export const tokenTypes = [
    "PORTAL",
    "DRRT",
    "COLLECTIBLE",
];

export const tokenCategories = [{
    value: 'DRRT',
    label: 'Rights Token',
    isPortal: true,
}, {
    value: 'PROT',
    label: 'Prop Token',
}, {
    value: 'CHAFIT',
    label: 'Character Token',
}, {
    value: 'WART',
    label: 'Wardrobe Token',
}, {
    value: 'MUSIT',
    label: 'Music Token',
}, {
    value: 'SEDET',
    label: 'Set Design Token',
}];
