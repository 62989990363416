import React from 'react'
import classNames from "classnames"
import { LoginForm } from '../AuthForm/LoginForm';
import { ReactComponent as LogoIcon } from "../../../../images/svg/ppw-logo.svg"
import { ReactComponent as CloseIcon } from "../../../../images/svg/exit-white.svg"

import styles from "./AuthModal.module.scss";

interface AuthModalProps {
  className?: string;
  onModalToggle: () => void;
}

export function AuthModal({ className, onModalToggle }: AuthModalProps) {
  const classes = classNames(styles.modal, className)
  return (
    <div className={classes}>
      <div className={styles.content}>
        <LogoIcon className={styles.logo} />
        <CloseIcon className={styles.close} onClick={onModalToggle} />
        <LoginForm />
      </div>
    </div>
  )
}
