import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, createLogger({ collapsed: true })];
}

export default function configureStore() {
  return createStore(rootReducer, applyMiddleware(...middleware));
}
