import classNames from "classnames";
import {
  HeartIcon,
  BoomIcon,
  CoinIcon,
  RobotIcon,
  CloudsIcon,
} from "../../assets/svg";

import styles from "./Actions.module.scss";

const actions = [
  <HeartIcon key="heart" />,
  <BoomIcon key="boom" />,
  <CoinIcon key="coin" />,
  <RobotIcon key="robot" />,
];

interface ActionsProps {
  className?: string;
}

export function Actions({ className }: ActionsProps) {
  const classes = classNames(styles.actions, className);

  return (
    <div className={classes}>
      <CloudsIcon className={styles.clouds} />
      {Array(128)
        .fill(0)
        .map((_, i) => (
          <div className={styles.action} key={i}>
            {actions[(i + 1) % 4]}
          </div>
        ))}
    </div>
  );
}
