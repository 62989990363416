var Config = {
  development: {
    url: "http://localhost:8081/",
    picturePrefix: "https://uppyupload.s3.amazonaws.com",
    fileStackPrefix: "https://ic-filestack.s3.us-west-1.amazonaws.com",
    etherscanUrl: "https://sepolia.etherscan.io/",
    pinataUrl: "https://imaginecouncil.mypinata.cloud/ipfs/",
    filestackApiKey: "AIyDX4xiwSsSWquPwbl4Lz",
    s3: {
      ledgerUrl: "https://ledger-objects.s3.amazonaws.com",
    },
  },
  staging: {
    url: "https://icssdk-staging.herokuapp.com/",
    picturePrefix: "https://uppyupload.s3.amazonaws.com",
    fileStackPrefix: "https://ic-filestack.s3.us-west-1.amazonaws.com",
    etherscanUrl: "https://sepolia.etherscan.io/",
    pinataUrl: "https://imaginecouncil.mypinata.cloud/ipfs/",
    filestackApiKey: "AIyDX4xiwSsSWquPwbl4Lz",
    s3: {
      ledgerUrl: "https://ledger-objects.s3.amazonaws.com",
    },
  },
  production: {
    url: "https://icssdk.herokuapp.com/",
    picturePrefix: "https://uppyupload.s3.amazonaws.com",
    fileStackPrefix: "https://ic-filestack.s3.us-west-1.amazonaws.com",
    etherscanUrl: "https://etherscan.io/",
    pinataUrl: "https://imaginecouncil.mypinata.cloud/ipfs/",
    filestackApiKey: "AIyDX4xiwSsSWquPwbl4Lz",
    s3: {
      ledgerUrl: "https://ledger-objects.s3.amazonaws.com",
    },
  },
};

module.exports = { Config };
