import React from "react";
import classNames from "classnames";

import styles from "./Blank.module.scss";

interface BlankProps extends React.PropsWithChildren<{}> {
  className?: string;
  linePosition?: "right" | "left";
  showLine?: boolean;
}

export function Blank({
  children,
  className,
  showLine = true,
  linePosition = "left",
}: BlankProps) {
  const classes = classNames(
    styles.blank,
    styles[linePosition],
    {
      [styles.hideLine]: !showLine,
    },
    className
  );

  return <div className={classes}>{children}</div>;
}
