import React from "react";

export function BoomIcon({ className }: DefaultIconProps) {
  return (
    <svg
      className={className}
      width="57"
      height="55"
      viewBox="0 0 57 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4203 22.6538H24.9707V25.8914H28.4203V22.6538Z"
        fill="#ECECEC"
      />
      <path
        d="M31.8793 19.416H28.4297V22.6536H31.8793V19.416Z"
        fill="#EA554E"
      />
      <path
        d="M35.3305 16.1782H31.8809V19.4158H35.3305V16.1782Z"
        fill="#EA554E"
      />
      <path
        d="M31.8793 38.832H28.4297V42.0697H31.8793V38.832Z"
        fill="#AA332D"
      />
      <path
        d="M28.3734 42.1177H24.9238V45.3553H28.3734V42.1177Z"
        fill="#AA332D"
      />
      <path
        d="M28.3734 45.3076H24.9238V48.5452H28.3734V45.3076Z"
        fill="#AA332D"
      />
      <path
        d="M31.8793 32.3667H28.4297V35.6043H31.8793V32.3667Z"
        fill="#AA332D"
      />
      <path
        d="M42.2387 22.6538H38.7891V25.8914H42.2387V22.6538Z"
        fill="#AA332D"
      />
      <path
        d="M49.1469 25.8916H45.6973V29.1292H49.1469V25.8916Z"
        fill="#AA332D"
      />
      <path
        d="M52.5961 29.1289H49.1465V32.3665H52.5961V29.1289Z"
        fill="#AA332D"
      />
      <path
        d="M56.057 25.8916H52.6074V29.1292H56.057V25.8916Z"
        fill="#DAA142"
      />
      <path
        d="M52.5961 22.6538H49.1465V25.8914H52.5961V22.6538Z"
        fill="#AA332D"
      />
      <path
        d="M38.7894 29.1289H35.3398V32.3665H38.7894V29.1289Z"
        fill="#AA332D"
      />
      <path
        d="M42.2387 25.8916H38.7891V29.1292H42.2387V25.8916Z"
        fill="#AA332D"
      />
      <path
        d="M45.6879 6.4751H42.2383V9.71272H45.6879V6.4751Z"
        fill="#3A3A3A"
      />
      <path
        d="M45.6879 16.3613H42.2383V19.599H45.6879V16.3613Z"
        fill="#AA332D"
      />
      <path
        d="M24.9711 6.4751H21.5215V9.71272H24.9711V6.4751Z"
        fill="#3A3A3A"
      />
      <path
        d="M35.3305 9.71289H31.8809V12.9505H35.3305V9.71289Z"
        fill="#DAA142"
      />
      <path
        d="M38.7894 6.4751H35.3398V9.71272H38.7894V6.4751Z"
        fill="#AA332D"
      />
      <path
        d="M49.1469 9.71289H45.6973V12.9505H49.1469V9.71289Z"
        fill="#AA332D"
      />
      <path
        d="M28.4203 3.23779H24.9707V6.47542H28.4203V3.23779Z"
        fill="#AA332D"
      />
      <path d="M28.4203 0H24.9707V3.23763H28.4203V0Z" fill="#DAA142" />
      <path
        d="M14.6039 6.4751H11.1543V9.71272H14.6039V6.4751Z"
        fill="#DAA142"
      />
      <path
        d="M14.6039 16.1782H11.1543V19.4158H14.6039V16.1782Z"
        fill="#AA332D"
      />
      <path
        d="M7.69375 16.1782H4.24414V19.4158H7.69375V16.1782Z"
        fill="#AA332D"
      />
      <path
        d="M18.0629 16.1782H14.6133V19.4158H18.0629V16.1782Z"
        fill="#AA332D"
      />
      <path
        d="M21.5121 29.1289H18.0625V32.3665H21.5121V29.1289Z"
        fill="#EFDD5B"
      />
      <path
        d="M18.0629 35.5947H14.6133V38.8324H18.0629V35.5947Z"
        fill="#DAA142"
      />
      <path
        d="M14.6039 38.832H11.1543V42.0697H14.6039V38.832Z"
        fill="#AA332D"
      />
      <path d="M11.057 42.166H7.60742V45.4036H11.057V42.166Z" fill="#DAA142" />
      <path
        d="M11.1527 25.8916H7.70312V29.1292H11.1527V25.8916Z"
        fill="#5E5E5E"
      />
      <path
        d="M49.1469 16.3613H45.6973V19.599H49.1469V16.3613Z"
        fill="#3A3A3A"
      />
      <path
        d="M11.1527 12.9409H7.70312V16.1785H11.1527V12.9409Z"
        fill="#5E5E5E"
      />
      <path
        d="M28.3734 51.4263H24.9238V54.6639H28.3734V51.4263Z"
        fill="#5E5E5E"
      />
      <path
        d="M49.1469 42.0693H45.6973V45.307H49.1469V42.0693Z"
        fill="#3A3A3A"
      />
      <path
        d="M18.0629 45.3076H14.6133V48.5452H18.0629V45.3076Z"
        fill="#AA332D"
      />
      <path
        d="M21.5121 16.1782H18.0625V19.4158H21.5121V16.1782Z"
        fill="#EA554E"
      />
      <path
        d="M21.5121 9.72266H18.0625V12.9603H21.5121V9.72266Z"
        fill="#EA554E"
      />
      <path
        d="M42.2387 35.5947H38.7891V38.8324H42.2387V35.5947Z"
        fill="#DAA142"
      />
      <path
        d="M38.7894 42.0693H35.3398V45.307H38.7894V42.0693Z"
        fill="#AA332D"
      />
      <path
        d="M35.3305 35.5947H31.8809V38.8324H35.3305V35.5947Z"
        fill="#AA332D"
      />
      <path
        d="M35.3305 29.1289H31.8809V32.3665H35.3305V29.1289Z"
        fill="#AA332D"
      />
      <path
        d="M38.7894 32.3667H35.3398V35.6043H38.7894V32.3667Z"
        fill="#AA332D"
      />
      <path
        d="M45.6879 38.832H42.2383V42.0697H45.6879V38.832Z"
        fill="#AA332D"
      />
      <path
        d="M28.4203 16.1782H24.9707V19.4158H28.4203V16.1782Z"
        fill="#DAA142"
      />
      <path
        d="M31.8598 16.1782H28.4102V19.4158H31.8598V16.1782Z"
        fill="#DAA142"
      />
      <path
        d="M28.4203 12.9409H24.9707V16.1785H28.4203V12.9409Z"
        fill="#DAA142"
      />
      <path
        d="M28.4203 9.71289H24.9707V12.9505H28.4203V9.71289Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 16.1782H21.5215V19.4158H24.9711V16.1782Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 12.9409H21.5215V16.1785H24.9711V12.9409Z"
        fill="#AA332D"
      />
      <path
        d="M24.9711 19.416H21.5215V22.6536H24.9711V19.416Z"
        fill="#DAA142"
      />
      <path
        d="M28.4203 25.8916H24.9707V29.1292H28.4203V25.8916Z"
        fill="#ECECEC"
      />
      <path
        d="M24.9711 22.6538H21.5215V25.8914H24.9711V22.6538Z"
        fill="#ECECEC"
      />
      <path
        d="M31.8793 25.8916H28.4297V29.1292H31.8793V25.8916Z"
        fill="#EFDD5B"
      />
      <path
        d="M35.3305 25.8916H31.8809V29.1292H35.3305V25.8916Z"
        fill="#DAA142"
      />
      <path
        d="M38.7894 22.6538H35.3398V25.8914H38.7894V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M45.6879 22.6538H42.2383V25.8914H45.6879V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M35.3305 22.6538H31.8809V25.8914H35.3305V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M31.8793 29.1289H28.4297V32.3665H31.8793V29.1289Z"
        fill="#DAA142"
      />
      <path
        d="M35.3305 32.3667H31.8809V35.6043H35.3305V32.3667Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 38.832H21.5215V42.0697H24.9711V38.832Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 29.1289H21.5215V32.3665H24.9711V29.1289Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 32.3667H21.5215V35.6043H24.9711V32.3667Z"
        fill="#AA332D"
      />
      <path
        d="M21.5121 32.3667H18.0625V35.6043H21.5121V32.3667Z"
        fill="#DAA142"
      />
      <path
        d="M28.4203 32.3667H24.9707V35.6043H28.4203V32.3667Z"
        fill="#DAA142"
      />
      <path
        d="M28.4203 35.5947H24.9707V38.8324H28.4203V35.5947Z"
        fill="#DAA142"
      />
      <path
        d="M31.8793 22.6538H28.4297V25.8914H31.8793V22.6538Z"
        fill="#EFDD5B"
      />
      <path
        d="M35.3305 19.416H31.8809V22.6536H35.3305V19.416Z"
        fill="#AA332D"
      />
      <path
        d="M38.7894 16.1782H35.3398V19.4158H38.7894V16.1782Z"
        fill="#AA332D"
      />
      <path
        d="M28.4203 19.416H24.9707V22.6536H28.4203V19.416Z"
        fill="#EFDD5B"
      />
      <path
        d="M28.4203 29.1289H24.9707V32.3665H28.4203V29.1289Z"
        fill="#EFDD5B"
      />
      <path
        d="M24.9711 25.8916H21.5215V29.1292H24.9711V25.8916Z"
        fill="#ECECEC"
      />
      <path
        d="M21.5121 25.8916H18.0625V29.1292H21.5121V25.8916Z"
        fill="#DAA142"
      />
      <path
        d="M14.6039 22.6538H11.1543V25.8914H14.6039V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M18.0629 29.1289H14.6133V32.3665H18.0629V29.1289Z"
        fill="#AA332D"
      />
      <path
        d="M7.69375 29.1289H4.24414V32.3665H7.69375V29.1289Z"
        fill="#AA332D"
      />
      <path
        d="M4.24454 25.8916H0.794922V29.1292H4.24454V25.8916Z"
        fill="#AA332D"
      />
      <path
        d="M14.6039 32.3667H11.1543V35.6043H14.6039V32.3667Z"
        fill="#DAA142"
      />
      <path
        d="M21.5121 22.6538H18.0625V25.8914H21.5121V22.6538Z"
        fill="#EFDD5B"
      />
      <path
        d="M18.0629 19.416H14.6133V22.6536H18.0629V19.416Z"
        fill="#EFDD5B"
      />
      <path
        d="M18.0629 25.8916H14.6133V29.1292H18.0629V25.8916Z"
        fill="#EA554E"
      />
      <path
        d="M18.0629 22.6538H14.6133V25.8914H18.0629V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M7.69375 22.6538H4.24414V25.8914H7.69375V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M14.6039 22.6538H11.1543V25.8914H14.6039V22.6538Z"
        fill="#DAA142"
      />
      <path
        d="M24.9711 19.416H21.5215V22.6536H24.9711V19.416Z"
        fill="#EFDD5B"
      />
    </svg>
  );
}
