import React from "react";
import classNames from "classnames";
import { FieldProps } from "formik";

import styles from "./InputField.module.scss";

interface InputFieldProps {
  className?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
}

export const InputField: React.FC<InputFieldProps & FieldProps> = ({
  className,
  type,
  placeholder = "Type in",
  field,
}) => {
  const classes = classNames(styles.input, className);

  return (
    <input
      className={classes}
      type={type}
      placeholder={placeholder}
      {...field}
    />
  );
};
