import React from "react";
import { Col, Nav, Navbar, Image } from "react-bootstrap";
import goodMunchie from "images/png/goodMunchie.png"
import goCoing from "images/svg/goCoin.svg"
import './components.scss'

const Header = () => {
    return (
        <Navbar className="border-bottom-black">
            <Col xs={2} className="d-flex justify-content-center no-side-paddings">
                <Navbar.Brand href="#home"><Image src={goodMunchie} className="mx-auto sidebar-avatar"/></Navbar.Brand>
            </Col>      
            <Col xs={10} className="no-side-paddings">
                <div className="d-flex w-100 flex-row justify-content-center">
                    <Nav>
                        <Image src={goCoing} className="mx-auto sidebar-avatar"/>
                    </Nav>
                </div>
            </Col>
        </Navbar>
        );
};

  
export default Header;