import React, { useState } from "react";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logOutUser } from "store/actions/auth";
import "./components.scss";

const Sidebar = ({ brand, logOutUser }) => {
  const history = useHistory();
  let path = window.location.pathname.split("/")[1];
  const [activeKey, setActiveKey] = useState(path);

  const checkStateForBrand = async () => {
    history.push("/games");
    // try {
    //     console.log('brand', brand)
    //     let response = await axios.get(`api/routes/games/getGameStatusForPortal?brandId=${brand.data._id}`)
    //     if (response?.data?.message === "Success") {
    //         if (!response.data.gamesInPlay) {
    //             history.push("/games")
    //         } else {
    //             history.push("/imagine")
    //         }
    //     }
    // } catch (error) {
    //     console.log('error', error)
    // }
  };

  return (
    <>
      <Nav
        className="border-right-black h-100 min-height mx-auto d-flex flex-column align-items-center sidebar"
        activeKey={activeKey}
        onSelect={(selectedKey) => {
          if (selectedKey === "logOut") {
            logOutUser();
            history.push("/");
          } else if (selectedKey === "imagine") {
            checkStateForBrand();
          } else {
            history.push(`/${selectedKey}`);
            setActiveKey(selectedKey);
          }
        }}
      >
        <Nav.Item className="d-flex mt-3">
          <Nav.Link eventKey="portal" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">PORTALS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="story" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">STORIES</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="token" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">TOKENS</p>
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item className="d-flex">
                    <Nav.Link eventKey="product" className="font-16 ml-4 sidebar-text">
                        <p className="mx-auto color-black font-18">PRODUCTS</p>
                    </Nav.Link>
                </Nav.Item> */}
        {/* <Nav.Item className="d-flex">
                    <Nav.Link eventKey="games" className="font-16 ml-4 sidebar-text">
                        <p className="mx-auto color-black font-18">PLAYS</p>
                    </Nav.Link>
                </Nav.Item> */}

        <Nav.Item className="d-flex">
          <Nav.Link eventKey="imagine" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">IMAGINE</p>
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item className="d-flex">
                    <Nav.Link eventKey="build" className="font-16 ml-4 sidebar-text">
                        <p className="mx-auto color-black font-18">BUILD</p>
                    </Nav.Link>
                </Nav.Item> */}
        {/* <Nav.Item className="d-flex">
                    <Nav.Link eventKey="prepare" className="font-16 ml-4 sidebar-text">
                        <p className="mx-auto color-black font-18">PREPARE</p>
                    </Nav.Link>
                </Nav.Item> */}
        {/* <Nav.Item className="d-flex">
                    <Nav.Link eventKey="distribute" className="font-16 ml-4 sidebar-text">
                        <p className="mx-auto color-black font-18">DISTRIBUTE</p>
                    </Nav.Link>
                </Nav.Item> */}

        <hr className="sidebar-line"></hr>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="verify" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">USERS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="brand" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">BRANDS & ADMINS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="brand" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">BRANDS & ADMINS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link
            eventKey="transactions"
            className="font-16 ml-4 sidebar-text"
          >
            <p className="mx-auto color-black font-18">TRANSACTIONS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="tokens" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">ASSETS</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="feed" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">FEED</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link eventKey="logOut" className="font-16 ml-4 sidebar-text">
            <p className="mx-auto color-black font-18">LOG OUT</p>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.user,
});

const mapDispatchToProps = {
  logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
