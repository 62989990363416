import React, {useState, useCallback} from "react";
import {useFormik, ErrorMessage, FormikProvider, Formik, Field, Form } from "formik";
import * as yup from "yup";
import {Row, Col, Button, CardDeck, Card, FormGroup, FormControl, FormLabel, Image} from "react-bootstrap";
import { Brand } from "../../interface";
import moment from "moment/moment";
import {useAuth} from "../../contexts/AuthContext";
import {registerSchema} from "./register.schema";
import arrowDown from "../../images/svg/arrow-down.svg";
import styles from './BrandComponent.module.scss';

interface IProps {
  brands: Brand[];
  onBrandUpdate: (brandId: string, values: object) => Promise<void>;
}

const isEmail = (email: string) => {
    let myRegexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return myRegexp.exec(email);
};

const BrandComponent: React.FC<IProps> = ({ brands, onBrandUpdate }) => {
    const [addNewClicked, setAddNewClicked] = useState(false);
    const [ isActionsOpen, setActionsOpen ] = useState<{[key: string]: boolean}>({});
    const { signUpUser } = useAuth();

    const register = async(values: registerValues) => {
        values.phoneNumber = values.phoneNumber.replace(/-/g, '');

        if (isEmail(values.email)) {
            values.email = values.email.toLowerCase();
        } else if (/\s+$/.test(values.email)) {
            values.email = values.email.replace(/\s+/g, "");
        }
        signUpUser(values);
    }

    const updateRole = async(brandID: string, role: string) => {
        const values = {
            role
        }

        onBrandUpdate(brandID, values);
    }

    const toggleActions = useCallback((id: string) => {
        setActionsOpen((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    }, []);

    const registerFormik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: "brand",
            phoneNumber: "",
            type: "portalpathway"
        },
        validationSchema: registerSchema,
        onSubmit: async (values) => {
            await register(values);
            registerFormik.resetForm();
        },
    });

    return (
        <div className="mt-5 mb-5 ms-5 me-5 justify-content-center d-flex flex-column w-100">
            <div className={"d-flex flex-row justify-content-between"}>
                <Button
                    variant="primary"
                    className="mb-4 w-100 orange-button"
                    onClick={() => setAddNewClicked(!addNewClicked)}
                >
                    Add New
                </Button>
            </div>
            {addNewClicked && (
                <div className="w-100 d-flex flex-column">
                    <FormikProvider value={registerFormik}>
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-3" controlId="firstName">
                                        <FormLabel>First Name</FormLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="First Name"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.firstName}
                                        />
                                        <ErrorMessage name="firstName" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-3" controlId="lastName">
                                        <FormLabel>Last Name</FormLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.lastName}
                                        />
                                        <ErrorMessage name="lastName" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-3" controlId="email">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl
                                            type="email"
                                            placeholder="Email"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.email}
                                        />
                                        <ErrorMessage name="email" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-3" controlId="password">
                                        <FormLabel>Password</FormLabel>
                                        <FormControl
                                            type="password"
                                            placeholder="Password"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.password}
                                        />
                                        <ErrorMessage name="password" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-3" controlId="role">
                                        <FormLabel>Role</FormLabel>
                                        <FormControl
                                            as="select"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.role}
                                        >
                                            <option value="brand">Brand</option>
                                            <option value="admin">Admin</option>
                                        </FormControl>
                                        <ErrorMessage name="role" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-3" controlId="phoneNumber">
                                        <FormLabel>Phone Number</FormLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Phone Number"
                                            onChange={registerFormik.handleChange}
                                            value={registerFormik.values.phoneNumber}
                                        />
                                        <ErrorMessage name="phoneNumber" component="div" className="error" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button variant="primary" className="orange-button w-100 mb-5" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </FormikProvider>
                </div>
            )}
            {brands.map((brand) => (
                <CardDeck key={brand._id} className="w-100 mb-3">
                    <Card className="ppw-card h-100" key={brand._id}>
                        <div className="p-10 d-flex flex-column h-100">
                            <Card.Body className="d-flex flex-row list-view-body">
                                <div className="w-100 me-5 d-flex flex-column">
                                    <Image roundedCircle className="token-image" src={brand.logo}/>
                                    <p className="text-left mb-1 font-18">{brand.firstName}</p>
                                    <p className="text-left font-18">{brand.email}</p>
                                </div>
                                <div className="my-auto d-flex flex-column w-100">
                                    <p className="font-18 opacity-5">ROLE</p>
                                    <p className="font-18-bold">{brand.role}</p>
                                </div>
                                <div className="my-auto d-flex flex-column w-100">
                                    <p className="font-18 opacity-5">CREATED</p>
                                    <p className="font-18-bold">{moment(brand.creationDate).format('MMMM Do, YYYY')}</p>
                                </div>
                            </Card.Body>
                            <div className={styles.menuWrapper}>
                                <button className={styles.button} onClick={() => toggleActions(brand._id)}>
                                    Actions <img alt="arrow-down"
                                                 className={`${isActionsOpen[brand._id] ? "rotate-arrow" : "normal-arrow"}`}
                                                 src={arrowDown}/>
                                </button>
                                {isActionsOpen[brand._id] && (
                                    <div className='d-flex flex-column list-view-body'>
                                        <>
                                            <div className="mt-3 d-flex flex-row">
                                                <Formik
                                                    initialValues={{
                                                        role: brand.role
                                                    }}
                                                    validationSchema={yup.object().shape({
                                                        role: yup.string().required("Role is required")
                                                    })
                                                    }
                                                    onSubmit={(values) => {
                                                         updateRole(brand._id, values.role as string);
                                                    }}
                                                >
                                                    {({isSubmitting}) => (
                                                        <Form className="w-100">
                                                            <hr/>
                                                            <div className="d-flex flex-row">
                                                                <h5 className="text-center w-100">Update Role</h5>
                                                            </div>
                                                            <br/>
                                                            <Field as="select" name="role"
                                                                   className="form-control mb-3">
                                                                <option value="" disabled>Select Role</option>
                                                                <option value="brand">Brand</option>
                                                                <option value="admin">Admin</option>
                                                            </Field>
                                                            <ErrorMessage name="role" component="div"
                                                                          className="error"/>
                                                            <Button variant="primary"
                                                                    className="orange-button w-100 mb-5"
                                                                    type="submit" disabled={isSubmitting}>
                                                                Update
                                                            </Button>
                                                            <hr/>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                </CardDeck>
            ))}
        </div>
    );
}

export default BrandComponent;
