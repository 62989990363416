import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';

// add a <div> child to body under which to mount the snackbars
const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);

const Notistack = {
  success: function(msg) {
    this.toast(msg, 'success');
  },
  warning: function(msg) {
    this.toast(msg, 'warning');
  },
  info: function(msg) {
    this.toast(msg, 'info');
  },
  error: function(msg) {
    this.toast(msg, 'error');
  },
  toast: function(msg, variant = 'default') {
    const ShowSnackbar = ({ message }) => {
      const { enqueueSnackbar, closeSnackbar } = useSnackbar();
      enqueueSnackbar(
        `${message}`,
        { 
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            ClickAwayListenerProps: {
                onClickAway: () => closeSnackbar(),
                touchEvent: "onTouchStart"
            },
            autoHideDuration: 8000,
        }
      );
      return null;
    };
    ReactDOM.render(
      <SnackbarProvider preventDuplicate maxSnack={3}>
        <ShowSnackbar message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  }
};

export default Notistack;