import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useFormik } from "formik";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import uploadImagePlaceholder from "images/svg/uploadeImagePlaceholder.svg"
import { startUppy, blobToBase64 } from "../../services/uppy";
import ObjectItem from "./ObjectItem";
import { useApp } from "contexts/AppContext";
import toast from '../../components/Notistack';

import "./portals.scss"
import axios from "axios";
import { Portal} from "interface";

const { Config } = require("../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV as any]["picturePrefix"]

interface IProps {
  isLoading?: boolean
  portals: Portal[]

  createPortal: (values: Portal) => void
  updatePortal: (portalId: number, values: Portal) => void
}

const PortalComponent = ({
  createPortal,
  updatePortal,
  portals,
}: IProps) => {
  const { colors, creativities, hubs } = useApp();
  const [active, setActive] = useState<any>(undefined);
  const [character, setCharacter] = useState<any>(undefined);

  const formik = useFormik({
    initialValues: {
      name: active?.name || '',
      description: active?.description || '',
      icon: active?.icon || '',
      url: active?.url || '',
      active: active?.active || false,
      hub: character?.hub || '',
      color: character?.favoriteColor || '',
      creative: character?.creative || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let request: any = {
        name: values.name,
        description: values.description,
        url: values.url,
        active: values.active,
      };
      if (values.active) {
        request.hub = values.hub;
        request.color = values.color;
        request.creative = values.creative;
      }
      if (typeof values.icon === 'string') {
        request.icon = values.icon;
      }
      if (active) {
        await updatePortal(active._id, request);
      } else {
        await createPortal(values);
      }
      formik.resetForm();
    },
  });

  const initUpload = async () => {
    if (document.getElementById(`uppyDiv`) !== null) {
      const uppy = startUppy(`.UppyModalOpenerBtn`, `.DashboardContainer`);
      uppy.on("complete", async (result: any) => {
        if (result && result.successful) {
          const base64 = await blobToBase64(result.successful[0].data);
          formik.setFieldValue('icon', base64);
          uppy.getPlugin("Dashboard").closeModal();
        }
      });
    }
  }
  useEffect(() => {
    initUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setActive(undefined);
  }, [portals])

  const loadCharacter = useCallback(async () => {
    try {
      const response = await axios.post(`api/routes/users/query?queryType=direct&limit=1`, {
        filters: [{
          key: 'portalID',
          value: [active?._id],
        }],
      })
      setCharacter(response?.data?.users?.docs?.[0]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  }, [active]);

  useEffect(() => {
    if (!active) {
      setCharacter(undefined);
    } else {
      loadCharacter();
    }
  }, [active, loadCharacter]);

  const iconImage = useMemo(() => {
    let iconImage = uploadImagePlaceholder;

    if (formik.values.icon?.url) {
      iconImage = `${picturePrefix}/${formik.values.icon.url}.png`;
    } else if (formik.values.icon) {
      iconImage = formik.values.icon;
    }

    return iconImage
  }, [formik.values.icon]);

  return (
    <Container>
      <Row className="h-100">
        <Col className="border-right-black" sm={3}>
          <div className="mt-4">
            {portals.map((portal) => (
              <div
                key={portal._id}
                className="d-flex mb-4 align-items-center cursor-pointer"
                onClick={() => setActive(portal)}
              >
                <img
                  className="me-2"
                  alt={portal.name}
                  src={`${picturePrefix}/${portal.icon.url}.png`}
                  height={80}
                />
                <div>
                  {portal.name}
                </div>
              </div>
            ))}
            <Button className="mt-4 w-50 orange-button" onClick={() => setActive(undefined)}>
              Add new
            </Button>
          </div>
        </Col>
        <Col sm={9}>
          <Form className="w-100 mt-4" onSubmit={formik.handleSubmit}>
            <div className="w-50 mb-4">
              <Form.Label className="font-18-bold">
                ICON
              </Form.Label>
              <br />
              <img
                src={iconImage}
                alt=""
                className={`UppyModalOpenerBtn uploaded-image`}
              />
            </div>
            <Form.Group className="mb-4" >
              <Form.Label className="font-18-bold">
                PORTAL NAME
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">
                PORTAL DESCRIPTION
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                name="description"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">
                PORTAL URL
              </Form.Label>
              <Form.Control
                type="text"
                name="url"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.url}
              />
            </Form.Group>
            <Form.Group className="w-100 mb-4">
              <Form.Label className="font-18-bold">
                CHARACTER
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="IS ENABLED"
                onChange={(event) => {
                  formik.setFieldValue('active', event.target.checked);
                }}
                checked={formik.values.active}
              />
            </Form.Group>
            {formik.values.active && (
              <>
                <div className='mb-4 mt-4 font-16-bold'>
                  COLOR
                </div>
                <div>
                  {colors.map((item: any) => (
                    <ObjectItem
                      active={formik.values.color === item.name}
                      handleClick={(value: any) => formik.setFieldValue('color', value)}
                      item={item}
                      key={item.name}
                    />
                  ))}
                </div>
                <div className='mb-4 mt-4 font-16-bold'>
                  HUB
                </div>
                <div>
                  {hubs.map((item: any) => (
                    <ObjectItem
                      active={formik.values.hub === item.name}
                      handleClick={(value: any) => formik.setFieldValue('hub', value)}
                      item={item}
                      key={item.name}
                    />
                  ))}
                </div>
                <div className='mb-4 mt-4 font-16-bold'>
                  CREATIVE
                </div>
                <div>
                  {creativities.map((item: any) => (
                    <ObjectItem
                      active={formik.values.creative === item.name}
                      handleClick={(value: any) => formik.setFieldValue('creative', value)}
                      item={item}
                      key={item.name}
                    />
                  ))}
                </div>
              </>
            )}
            <Button className="mt-4 mb-4 w-100 orange-button" type="submit">
              SAVE
            </Button>
          </Form>
        </Col>
      </Row>
      <div id="uppyDiv" className="DashboardContainer" />
    </Container>
  );
};

export default PortalComponent;
