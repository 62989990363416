import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import TokensComponent from "./TokensComponent";
// import { tokenType } from "constants/tokenType"
import axios from "../../services/axios";
import toast from "../../components/Notistack"

const TokensContainer = ({ brand }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tokens, setTokens] = useState([])
  const [products, setProducts] = useState([])
  const [games, setGames] = useState([])
  const [gameSaves, setGameSaves] = useState([])
  const [game, setGame] = useState({})
  const history = useHistory();

  const getTokenByType = async (type) => {
    setIsLoading(true)
    try {
      let response = await axios.get(`api/routes/tokens/getTokensByType?type=${type}`)
      if (response.data.message === "Success") {
        setTokens(response.data.tokens)
        setProducts([])
        setGames([])
        setGameSaves([])
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const getProductsByToken = async (token) => {
    setIsLoading(true)
    try {
      let response = await axios.get(`api/routes/products/getProductsByToken?tokenId=${token._id}`)
      if (response.data.message === "Success") {
        setProducts(response.data.products)
        setGames([])
        setGameSaves([])
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const getGamesByProduct = async (product) => {
    setIsLoading(true)
    try {
      let response = await axios.get(`api/routes/games/getProductGames?product_id=${product._id}`)
      if (response.data.message === "Success") {
        setGames(response.data.games)
        setGameSaves([])
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const getGameDetails = async (game) => {
    setIsLoading(true)
    try {
      let response = await axios.get(`api/routes/games/getGameDetailsForPortal?gameId=${game._id}`)
      if (response.data.message === "Success") {
        setGame(response.data.game)
        setGameSaves(response.data.gameSaves)
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const deleteToken = async (tokenId) => {
    setIsLoading(true)
    try {
      let response = await axios.delete(`api/routes/tokens/deleteToken?tokenId=${tokenId}`)
      if (response.data.message === "Success") {
        getTokenByType('PRODUCT');
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/")
    }
  }, [brand, history]);

  return (
    <TokensComponent
      game={game}
      gameSaves={gameSaves}
      isLoading={isLoading}
      tokens={tokens}
      products={products}
      games={games}
      getGameDetails={getGameDetails}
      getTokenByType={getTokenByType}
      getProductsByToken={getProductsByToken}
      getGamesByProduct={getGamesByProduct}
      deleteToken={deleteToken}
    />
  );
};

export default connect(state => ({
  brand: state.auth.user,
}))(TokensContainer);

