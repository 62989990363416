import React from "react";


const DrawContent = ({ item }: any) => {

    if (item.mobileVideos?.pending || item.desktopVideos?.pending) {
      switch (item.type) {
        case "image":
          return (
            <>
              <img src={item.mobileFile} alt={item.title} width={"100%"} />
              <img src={item.desktopFile} alt={item.title} width={"100%"} />
            </>
          );
        case "video":
          return (
            <div className="d-flex flex-column row-gap-5 mb-4">
              <div className="d-flex flex-column row-gap-2">
                <span className="font-16-bold">DESKTOP VIDEOS</span>
                <video
                  controls
                  src={item.desktopVideos?.pending}
                  width={"100%"}
                />
                <video
                  controls
                  src={item.desktopVideos?.submission}
                  width={"100%"}
                />
                <video controls src={item.desktopVideos?.vote} width={"100%"} />
                <video
                  controls
                  src={item.desktopVideos?.completed}
                  width={"100%"}
                />
                   {item.desktopVideos?.hold && <video controls src={item.desktopVideos?.hold} width={"100%"} />}
                </div>
                
              <div className="d-flex column-gap-3 flex-column row-gap-2">
                <span className="font-16-bold">MOBILE VIDEOS</span>
                <video
                  controls
                  src={item.mobileVideos?.pending}
                  width={"100%"}
                />
                <video
                  controls
                  src={item.mobileVideos?.submission}
                  width={"100%"}
                />
                <video controls src={item.mobileVideos?.vote} width={"100%"} />
                <video
                  controls
                  src={item.mobileVideos?.completed}
                  width={"100%"}
                />
                { item.mobileVideos?.hold && <video controls src={item.mobileVideos?.hold} width={"100%"} />}
              </div>
            </div>
          );
        case "text":
        default:
          return item.message;
      }
    } else {
      switch (item.type) {
        case "image":
          return (
            <>
              <img src={item.mobileFile} alt={item.title} width={"100%"} />
              <img src={item.desktopFile} alt={item.title} width={"100%"} />
            </>
          );
        case "video":
          return (
            <>
              <video controls src={item.mobileFile} width={"100%"} />
              <video controls src={item.desktopFile} width={"100%"} />
            </>
          );
        case "text":
        default:
          return item.message;
      }
    }
};
  

export default DrawContent;