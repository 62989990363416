import React, { useState, useMemo, useCallback } from "react";
import { useFormik, ErrorMessage, FormikProvider } from "formik";
import * as yup from "yup";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { PickerDropPane } from "filestack-react";
import { defaultSizes } from "../../constants/sizes";

import { tokenTypes, tokenCategories } from "constants/tokenType";
import { tradeOptions } from "constants/tradeOptions";

import TokenSlidesCreator, { Slide } from "./TokenSlidesCreator";
import FileUploader from "components/FileUploader";

import "./token.scss";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";

import { Token, Portal, Story, Game } from "interface";

const { Config } = require("../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV as any]["picturePrefix"];
const ethercanUrl = Config[process.env.REACT_APP_ENV as any]["etherscanUrl"];
const pinataUrl = Config[process.env.REACT_APP_ENV as any]["pinataUrl"];
const fileStackApiKey =
  Config[process.env.REACT_APP_ENV as any]["filestackApiKey"];

const fileStackPrefix =
  Config[process.env.REACT_APP_ENV as any]["fileStackPrefix"];

const prepareToShowUrl = (value: any) => {
  if (value?.type) {
    if (value.type?.indexOf("video") >= 0) {
      return `/api/routes/media/file/${value?.url}/video`;
    }
    return `/api/routes/media/file/${value?.url}/image`;
  }
  if (value?.url) {
    return `${picturePrefix}/${value?.url}.png`;
  }
  return "";
};

interface IProps {
  createToken: (token: Token) => Promise<void>;
  updateToken: (id: number, token: Token) => Promise<void>;
  addSlides: (id: number, slides: any) => Promise<void>;
  tokens: Token[];
  portals: Portal[];
  games: Game[];
  stories: Story[];
  uploadToIpfs: (id: number) => Promise<void>;
  deployToken: (id: number) => Promise<void>;
  startSale: (id: number, price: number) => Promise<void>;
}

const TokenComponent = ({
  createToken,
  updateToken,
  addSlides,
  tokens,
  portals,
  stories,
  games,
  uploadToIpfs,
  deployToken,
  startSale,
}: IProps) => {
  const [active, setActive] = useState<any>(undefined);
  const [audioFileUploaded, setAudioFileUploaded] = useState(false);
  const [price, setPrice] = useState<number>(0);
  const formDisabled = useMemo(() => active?.address, [active?.address]);

  const TokenSchema = yup.object().shape({
    symbol: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: active?.name || "",
      audio: active?.audio || "",
      symbol: active?.symbol || "",
      description: active?.description || "",
      story: active?.storyID || (stories.length ? stories[0]._id : ""),
      portal: active?.portalID || "none",
      game: active?.gameID || "none",
      type: active?.type || "COLLECTIBLE",
      tradable: active?.tradable || "TRADABLE",
      category: active?.category || "PROT",
      active: active?.status === "ACTIVE",
      bannerDesktop: active?.banner?.desktop || active?.banner || {},
      bannerMobile: active?.banner?.mobile || active?.banner || {},
      product: active?.product || {
        isProduct: false,
        isCommemorative: false,
        isMultiplatform: false,
        sizes: defaultSizes,
      },
      iconApp: active?.icon?.app || active?.icon || {},
      iconMeta: active?.icon?.meta || active?.icon || {},
      makers: {
        builder: active?.makers?.builder || "",
        creator: active?.makers?.creator || "",
      },
    },
    validationSchema: TokenSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let request: any = {
        name: values.name,
        audio: values.audio,
        symbol: values.symbol,
        description: values.description,
        story: values.story,
        portal: values.portal,
        game: values.game,
        type: values.type,
        tradable: values.tradable,
        category: values.type === "DRRT" ? "DRRT" : values.category,
        status: values.active ? "ACTIVE" : "DEVELOPING",
        makers: values.makers,
        product: values.product,
        icon: {
          app: values.iconApp,
          meta: values.iconMeta,
        },
        banner: {
          desktop: values.bannerDesktop,
          mobile: values.bannerMobile,
        },
      };

      if (active) {
        if (formik.values.type !== "COLLECTIBLE") {
          await updateToken(active._id, { ...request, game: null });
        } else {
          await updateToken(active._id, request);
        }
      } else {
        await createToken(request);
      }
      formik.resetForm();
    },
  });

  const saveSlides = useCallback(
    async (slides: Slide[]) => {
      if (active?._id) {
        await addSlides(active._id, {
          slides,
        });
      }
    },
    [active?._id, addSlides],
  );

  React.useEffect(() => {
    if (active) {
      setActive(tokens.find((token: any) => token._id === active._id));
    }
  }, [tokens]);

  return (
    <Container>
      <Row className="h-100">
        <Col className="border-right-black" sm={3}>
          <div className="mt-4">
            {tokens.map((token: any) => (
              <div
                key={token._id}
                className="d-flex mb-4 align-items-center cursor-pointer"
                onClick={() => setActive(token)}
              >
                <img
                  alt={token.name}
                  src={prepareToShowUrl(token?.icon?.app || token?.icon)}
                  height={80}
                />
                <div>{token.name}</div>
              </div>
            ))}
            <Button
              className="mt-4 w-50 orange-button"
              onClick={() => setActive(undefined)}
            >
              Add new
            </Button>
          </div>
        </Col>
        <Col sm={9}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex flex-row w-100 flex-column mt-4">
                {active && (
                  <Form.Group className="w-100 mb-4">
                    <Form.Label className="font-18-bold">STATUS</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="IS ACTIVE"
                      onChange={(event) => {
                        formik.setFieldValue("active", event.target.checked);
                        setTimeout(() => {
                          formik.submitForm();
                        }, 500);
                      }}
                      checked={formik.values.active}
                    />
                  </Form.Group>
                )}
                <div className="w-100 mb-4">
                  <br />
                  <Row className="h-100 mb-4">
                    <Col sm={6}>
                      <Form.Label className="font-16-bold">AUDIO</Form.Label>
                      {(active && active.audio) || formik.values.audio ? (
                        <>
                          <AudioPlayer
                            audioURL={active?.audio || formik?.values?.audio}
                          />
                          <span
                            className="text-danger cursor-pointer"
                            onClick={() => {
                              setAudioFileUploaded(false);

                              if (formDisabled) {
                                setTimeout(() => {
                                  formik.submitForm();
                                }, 500);
                              }
                              setActive({ ...active, audio: "" });
                              return formik.setFieldValue("audio", "");
                            }}
                          >
                            delete
                          </span>
                        </>
                      ) : (
                        <>
                          <PickerDropPane
                            apikey={fileStackApiKey}
                            onError={(error) => {
                              console.log("error", error);
                            }}
                            onUploadDone={(res: any) => {
                              if (res?.filesUploaded.length > 0) {
                                formik.setFieldValue(
                                  "audio",
                                  `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                                );
                                setAudioFileUploaded(true);
                                if (formDisabled) {
                                  setTimeout(() => {
                                    formik.submitForm();
                                  }, 500);
                                }
                              }
                            }}
                            pickerOptions={{
                              accept: "audio/*",
                              storeTo: {
                                container: "ic-filestack",
                                region: "us-west-1",
                              },
                            }}
                          />
                          {audioFileUploaded && (
                            <>
                              <span
                                className="text-danger cursor-pointer mt-2"
                                onClick={() => {
                                  setAudioFileUploaded(false);
                                  formik.setFieldValue("audio", "");
                                }}
                              >
                                delete
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>

                  <Form.Label className="font-18-bold">BANNER</Form.Label>
                  <br />
                  <Row className="h-100">
                    <Col sm={6}>
                      <Form.Label className="font-16-bold">DESKTOP</Form.Label>
                      <FileUploader
                        acceptType={["video/*", "image/*"]}
                        value={{
                          url: prepareToShowUrl(formik.values.bannerDesktop),
                          type: formik.values.bannerDesktop.type,
                        }}
                        resetValue={() =>
                          formik.setFieldValue("bannerDesktop", "")
                        }
                        onUploadDone={(value: any) =>
                          formik.setFieldValue("bannerDesktop", value)
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label className="font-16-bold">MOBILE</Form.Label>
                      <FileUploader
                        acceptType={["video/*", "image/*"]}
                        value={{
                          url: prepareToShowUrl(formik.values.bannerMobile),
                          type: formik.values.bannerMobile.type,
                        }}
                        resetValue={() =>
                          formik.setFieldValue("bannerMobile", "")
                        }
                        onUploadDone={(value: any) =>
                          formik.setFieldValue("bannerMobile", value)
                        }
                      />
                    </Col>
                  </Row>
                </div>
                <div className="w-100 mb-4">
                  <Row className="h-100">
                    <Col sm={6}>
                      <Form.Label className="font-18-bold">
                        ICON (web app)
                      </Form.Label>
                      <FileUploader
                        acceptType={["image/*"]}
                        value={{
                          url: prepareToShowUrl(formik.values.iconApp),
                          type: formik.values.iconApp.type,
                        }}
                        resetValue={() => formik.setFieldValue("iconApp", "")}
                        onUploadDone={(value) =>
                          formik.setFieldValue("iconApp", value)
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label className="font-18-bold">
                        ICON (metadata)
                      </Form.Label>
                      <FileUploader
                        acceptType={["image/png"]}
                        value={{
                          url: prepareToShowUrl(formik.values.iconMeta),
                          type: formik.values.iconMeta.type,
                        }}
                        resetValue={() => formik.setFieldValue("iconMeta", "")}
                        onUploadDone={(value) =>
                          formik.setFieldValue("iconMeta", value)
                        }
                        disabled={active?.ipfsIcon}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="d-flex flex-row w-100 flex-column">
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">PORTAL</Form.Label>
                  <Form.Control
                    as="select"
                    name="portal"
                    disabled={formDisabled}
                    value={formik.values.portal}
                    onChange={formik.handleChange}
                  >
                    <option value="none">None</option>
                    {portals.map((portal: any) => (
                      <option key={portal._id} value={portal._id}>
                        {portal.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">STORY</Form.Label>
                  <Form.Control
                    as="select"
                    name="story"
                    disabled={formDisabled}
                    value={formik.values.story}
                    onChange={formik.handleChange}
                  >
                    {stories.map((story: any) => (
                      <option key={story._id} value={story._id}>
                        {story.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">TOKEN NAME</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    disabled={formDisabled}
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">TOKEN SYMBOL</Form.Label>
                  <Form.Control
                    type="text"
                    name="symbol"
                    disabled={formDisabled}
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.symbol}
                  />
                </Form.Group>
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">
                    TOKEN BUILDER
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="makers.builder"
                    disabled={formDisabled}
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.makers?.builder}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">
                    TOKEN CREATOR
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="makers.creator"
                    disabled={formDisabled}
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.makers?.creator}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                {formik.values.type === "COLLECTIBLE" && (
                  <Form.Group className="w-100 mb-4">
                    <Form.Label className="font-18-bold">GAME</Form.Label>
                    <Form.Control
                      as="select"
                      name="game"
                      disabled={formDisabled}
                      value={formik.values.game}
                      onChange={formik.handleChange}
                    >
                      <option value="none">None</option>
                      {games.map((game: any) => (
                        <option key={game._id} value={game._id}>
                          {game.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}

                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">DESCRIPTION</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    disabled={formDisabled}
                    name="description"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </Form.Group>
                {active?.price && (
                  <Form.Group className="w-100 min-token-type-width mb-3">
                    <Form.Label className="font-18-bold">
                      TOKEN PRICE
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="price"
                      disabled
                      placeholder=""
                      value={active?.price}
                    />
                  </Form.Group>
                )}
                <Form.Group className="w-100 mb-4">
                  <Form.Label className="font-18-bold">
                    TRADE OPTIONS
                  </Form.Label>
                  {tradeOptions.map((to, i) => (
                    <Form.Group key={`group-trade-${i}`} className="mb-1">
                      <Form.Check
                        name="tradable"
                        key={`check-trade-${i}`}
                        className="font-16"
                        disabled={formDisabled}
                        type="radio"
                        label={`${to}`}
                        checked={formik.values.tradable === to}
                        onChange={() => formik.setFieldValue("tradable", to)}
                      />
                    </Form.Group>
                  ))}
                </Form.Group>
                <Form.Group className="w-100 min-token-type-width mb-3">
                  <Form.Label className="font-18-bold">TOKEN TYPE</Form.Label>
                  {tokenTypes.map((tp, i) => (
                    <Form.Group
                      key={`group-type-${i}`}
                      className="fixed-width-200 mb-1"
                    >
                      <Form.Check
                        name="type"
                        key={`check-type-${i}`}
                        className="font-16"
                        type="radio"
                        label={`${tp}`}
                        disabled={formDisabled}
                        checked={formik.values.type === tp}
                        onChange={() => formik.setFieldValue("type", tp)}
                      />
                    </Form.Group>
                  ))}
                </Form.Group>
                {formik.values.type === "COLLECTIBLE" && (
                  <Form.Group className="w-full min-token-type-width mb-3">
                    <Form.Label className="font-18-bold">
                      TOKEN PRODUCT VALUES
                    </Form.Label>
                    <Form.Group className="w-full d-flex space-x-2 justify-between">
                      <Form.Check
                        type="checkbox"
                        name="product.isProduct"
                        label="Is Product"
                        checked={formik.values.product.isProduct}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "product.isProduct",
                            event.target.checked,
                          );
                          setTimeout(() => {
                            formik.submitForm();
                          }, 500);
                        }}
                      />
                    </Form.Group>
                    {active && (
                      <Form.Group>
                        <Form.Group className="w-full d-flex space-x-2 justify-between">
                          <Form.Check
                            disabled={!active.product.isProduct}
                            type="checkbox"
                            name="product.isCommemorative"
                            label="Is Commemorative"
                            checked={formik.values.product.isCommemorative}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "product.isCommemorative",
                                event.target.checked,
                              );
                              setTimeout(() => {
                                formik.submitForm();
                              }, 500);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="w-full d-flex space-x-2 justify-between">
                          <Form.Check
                            disabled={!active.product.isCommemorative}
                            type="checkbox"
                            name="product.isMultiplatform"
                            label="Is Multi-Platform"
                            checked={formik.values.product.isMultiplatform}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "product.isMultiplatform",
                                event.target.checked,
                              );
                              setTimeout(() => {
                                formik.submitForm();
                              }, 500);
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                    )}
                    {/*{formik.values.product.isProduct && (*/}
                    {/*    <Form.Group className="w-full d-flex" style={{ flexDirection: "column" }}>*/}
                    {/*      <Form.Label id="sizes-label">Product Sizes</Form.Label>*/}
                    {/*      <Select*/}
                    {/*          multiple*/}
                    {/*          name="product.sizes"*/}
                    {/*          value={formik.values.product.sizes}*/}
                    {/*          onChange={formik.handleChange}*/}
                    {/*          labelId="sizes-label"*/}
                    {/*          renderValue={(selected: any) => selected.join(', ')}*/}
                    {/*      >*/}
                    {/*        {sizes.map((size: any) => (*/}
                    {/*            <MenuItem key={size} value={size}>*/}
                    {/*              <Checkbox checked={formik.values.product.sizes.indexOf(size) > -1} />*/}
                    {/*              <ListItemText primary={size} />*/}
                    {/*            </MenuItem>*/}
                    {/*        ))}*/}
                    {/*      </Select>*/}
                    {/*    </Form.Group>*/}
                    {/*)}*/}
                  </Form.Group>
                )}
                {formik.values.type === "COLLECTIBLE" && (
                  <Form.Group className="w-100 min-token-type-width mb-3">
                    <Form.Label className="font-18-bold">
                      TOKEN CATEGORY
                    </Form.Label>
                    {tokenCategories
                      .filter((tc) => !tc.isPortal)
                      .map((tc, i) => (
                        <Form.Group
                          key={`group-type-${i}`}
                          className="fixed-width-200 mb-1"
                        >
                          <Form.Check
                            name="category"
                            key={`check-type-${i}`}
                            className="font-16"
                            type="radio"
                            disabled={formDisabled}
                            label={`${tc.label}`}
                            checked={formik.values.category === tc.value}
                            onChange={() =>
                              formik.setFieldValue("category", tc.value)
                            }
                          />
                        </Form.Group>
                      ))}
                  </Form.Group>
                )}
              </div>
              {!formDisabled && (
                <div className="d-flex flex-row w-100">
                  <div className="pt-4 d-flex align-items-center flex-column w-100">
                    <Button
                      className="mt-4 w-100 orange-button mb-4"
                      type="submit"
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              )}
              <div className="d-flex flex-column w-100 mb-4">
                {(!active?.ipfsIcon ||
                  !active?.ipfsNftMetadata ||
                  !active?.ipfsContractMetadata) &&
                  active?._id && (
                    <Button
                      className="mt-4 w-100 orange-button mb-4"
                      onClick={() => uploadToIpfs(active?._id)}
                    >
                      Upload to IPFS
                    </Button>
                  )}
                {active?.ipfsIcon && (
                  <a
                    className="mb-2"
                    href={`${pinataUrl}${active?.ipfsIcon}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check ipfs icon
                  </a>
                )}
                {active?.ipfsNftMetadata && (
                  <a
                    className="mb-2"
                    href={`${pinataUrl}${active?.ipfsNftMetadata}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check ipfs nft metadata
                  </a>
                )}
                {active?.ipfsContractMetadata && (
                  <a
                    className="mb-2"
                    href={`${pinataUrl}${active?.ipfsContractMetadata}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check ipfs contract metadata
                  </a>
                )}
                {active?.ipfsIcon &&
                  active?.ipfsNftMetadata &&
                  active?.ipfsContractMetadata &&
                  !active?.address &&
                  active?._id && (
                    <Button
                      className="mt-4 w-100 orange-button mb-4"
                      onClick={() => deployToken(active?._id)}
                    >
                      Deploy smart contract
                    </Button>
                  )}
                {active?.address && (
                  <a
                    className="mb-2"
                    href={`${ethercanUrl}address/${active?.address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check smart contract on etherscan
                  </a>
                )}
                {active?.address && !active?.price && active?._id && (
                  <>
                    <Form.Group className="w-100 min-token-type-width mb-3">
                      <Form.Label className="font-18-bold">
                        TOKEN PRICE
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        placeholder=""
                        value={price}
                        onChange={(event) =>
                          setPrice(parseInt(event.target.value))
                        }
                      />
                    </Form.Group>
                    <Button
                      className="mt-4 w-100 orange-button mb-4"
                      onClick={() => startSale(active?._id, price)}
                    >
                      Start token sale
                    </Button>
                  </>
                )}
                {active?._id && (
                  <TokenSlidesCreator saveSlides={saveSlides} token={active} />
                )}
              </div>
            </Form>
          </FormikProvider>
        </Col>
      </Row>
      <div id="uppyDiv" className="DashboardContainer" />
    </Container>
  );
};

export default TokenComponent;
