import React from "react";

export function RobotIcon({ className }: DefaultIconProps) {
  return (
    <svg
      className={className}
      width="45"
      height="40"
      viewBox="0 0 45 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2422 3.49121H15.6055V17.2029H30.2422V3.49121Z"
        fill="#841842"
      />
      <path
        d="M15.6051 6.22754H12.6758V19.9393H15.6051V6.22754Z"
        fill="#78F1FB"
      />
      <path
        d="M15.6051 3.49121H12.6758V6.23741H15.6051V3.49121Z"
        fill="#78F1FB"
      />
      <path
        d="M33.1617 3.49121H30.2324V6.23741H33.1617V3.49121Z"
        fill="#4AA2BD"
      />
      <path
        d="M30.2422 0.745117H15.6055V3.49132H30.2422V0.745117Z"
        fill="#78F1FB"
      />
      <path
        d="M18.5348 3.49121H15.6055V14.4567H18.5348V3.49121Z"
        fill="#DE5049"
      />
      <path
        d="M33.1617 6.22754H30.2324V19.9393H33.1617V6.22754Z"
        fill="#4AA2BD"
      />
      <path
        d="M24.3844 22.6855H21.4551V25.4317H24.3844V22.6855Z"
        fill="#A72216"
      />
      <path
        d="M15.6051 22.6855H12.6758V25.4317H15.6051V22.6855Z"
        fill="#A72216"
      />
      <path
        d="M33.1617 22.6855H30.2324V25.4317H33.1617V22.6855Z"
        fill="#A72216"
      />
      <path
        d="M33.1617 25.4321H30.2324V28.1783H33.1617V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M21.4547 25.4321H18.5254V28.1783H21.4547V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M27.3121 25.4321H24.3828V28.1783H27.3121V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M15.6051 25.4321H12.6758V28.1783H15.6051V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M12.6773 25.4321H9.74805V28.1783H12.6773V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M9.74763 22.6855H6.81836V25.4317H9.74763V22.6855Z"
        fill="#5F0F33"
      />
      <path
        d="M9.74763 25.4321H6.81836V28.1783H9.74763V25.4321Z"
        fill="#841842"
      />
      <path
        d="M9.74763 28.1685H6.81836V30.9147H9.74763V28.1685Z"
        fill="#841842"
      />
      <path
        d="M39.0211 22.6855H36.0918V25.4317H39.0211V22.6855Z"
        fill="#5F0F33"
      />
      <path
        d="M39.0211 25.4321H36.0918V28.1783H39.0211V25.4321Z"
        fill="#841842"
      />
      <path
        d="M39.0211 28.1685H36.0918V30.9147H39.0211V28.1685Z"
        fill="#841842"
      />
      <path
        d="M12.6773 22.6855H9.74805V25.4317H12.6773V22.6855Z"
        fill="#D42E79"
      />
      <path
        d="M12.6773 28.1685H9.74805V30.9147H12.6773V28.1685Z"
        fill="#D42E79"
      />
      <path
        d="M6.81796 25.4321H3.88867V28.1783H6.81796V25.4321Z"
        fill="#D42E79"
      />
      <path
        d="M41.9488 25.4321H39.0195V28.1783H41.9488V25.4321Z"
        fill="#D42E79"
      />
      <path
        d="M3.89804 28.1685H0.96875V30.9147H3.89804V28.1685Z"
        fill="#ED79B2"
      />
      <path
        d="M44.8785 28.1685H41.9492V30.9147H44.8785V28.1685Z"
        fill="#ED79B2"
      />
      <path
        d="M9.74763 30.9146H6.81836V33.6608H9.74763V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M9.74763 33.6514H6.81836V36.3976H9.74763V33.6514Z"
        fill="#ED79B2"
      />
      <path
        d="M36.0914 25.4321H33.1621V28.1783H36.0914V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M36.0914 28.1685H33.1621V30.9147H36.0914V28.1685Z"
        fill="#D42E79"
      />
      <path
        d="M39.0211 30.9146H36.0918V33.6608H39.0211V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M39.0211 33.6514H36.0918V36.3976H39.0211V33.6514Z"
        fill="#ED79B2"
      />
      <path
        d="M27.3121 28.1685H24.3828V30.9147H27.3121V28.1685Z"
        fill="#D42E79"
      />
      <path
        d="M21.4547 28.1685H18.5254V30.9147H21.4547V28.1685Z"
        fill="#D42E79"
      />
      <path
        d="M24.3844 25.4321H21.4551V28.1783H24.3844V25.4321Z"
        fill="#5F0F33"
      />
      <path
        d="M24.3844 28.1685H21.4551V30.9147H24.3844V28.1685Z"
        fill="#841842"
      />
      <path
        d="M24.3844 30.9146H21.4551V33.6608H24.3844V30.9146Z"
        fill="#841842"
      />
      <path
        d="M27.3121 30.9146H24.3828V33.6608H27.3121V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M30.2418 30.9146H27.3125V33.6608H30.2418V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M30.2418 33.6514H27.3125V36.3976H30.2418V33.6514Z"
        fill="#D42E79"
      />
      <path
        d="M30.2418 36.3975H27.3125V39.1437H30.2418V36.3975Z"
        fill="#ED79B2"
      />
      <path
        d="M21.4547 30.9146H18.5254V33.6608H21.4547V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M18.5348 30.9146H15.6055V33.6608H18.5348V30.9146Z"
        fill="#D42E79"
      />
      <path
        d="M18.5348 33.6514H15.6055V36.3976H18.5348V33.6514Z"
        fill="#D42E79"
      />
      <path
        d="M18.5348 36.3975H15.6055V39.1437H18.5348V36.3975Z"
        fill="#ED79B2"
      />
      <path
        d="M39.0212 19.9395H6.81836V22.6857H39.0212V19.9395Z"
        fill="#BBBBBB"
      />
      <path
        d="M30.2422 14.4565H15.6055V17.2027H30.2422V14.4565Z"
        fill="#841842"
      />
      <path
        d="M24.3837 3.49121H15.6055V6.23741H24.3837V3.49121Z"
        fill="#EF9491"
      />
      <path
        d="M30.2418 3.49121H27.3125V6.23741H30.2418V3.49121Z"
        fill="#DE5049"
      />
      <path
        d="M27.3036 11.7104H18.5254V14.4566H27.3036V11.7104Z"
        fill="#EEE24F"
      />
      <path
        d="M27.3036 14.4565H18.5254V17.2027H27.3036V14.4565Z"
        fill="#E3A440"
      />
      <path
        d="M27.3036 6.22754H18.5254V8.97374H27.3036V6.22754Z"
        fill="#EEE24F"
      />
      <path
        d="M24.3844 8.97412H21.4551V11.7203H24.3844V8.97412Z"
        fill="#EEE24F"
      />
      <path
        d="M24.3844 14.4565H21.4551V17.2027H24.3844V14.4565Z"
        fill="#841842"
      />
      <path
        d="M24.3844 11.7104H21.4551V14.4566H24.3844V11.7104Z"
        fill="#E3A440"
      />
      <path
        d="M21.4547 8.97412H18.5254V11.7203H21.4547V8.97412Z"
        fill="#841842"
      />
      <path
        d="M27.3121 8.97412H24.3828V11.7203H27.3121V8.97412Z"
        fill="#841842"
      />
      <path
        d="M36.0923 17.2031H9.74805V19.9493H36.0923V17.2031Z"
        fill="#D7D7D7"
      />
      <path
        d="M36.0923 22.6855H9.74805V25.4317H36.0923V22.6855Z"
        fill="#D42E79"
      />
      <path
        d="M9.74763 19.9395H6.81836V22.6857H9.74763V19.9395Z"
        fill="#BBBBBB"
      />
      <path
        d="M12.6773 17.2031H9.74805V19.9493H12.6773V17.2031Z"
        fill="#EEEEEE"
      />
      <path
        d="M36.0914 17.2031H33.1621V19.9493H36.0914V17.2031Z"
        fill="#989898"
      />
      <path
        d="M9.74763 19.9395H6.81836V22.6857H9.74763V19.9395Z"
        fill="#BBBBBB"
      />
      <path
        d="M39.0211 19.9395H36.0918V22.6857H39.0211V19.9395Z"
        fill="#BBBBBB"
      />
      <path
        d="M30.2418 0.745117H27.3125V3.49132H30.2418V0.745117Z"
        fill="#4AA2BD"
      />
      <path
        d="M27.3121 0.745117H24.3828V3.49132H27.3121V0.745117Z"
        fill="#4AA2BD"
      />
      <path
        d="M39.0211 19.9395H36.0918V22.6857H39.0211V19.9395Z"
        fill="#989898"
      />
      <path
        d="M9.74763 19.9395H6.81836V22.6857H9.74763V19.9395Z"
        fill="#EEEEEE"
      />
    </svg>
  );
}
