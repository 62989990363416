import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../services/axios";
import toast from '../../components/Notistack';

import Layout from "components/Layout";

import StoriesComponent from "./StoriesComponent";
import { Story } from "interface";

const StoriesContainer = ({ brand }: any) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stories, setStories] = useState<Story[]>([]);

  const loadStories = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`api/routes/stories/get`);
      setStories(response?.data?.data || []);
      setIsLoading(false);
    } catch (error:any) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false);
    }
  }, []);

  const createStory = useCallback(
    async (values: Story) => {
      try {
        await axios.post(`api/routes/stories/create`, {values});
      } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message || error?.message)
      }
      void loadStories();
    },
    [loadStories],
  );

  const updateStory = useCallback(
    async (storyId: number, values: Story) => {
      try {
        await axios.put(`api/routes/stories/update`, {
          storyId,
          values,
        });
      } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message || error?.message)
      }
      void loadStories();
    },
    [loadStories],
  );

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/");
    } else {
      void loadStories();
    }
  }, [brand.isLogged, history, loadStories]);

  return (
    <Layout>
      <StoriesComponent
        createStory={createStory}
        updateStory={updateStory}
        isLoading={isLoading}
        stories={stories}
      />
    </Layout>
  );
};

export default connect((state: any) => ({
  brand: state.auth.user,
}))(StoriesContainer);
