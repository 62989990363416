import React from "react";

export function CloudsIcon({ className }: DefaultIconProps) {
  return (
    <svg
      className={className}
      width="179"
      height="117"
      viewBox="0 0 179 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.457 29.1431C124.457 45.2383 107.997 58.2861 87.6922 58.2861C67.3878 58.2861 50.9277 45.2383 50.9277 29.1431C50.9277 13.0478 67.3878 0 87.6922 0C107.997 0 124.457 13.0478 124.457 29.1431Z"
        fill="black"
      />
      <path
        d="M122.821 30.1469C122.821 46.1248 107.029 59.0775 87.5483 59.0775C68.0676 59.0775 52.2754 46.1248 52.2754 30.1469C52.2754 14.169 68.0676 1.21631 87.5483 1.21631C107.029 1.21631 122.821 14.169 122.821 30.1469Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M125.962 66.0978C125.962 80.6996 112.605 92.5367 96.1284 92.5367C79.6518 92.5367 66.2949 80.6996 66.2949 66.0978C66.2949 51.4959 79.6518 39.6587 96.1284 39.6587C112.605 39.6587 125.962 51.4959 125.962 66.0978Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M149.468 45.6671C149.468 60.269 136.111 72.1062 119.634 72.1062C103.158 72.1062 89.8008 60.269 89.8008 45.6671C89.8008 31.0652 103.158 19.228 119.634 19.228C136.111 19.228 149.468 31.0652 149.468 45.6671Z"
        fill="black"
      />
      <path
        d="M83.1709 54.0807C83.1709 68.6826 69.814 80.5197 53.3374 80.5197C36.8608 80.5197 23.5039 68.6826 23.5039 54.0807C23.5039 39.4788 36.8608 27.6416 53.3374 27.6416C69.814 27.6416 83.1709 39.4788 83.1709 54.0807Z"
        fill="black"
      />
      <path
        d="M86.0589 55.4278C86.0589 70.0297 72.3681 81.8668 55.4797 81.8668C38.5912 81.8668 24.9004 70.0297 24.9004 55.4278C24.9004 40.8259 38.5912 28.9888 55.4797 28.9888C72.3681 28.9888 86.0589 40.8259 86.0589 55.4278Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M47.613 77.2148C47.613 88.9959 36.9545 98.5462 23.8065 98.5462C10.6585 98.5462 0 88.9959 0 77.2148C0 65.4337 10.6585 55.8833 23.8065 55.8833C36.9545 55.8833 47.613 65.4337 47.613 77.2148Z"
        fill="black"
      />
      <path
        d="M48.2822 77.7217C48.2822 88.7916 37.8144 97.7656 24.9018 97.7656C11.9892 97.7656 1.52148 88.7916 1.52148 77.7217C1.52148 66.6517 11.9892 57.6777 24.9018 57.6777C37.8144 57.6777 48.2822 66.6517 48.2822 77.7217Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M149.984 48.205C149.984 63.5108 136.389 75.9186 119.618 75.9186C102.847 75.9186 89.252 63.5108 89.252 48.205C89.252 32.8991 102.847 20.4912 119.618 20.4912C136.389 20.4912 149.984 32.8991 149.984 48.205Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M178.999 54.6807C178.999 69.2827 165.642 81.1198 149.166 81.1198C132.689 81.1198 119.332 69.2827 119.332 54.6807C119.332 40.0789 132.689 28.2417 149.166 28.2417C165.642 28.2417 178.999 40.0789 178.999 54.6807Z"
        fill="black"
      />
      <path
        d="M178.111 54.5789C178.111 68.3595 164.658 79.5308 148.064 79.5308C131.47 79.5308 118.018 68.3595 118.018 54.5789C118.018 40.7983 131.47 29.627 148.064 29.627C164.658 29.627 178.111 40.7983 178.111 54.5789Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M49.4213 94.3386C49.4213 102.137 43.2151 108.46 35.5593 108.46C27.9035 108.46 21.6973 102.137 21.6973 94.3386C21.6973 86.5398 27.9035 80.2178 35.5593 80.2178C43.2151 80.2178 49.4213 86.5398 49.4213 94.3386Z"
        fill="black"
      />
      <path
        d="M49.8428 93.4554C49.8428 101.137 43.732 107.364 36.1939 107.364C28.6558 107.364 22.5449 101.137 22.5449 93.4554C22.5449 85.774 28.6558 79.5469 36.1939 79.5469C43.732 79.5469 49.8428 85.774 49.8428 93.4554Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M100.048 90.1339C100.048 104.736 86.6909 116.573 70.2143 116.573C53.7378 116.573 40.3809 104.736 40.3809 90.1339C40.3809 75.532 53.7378 63.6948 70.2143 63.6948C86.6909 63.6948 100.048 75.532 100.048 90.1339Z"
        fill="black"
      />
      <path
        d="M100.209 89.4461C100.209 103.74 87.1662 115.327 71.0771 115.327C54.9881 115.327 41.9453 103.74 41.9453 89.4461C41.9453 75.1526 54.9881 63.5654 71.0771 63.5654C87.1662 63.5654 100.209 75.1526 100.209 89.4461Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M165.741 78.1149C165.741 92.7167 152.384 104.554 135.908 104.554C119.431 104.554 106.074 92.7167 106.074 78.1149C106.074 63.513 119.431 51.6758 135.908 51.6758C152.384 51.6758 165.741 63.513 165.741 78.1149Z"
        fill="black"
      />
      <path
        d="M164.154 77.7873C164.154 91.8319 150.774 103.217 134.268 103.217C117.762 103.217 104.381 91.8319 104.381 77.7873C104.381 63.7428 117.762 52.3574 134.268 52.3574C150.774 52.3574 164.154 63.7428 164.154 77.7873Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M107.946 111.362C98.434 112.931 89.4478 106.836 87.8752 97.7489C86.3025 88.662 92.7389 80.0242 102.251 78.456C111.764 76.8876 120.75 82.9826 122.322 92.0695C123.895 101.156 117.459 109.794 107.946 111.362Z"
        fill="black"
      />
      <path
        d="M108.316 109.699C98.9637 111.267 90.1284 105.173 88.5821 96.0857C87.0359 86.9989 93.3643 78.3611 102.717 76.7929C112.07 75.2245 120.905 81.3194 122.451 90.4063C123.998 99.4931 117.669 108.131 108.316 109.699Z"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  );
}
