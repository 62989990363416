import React from "react";
import PropTypes from "prop-types";
import { Image, Modal } from "react-bootstrap";
import './components.scss'

const GameSaveModal = ({ 
    cancel,
    userGS
}) => {
    console.log('userGS', userGS)
    return (
        <Modal className="" show={true} onHide={() => cancel()} centered>
            <Modal.Body className="align-items-start d-flex flex-column">
                <Image 
                    className={"mt-3 mb-3 avatar"} 
                    src={userGS.userId.nodeID}
                    roundedCircle
                />
                <p className="font-18">PLAYER - {userGS.userId.username}</p>
                <p className="font-18">HUB - {userGS.userId.hub}</p>
                <p className="font-18">TYPE - {userGS.userId.creative}</p>
                <div className="d-flex flex-row w-100">
                    <p className="my-auto font-18">COLOR - {userGS.userId.favoriteColor}</p>
                    <p className={`my-auto ms-3 ${userGS.userId.favoriteColor.toLowerCase()}-span`}></p>
                </div>
                
            </Modal.Body>
            <Modal.Footer className="game-save-modal-footer">
                <div className="d-flex w-100 flex-row justify-content-center">
                    <p className="font-24-bold">{userGS.submission}</p>
                </div>
            </Modal.Footer>
        </Modal>
        );
};

GameSaveModal.propTypes = {
    cancel: PropTypes.func,
};

GameSaveModal.defaultProps = {
    cancel: () => {}
}
  
export default GameSaveModal;