import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import axios from "../../services/axios";
import toast from '../../components/Notistack';

import Layout from 'components/Layout';

import PortalComponent from './PortalComponent';
import {Portal} from 'interface';

const PortalContainer = ({ brand }: any) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [portals, setPortals] = useState<Portal[]>([]);

  const loadPortals = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`api/routes/portals/get`)
      setPortals(response?.data?.data || []);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false);
    }
  }, []);

  const createPortal = useCallback(async (values: Portal) => {
    try {
      await axios.post(`api/routes/portals/create`, {values})
    } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message || error?.message)
    }
    loadPortals();
  }, [loadPortals]);

  const updatePortal = useCallback(async (portalId: number, values: Portal) => {
    try {
      await axios.put(`api/routes/portals/update`, {
        portalId,
        values,
      })
    } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message || error?.message)
    }
    loadPortals()
  }, [loadPortals]);

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/")
    } else {
      loadPortals();
    }
  }, [loadPortals, brand, history]);

  return (
    <Layout>
      <PortalComponent
        createPortal={createPortal}
        updatePortal={updatePortal}
        isLoading={isLoading}
        portals={portals}
      />
    </Layout>
  );
}

export default connect((state: any) => ({
  brand: state.auth.user,
}))(PortalContainer);
