import React, {useEffect, useState} from 'react'


import { ReactComponent as PlayButton } from "../../images/svg/play-button.svg"
import { ReactComponent as PauseButton } from '../../images/svg/pause-button.svg';

import './AudioPlayer.scss'

import { useAudio } from '../../hooks/useAudio';


interface AudioPlayerProps {
    audioURL: string;
}

const AudioPlayer = ({ audioURL }: AudioPlayerProps) => {
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);

    useEffect(() => {
        const newAudio = new Audio(audioURL);
        setAudioElement(newAudio);
    }, [audioURL]);



    const { isAudioPlaying, onAudioToggle } = useAudio({ audio: audioElement });

 
    return (
        <div onClick={onAudioToggle} className='audio'>
            {!isAudioPlaying ? <PlayButton /> : <PauseButton />}
        </div>
    )
}


export default AudioPlayer;