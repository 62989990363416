export const categories = [
  {
    label: "News",
    value: "news",
  },
  {
    label: "Fun",
    value: "fun",
  },
];

export const types = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Image",
    value: "image",
  },
];
