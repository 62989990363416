import React from "react";

import { IResponse } from "../../../../interface";

interface Props {
  response: IResponse;
}

const Response = ({ response }: Props) => {
  return (
    <div className="d-flex w-100 flex-column row-gap-2 justify-content-end align-items-end">
      {response.fromPortal ? (
        <div className="d-flex w-100 column-gap-2 justify-content-end align-items-end">
          <div className="d-flex w-100 flex-column align-items-end justify-content-end w-100 row-gap-2">
            <div className="respons">{response.text}</div>
            <div
              className="font-12 bold w-100 align-items-end justify-content-end"
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              {response.portal.name}
            </div>
          </div>
          <img
            src={
              response.portal?.icon?.url?.includes("/routes/media/image")
                ? response.portal?.icon?.url
                : `api/routes/media/image/${response.portal.icon.url}`
            }
            alt="port"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ) : (
        <div className="d-flex w-100 column-gap-2 justify-content-end align-items-end">
          <img
            src={response.user?.nodeID}
            alt="port"
            style={{ width: "35px", height: "35px" }}
          />
          <div className="d-flex w-100 flex-column align-items-start justify-content-start w-100 row-gap-2">
            <div className="respons">{response.text}</div>
            <div
              className="font-12 bold w-100 align-items-start justify-content-start"
              style={{ textAlign: "start", fontWeight: "bold" }}
            >
              {response.user?.username}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Response;
