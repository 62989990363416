import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PickerDropPane } from 'filestack-react';
import { Form, ButtonGroup, Button } from "react-bootstrap";

type ITheme = 'light' | 'dark';

export interface Slide {
  id: number;
  mobileLink: string | null;
  desktopLink: string | null;
  mobileLinkDuration: number,
  desktopLinkDuration: number,
  theme: ITheme,
}

interface TokenSlidesCreatorProps {
  saveSlides: (slides: Slide[]) => void;
  token: any;
}

const { Config } = require("../../config.js");
const fileStackApiKey = Config[process.env.REACT_APP_ENV as any]["filestackApiKey"]

const defaultSlide: Slide = {
  id: 0,
  mobileLink: null,
  desktopLink: null,
  mobileLinkDuration: 0,
  desktopLinkDuration: 0,
  theme: 'light'
};

const pickerOptions = {
  accept: 'video/*',
  storeTo: {
    container: 'ic-filestack',
    region: 'us-west-1',
  },
};

export default function TokenSlidesCreator({
  saveSlides,
  token,
}: TokenSlidesCreatorProps) {
  const [slides, setSlides] = useState<Slide[]>([defaultSlide]);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const mobileDropPane: any = useRef();
  const desktopDropPane: any = useRef();

  useEffect(() => {
    if (token?.slides) {
      setSlides(token.slides);
    } else {
      setSlides([defaultSlide]);
    }
  }, [token]);

  const activeStoryObject = useMemo(() => slides.find((slide) => slide.id === activeSlide), [slides, activeSlide]);

  return (
    <>
      <ButtonGroup>
        {slides.map((story, index) => (
          <Button
            key={story.id}
            active={story.id === activeSlide}
            onClick={() => {
              setActiveSlide(story.id);
            }}
          >
            {`Story ${index}`}
          </Button>
        ))}
        <Button
          onClick={() => {
            setSlides((prevState) => [...prevState, {
              ...defaultSlide,
              id: (prevState[prevState.length - 1]?.id || 0) + 1,
            }])
          }}
        >
          Add a story
        </Button>
      </ButtonGroup>
      {activeStoryObject ? (
        <div className="d-flex flex-row w-100 flex-column">
          <Form.Group className="w-100 mb-4">
            <Form.Label className="font-18-bold">
              PORTAL
            </Form.Label>
            <Form.Control
              as="select"
              name="portal"
              value={activeStoryObject.theme || 'light'}
              onChange={(e) => {
                const newTheme = e.target.value as ITheme;
                setSlides((prevState) => prevState.map((slide) => slide?.id === activeStoryObject.id ? ({
                  ...slide,
                  theme: newTheme,
                }) : slide))
              }}
            >
              <option value='light'>
                Light
              </option>
              <option value='dark'>
                Dark
              </option>
            </Form.Control>
          </Form.Group>
          <div className="w-100 mb-4">
            <Form.Label className="font-18-bold">
              MOBILE VIDEO
            </Form.Label>
            <br />
            {activeStoryObject?.mobileLink ? (
              <video
                ref={mobileDropPane}
                controls
                src={`/api/routes/media/file/${activeStoryObject.mobileLink}/video`}
                height={300}
                onLoadedData={() => {
                  const mobileLinkDuration = mobileDropPane?.current?.duration;
                  setSlides((prevState) => prevState.map((slide) => slide?.id === activeStoryObject.id ? ({
                    ...slide,
                    mobileLinkDuration,
                  }) : slide))
                }}
              />
            ) : (
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log('error', error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    setSlides((prevState) => [...prevState.map((story) => story.id === activeSlide ? ({
                      ...story,
                      mobileLink: res.filesUploaded[0].key,
                    }) : story)]);
                  }
                }}
                pickerOptions={pickerOptions}
              />
            )}
          </div>
          <div className="w-100 mb-4">
            <Form.Label className="font-18-bold">
              DESKTOP VIDEO
            </Form.Label>
            <br />
            {activeStoryObject?.desktopLink ? (
              <video
                ref={desktopDropPane}
                controls
                src={`/api/routes/media/file/${activeStoryObject.desktopLink}/video`}
                height={300}
                onLoadedData={() => {
                  const desktopLinkDuration = desktopDropPane?.current?.duration;
                  setSlides((prevState) => prevState.map((slide) => slide?.id === activeStoryObject.id ? ({
                    ...slide,
                    desktopLinkDuration,
                  }) : slide))
                }}
              />
            ) : (
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log('error', error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    setSlides((prevState) => [...prevState.map((story) => story.id === activeSlide ? ({
                      ...story,
                      desktopLink: res.filesUploaded[0].key,
                    }) : story)]);
                  }
                }}
                pickerOptions={pickerOptions}
              />
            )}
          </div>
          <Button onClick={() => {
            setSlides((prevState) => [...prevState.filter((story) => story.id !== activeSlide)])
          }}>
            Remove Story
          </Button>
          <Button className="mt-4 w-100 orange-button mb-4" onClick={() => saveSlides(slides)}>
            SAVE STORIES
          </Button>
        </div>
      ) : null}
    </>
  );
}
