import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Header from "./Header";

export default function Layout({ children }) {
  return (
    <Container className="min-height" fluid>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col xs={2} className="no-side-paddings sidebar-wrapper">
          <Sidebar />
        </Col>
        <Col xs={10} className="background-light-grey page-content-wrapper">
          <div className="d-flex flex-row w-100 h-100">
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
