import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import Sidebar from "components/Sidebar"
import Header from "components/Header"
import UserComponent from "./UserComponent";

const VerifyUsersComponent = ({
  isLoading,
  users,
  approvePushuser,
  patchUser,
  sendGoborb,
  sendNft,
  sendRoyaltyAmount,
}) => {
  return (
    <>
      {isLoading &&
        <div className="bouncer-loading">
          <BounceLoader
            size={25}
            color={"#000"}
            loading={isLoading}
          />
        </div>
      }
      <Container className="min-height" fluid>
        <Row>
          <Header />
        </Row>
        <Row className={`${isLoading ? "opacity-5" : ""}`}>
          <Col xs={2} className="no-side-paddings sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} className="background-light-grey page-content-wrapper">
            <div className="mt-5 mb-5 ms-5 me-5 justify-content-center d-flex flex-column">
              {users.map(user => (
                <UserComponent
                  key={user._id}
                  approvePushuser={approvePushuser}
                  patchUser={patchUser}
                  user={user}
                  sendGoborb={sendGoborb}
                  sendNft={sendNft}
                  sendRoyaltyAmount={sendRoyaltyAmount}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

VerifyUsersComponent.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.array,
  approvePushuser: PropTypes.func,
  patchUser: PropTypes.func.isRequired,
  sendGoborb: PropTypes.func.isRequired,
  sendNft: PropTypes.func.isRequired,
  sendRoyaltyAmount: PropTypes.func.isRequired,
};

VerifyUsersComponent.defaultProps = {
  isLoading: false,
  users: [],
  approvePushuser: () => { },
}

export default VerifyUsersComponent;
