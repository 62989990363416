import React from "react";
import classNames from "classnames";

import styles from "./Text.module.scss";

interface TextProps extends React.PropsWithChildren<{}> {
  className?: string;
  size?: "small" | "medium" | "large";
  weight?:
    | "regular"
    | "mediumbold"
    | "semibold"
    | "bold"
    | "bolder"
    | "extrabold";
  color?: "white" | "yellow";
}

export function Text({
  children,
  className,
  size = "medium",
  weight = "mediumbold",
  color = "white",
}: TextProps) {
  const classes = classNames(
    styles.text,
    styles[size],
    styles[weight],
    styles[color],
    className
  );

  return <div className={classes}>{children}</div>;
}
