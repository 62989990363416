"use client";
import React from "react";
import classNames from "classnames";
import type { TickerData } from "./types";
import { Text } from "../UI";

import styles from "./Ticker.module.scss";

interface TickerProps {
  data: TickerData;
}

export function Ticker({ data }: TickerProps) {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const timerRef = React.useRef<ReturnType<typeof setInterval> | null>(null);

  function startTimer() {
    timerRef.current = setTimeout(() => {
      setSelectedIndex((prev) => (prev >= data.length - 1 ? 0 : prev + 1));
    }, 5000);
  }

  React.useEffect(() => {
    startTimer();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [selectedIndex]);

  return (
    <div className={styles.ticker}>
      <Text
        key={selectedIndex}
        className={classNames(styles.item, styles.active)}
        size="small"
        weight="mediumbold"
      >
        {data[selectedIndex].text}
      </Text>
    </div>
  );
}
