import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { PageLoader, Actions } from "./components";
import PublicLayout from "./layout";

import styles from "./Landing.module.scss";

export function LandingNew() {
  const history = useHistory();
  const user = useSelector((state: any) => state.auth.user);

  React.useEffect(() => {
    if (user.isLogged) {
      history.push("/token");
    }
  }, [user, history]);

  return (
    <PublicLayout>
      <main className={styles.page}>
        <PageLoader>Ledger</PageLoader>
        <Actions />
      </main>
    </PublicLayout>
  );
}
