import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import VerifyUsersComponent from "./VerifyUsersComponent";
import axios from "../../services/axios";
import toast from "../../components/Notistack"

const VerifyUsersContainer = ({ brand }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const history = useHistory();

  const approvePushuser = async (user) => {
    setIsLoading(true)
    try {
      await axios.post(`/api/routes/users/approvePushuser`, user);
      getAllUsers()
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const patchUser = async (user, payload) => {
    setIsLoading(true)
    try {
      await axios.put(`/api/routes/users/updateUser`, {
        email: user.email,
        type: user.type,
        patchData: payload,
      });
      getAllUsers()
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const getAllUsers = async () => {
    setIsLoading(true)
    try {
      let response = await axios.get(`api/routes/users/getAllImagineUsers`)
      if (response.status === 200) {
        let sortedUsers = response.data.users.sort((a, b) => a.verified - b.verified || !a.onboarding - !b.onboarding);
        setUsers(sortedUsers)
      }
      setIsLoading(false)
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }

  const sendGoborb = useCallback(async (amount, userEthAddress) => {
    if (!amount || !amount.length) {
      toast.warning('Input GOBORB amount');
      return;
    }

    try {
      await axios.post(`/api/routes/blockchain/token/send`, {
        amount,
        userEthAddress,
      });
      toast.success('GOBORB sent');
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }, []);

  const sendNft = useCallback(async (nftAddress, userEthAddress) => {
    if (!nftAddress || !nftAddress.length) {
      toast.warning('Input NFT address');
      return;
    }

    try {
      await axios.post(`/api/routes/blockchain/token/send`, {
        nftAddress,
        userEthAddress,
      });
      toast.success('NFT sent');
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }, []);

  const sendRoyaltyAmount = useCallback(async (royalties, userEmail) => {
    if (!royalties || !userEmail) {
      toast.warning('Input royalties amount');
      return;
    }

    try {
      await axios.post('/api/routes/users/updateRoyalties', {
        email: userEmail,
        connectRoyalties: royalties && royalties > 0,
        royalties: +royalties,
      });
      toast.success('Royalties set');
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }, []);

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/")
    } else {
      getAllUsers();
    }
  }, [brand.isLogged, history])

  return (
    <VerifyUsersComponent
      users={users}
      approvePushuser={approvePushuser}
      patchUser={patchUser}
      isLoading={isLoading}
      sendGoborb={sendGoborb}
      sendNft={sendNft}
      sendRoyaltyAmount={sendRoyaltyAmount}
    />
  );
};

export default connect(state => ({
  brand: state.auth.user,
}))(VerifyUsersContainer);

