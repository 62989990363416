import React, { ChangeEvent, useState } from "react";

import {Feed, IResponse, Message} from "../../../../interface";
import Response from "../Response/Response";
import axios from "../../../../services/axios";
import toast from "../../../../components/Notistack";

interface Props {
  responses: IResponse[];
  loading: boolean;
  message: Message;
  updateMessageResponses: (response: any) => void;
  feed: Feed;
}

const Responses = ({
  responses,
  message,
  updateMessageResponses,
  feed,
}: Props) => {
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [localRes, setLocalRes] = useState<IResponse[]>(
    [...responses].reverse(),
  );

  async function reply() {
    try {
      setLoading(true);
      const body = {
        text,
        userId: null,
        messageId: message?._id,
        portalId: feed?.portalID,
        portal: {
          icon: { url: feed?.portalFull?.icon },
          name: feed?.portalFull?.name,
        },
        fromPortal: true,
        fromUser: false,
        user: null,
      };

      const res = await axios.post("api/routes/messages/response", body);
      if (res.data) {
        await updateMessageResponses(body);
        setLocalRes((prevState) => [...prevState, body]);
        setText("");
      }
      setLoading(false);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setLoading(false);
      console.error(error);
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    e.stopPropagation();
    setText(e.target.value);
  }

  return (
    <div className="d-flex flex-column w-75 align-items-end justify-content-end mt-3 row-gap-4">
      {localRes.map((res, i) => (
        <Response response={res} key={i} />
      ))}

      <div className="w-100 d-flex justify-content-end align-items-end">
        {localRes[localRes.length - 1].fromUser && (
          <div className="d-flex flex-column w-75 align-items-end row-gap-2">
            <textarea
              name="reply"
              id="reply"
              className="w-100 font-12 p-2"
              style={{ height: "100px" }}
              onChange={handleChange}
              disabled={loading}
            ></textarea>
            {loading ? (
              <div className="respond-loading w-25">reply</div>
            ) : (
              <div className="respond w-25" onClick={reply}>
                reply
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Responses;
