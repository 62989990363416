import React, { useEffect, useState } from "react";
import axios from "../../../../services/axios";
import toast from '../../../../components/Notistack';

import BounceLoader from "react-spinners/BounceLoader";
import MessageItem from "../Message/Message";

import {Feed, IResponse, Message} from "../../../../interface";

interface Props {
  feed: Feed;
}

const Messages = ({ feed }: Props) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getMessages() {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `api/routes/messages/messages/${feed?._id}`,
        );
        if (data?.messages) {
          setMessages(data.messages);
        }
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setLoading(false);
      }
    }
    void getMessages();
  }, [feed?._id]);

  function updateMessageResponses(response: IResponse) {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message._id === response.messageId
          ? { ...message, responses: [...(message.responses || []), response] }
          : message,
      ),
    );
  }

  return (
    <div className="mb-4">
      <div className="font-18-bold mb-3">MESSAGES</div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-50">
          <div className="bouncer-loading-small">
            <BounceLoader size={15} color={"#000"} loading={loading} />
          </div>
        </div>
      ) : (
        <>
          {!messages?.length ? (
            <div className="mb-3 w-100 font-16-bold">
              No messages for this feed...
            </div>
          ) : (
            <div className="d-flex flex-column row-gap-4 messages_box">
              {messages.map((message) => (
                <MessageItem
                  key={message._id}
                  message={message}
                  updateMessageResponses={updateMessageResponses}
                  feed={feed}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Messages;
