import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Image, Container } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import Sidebar from "../../components/Sidebar"
import Header from "../../components/Header"
import arrowDown from "images/svg/arrow-down.svg"
import Modal from "components/GameSaveModal"
import {gameSaveStatus} from "constants/gameSaveStatus";
import "./imagine.css"
const { Config } = require("../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV]["picturePrefix"]

const ImagineComponent = ({
    isLoading,
    topObjects,
    selectTopObject,
    game,
    token,
    products,
    getGame,
    gameSaves
}) => {
  const [showModal, setShowModal] = useState(false)
  const [gameData, setGameData] = useState(false)
  const [userGS, setUserGS] = useState({})
  const usersPlayed = gameSaves.filter(gs => gs.userGs.state === gameSaveStatus.SUBMITTED)
  const usersVoted = gameSaves.filter(gs => gs.userGs.state === gameSaveStatus.VOTED)

  useEffect(() => {
    if (Object.keys(userGS).length) {
        setShowModal(true)
    }
  }, [userGS])

  return (
    <>
    {isLoading &&
    <div className="bouncer-loading">
      <BounceLoader
        size={25}
        color={"#000"}
        loading={isLoading}
      />
    </div>
    }
     <Container className="min-height" fluid>
         <Row>
             <Header />
         </Row>
        <Row className={`${isLoading ? "opacity-5" : ""}`}>
            <Col xs={2} className="no-side-paddings sidebar-wrapper">      
                <Sidebar />
            </Col>
            <Col xs={10} className="no-side-paddings background-light-grey page-content-wrapper">
                <div className="border-bottom-black d-flex flex-row">
                    <div className="width-15 align-items-center d-flex flex-column pb-3 pt-3 pe-3 ps-3 border-right-black">
                        <p className="text-center font-18">{token.name}</p>
                        <Image roundedCircle className="token-image" src={`${picturePrefix}/${token?.image?.url}.png`} />
                    </div>
                    <div className="w-100 d-flex flex-row my-auto ms-5 me-5">
                        {products.map((p, i) => (
                            <div className="d-flex flex-row w-100">
                                <Image roundedCircle className="ms-4 token-image" src={`${picturePrefix}/${p?.productImage?.url}.png`} />
                                {[...Array(p.numberOfGames)].map((n, i) => (
                                    <p onClick={() => getGame(p, i)} className={`${i < p.gameStatus[i].state === "SUBMISSION" ? "orange-text" : ""} cursor-pointer my-auto w-100 text-center font-24-bold`}>{i+1}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="cursor-pointer game-data position-relative">
                    <p onClick={() => setGameData(!gameData)} className="text-center yellow-wrapper font-18">SEE GAME DATA <img alt="arrow-down" className={`${gameData ? "rotate-arrow" : "normal-arrow"}`} src={arrowDown} /></p>
                   {gameData && 
                        <div className="game-data-details">
                            <div className="w-100 ps-3 pe-3 pt-3 pb-3">
                                <p className="word-break font-18">HUBS: {game.gameHubs?.join()}</p> 
                                <p className="word-break font-18">USER TYPE: {game.gameCreative?.join()}</p> 
                                <p className="word-break font-18">COLORS: {game.gameColors?.join()}</p> 
                                <p className="font-18">PLAYERS: {gameSaves.length}</p>    
                                <p className="font-18">PLAYED: {usersPlayed.length}/{gameSaves.length}</p>    
                                <p className="font-18">VOTED: {usersVoted.length}/{gameSaves.length}</p>    
                            </div>
                        </div>    
                   }          
                </div>
                {gameSaves.length > 0 && 
                <div className="w-100 d-flex flex-wrap pl-3 pr-3 pt-2">
                    {gameSaves.map((gs, i) => (
                        <div onClick={() => selectTopObject(gs.object)} className="me-5 ms-2 cursor-pointer d-flex flex-column">
                            <Image 
                                key={i}
                                className={`mt-3 mb-1 mx-auto avatar ${topObjects.some(t => t === gs?.object?._id) ? "selected-circle" : ""}` } 
                                src={gs.userGs.userId.nodeID}
                                roundedCircle
                            />
                            <p onClick={() => {game && game.state === "COMPLETED" && setUserGS(gs.userGs)}} className="text-center cursor-pointer font-16">{gs.userGs.userId.username}</p>
                            <div className="d-flex flex-row justify-content-center">
                                {(gs.userGs.state === gameSaveStatus.SUBMITTED || gs.userGs.state === gameSaveStatus.VOTED) && <div className="red-circle"></div>}
                                {gs.userGs.state === gameSaveStatus.VOTED && <div className="ms-1 blue-circle"></div>}
                            </div>
                            {gs.userGs.state === gameSaveStatus.VOTED && <p className="font-16 text-center">{gs.userGs.percentage}%</p>}
                        </div>
                    ))}
                </div>
                }
            </Col> 
        </Row>

        {showModal && 
         <Modal
            userGS={userGS}
            cancel={() => {
                setUserGS({})
                setShowModal(false)
            }}
         />
        }
    </Container>
    </>
    );
};

ImagineComponent.propTypes = {
    isLoading: PropTypes.bool,
    getGame: PropTypes.func,
    selectTopObject: PropTypes.func,
    game: PropTypes.object,
    token: PropTypes.object,
    products: PropTypes.array,
    gameSaves: PropTypes.array
};

ImagineComponent.defaultProps = {
    isLoading: false,
    getGame: () => {},
    selectTopObject: () => {},
    gameSaves: [],
    game: {},
    token: {}
}

export default ImagineComponent;
