import React from "react";
import classNames from "classnames";
import { Text } from "../UI";

import styles from "./PageLoader.module.scss";

interface PageLoaderProps extends React.PropsWithChildren<{}> {
  className?: string;
}

export function PageLoader({ children, className }: PageLoaderProps) {
  const classes = classNames(styles.container, className);

  return (
    <div className={classes}>
      <div className={styles.top}>
        <Text className={styles.title} size="small" weight="extrabold">
          {children}
        </Text>
        <div className={styles.dashes} />
      </div>
      <div className={styles.loader} />
    </div>
  );
}
