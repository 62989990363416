import React, { useRef, useEffect } from "react";

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  if (ref.current)
    return ref.current

  return null
};


export const useWidthBreakpoint = (breakpoint = 768) => {
  const [width, setWidth] = React.useState<number>(window.innerWidth)

  React.useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth)
      }
    }

    window.addEventListener("resize", handleWindowSizeChange)

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  return width <= breakpoint
}