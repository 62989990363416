import React, {useCallback, useEffect, useState} from "react";
import { connect } from "react-redux";
import axios from "../../services/axios";
import BounceLoader from "react-spinners/BounceLoader";
import toast from "../../components/Notistack";
import Layout from "components/Layout";
import BrandComponent from "./BrandComponent";
import { Brand } from "../../interface";

const BrandContainer = ({ brand }: any) => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadBrands = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`api/routes/brands/all`);
      setBrands(response?.data?.data || []);
      setIsLoading(false);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, []);

  const updateBrand = useCallback(async (brandId: string, values: object) => {
    try {
      setIsLoading(true);
      const response = await axios.put(`api/routes/brands/${brandId}`, values);
      if (response?.data?.status === 200) {
        loadBrands();
      }
      setIsLoading(false);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, [loadBrands]);

  useEffect(() => {
    loadBrands();
  }, [loadBrands]);


  return (
    <Layout>
        {isLoading ? (
            <div className="bouncer-loading">
                <BounceLoader size={25} color={"#000"} loading={true}/>
            </div>
        ) : (
            <BrandComponent brands={brands} onBrandUpdate={updateBrand} />
        )}
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  brand: state.brand,
});

export default connect(mapStateToProps)(BrandContainer);
