import React from "react";

const SelectTimeType = ({ setTimeType }) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    let value;
    switch (event.target.value) {
      case "MIN":
        value = 5;
        break;
      default:
        value = 1;
    }
    setTimeType({ selectedValue, value });
  };

  return (
    <select
      name="choice"
      onChange={handleSelectChange}
      className="d-flex align-items-center justify-content-center px-1 m-3 font-12 p-1"
    >
      <option value="MIN">MIN</option>
      <option value="HRS">HRS</option>
      <option value="DAYS">DAYS</option>
      <option value="MONTHS">MONTHS</option>
    </select>
  );
};

export default SelectTimeType;
