import React, { createContext, useContext, useState, useCallback } from "react";
import { connect } from "react-redux";
import toast from "../components/Notistack";
import {
  loginUser as logIn,
  logOutUser as logOut,
} from "../store/actions/auth";

// TODO use instead of redux store
const AuthContext = createContext<any>({
  isLogged: false,
  logoutUser: () => false,
  loginUser: () => false,
  signUpUser: () => false,
  resetPassword: () => false,
});

export const AuthProvider = connect(
  (state: any) => ({
    user: state.auth.user,
  }),
  {
    logIn,
    logOut,
  }
)(({ children, logIn, logOut, user }: any) => {
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const logoutUser = useCallback(async () => {
    try {
      logOut();
      setIsLogged(false);
      window.location.href = "/";
    } catch (error) {
      toast.warning("There was an error logging out, please try again!");
    }
  }, [logOut]);

  const loginUser = useCallback(
    async (values: any) => {
      let data = await fetch("/api/routes/brands/loginBrand", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(values),
        headers: { "Content-Type": "application/json" },
      });
      const response = await data.json();
      if (
        (response.status && response?.status !== 200) ||
        response?.message !== "Success"
      ) {
        if (response?.message) {
          toast.warning(response?.message);
        } else {
          toast.warning("Check your email/password and try again!");
        }
      } else {
        setIsLogged(true);
        logIn({ user: response.brand });
        window.location.href = "/token";
      }
    },
    [logIn]
  );

  const signUpUser = useCallback(
    async (values: any) => {
      let data = await fetch("/api/routes/brands/signUpBrand", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(values),
        headers: { "Content-Type": "application/json" },
      });

      const response = await data.json();
      if (
        ((response.status && response?.status !== 200) || response?.message !== "Success")
      ) {
        toast.warning("Check your email/password and try again!")
      }

      window.location.href = "/brand";
    }
    ,
    []
  );

  const resetPassword = useCallback(async (values: any) => {
    let data = await fetch("/api/routes/brands/forgotPassword", {
      method: "POST",
      body: JSON.stringify(values),
      headers: { "Content-Type": "application/json" },
    });
    const response = await data.json();
    if (
      (response.status && response?.status !== 200) ||
      response?.message !== "Success"
    ) {
      toast.warning(
        "Something went wrong with changing your password, please contact us for assistance!"
      );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        logoutUser,
        loginUser,
        signUpUser,
        resetPassword,
        userData: user?.data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth = () => useContext(AuthContext);
