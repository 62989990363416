import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import Tus from '@uppy/tus'

import 'uppy/dist/uppy.min.css'

const startUppy = (triggerClass, targetClass, options = {}) => {
  return new Uppy({
    ...options,
    debug: options.debug || true,
    autoProceed: options.autoProceed || false,
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ["image/*"],
    },
  })
    .use(Dashboard, {
      trigger: triggerClass,
      inline: false,
      target: targetClass,
      replaceTargetContent: true,
      showProgressDetails: true,
      note: "Only 1 image",
      height: 470,
      metaFields: [
        { id: "name", name: "Name", placeholder: "file name" },
        {
          id: "caption",
          name: "Caption",
          placeholder: "describe what the image is about",
        },
      ],
      browserBackButtonClose: true,
    })
    .use(ImageEditor, { target: Dashboard })
    .use(Tus, { endpoint: "https://tusd.tusdemo.net/files/" });
};

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export {
  startUppy,
  blobToBase64,
};
