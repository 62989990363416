import React from "react";
import classNames from "classnames";
import type { TickerData } from "../Ticker/types";
import { Blank, Button, Text } from "../UI";
import { AuthModal } from "../AuthModal";
import { Ticker } from "../Ticker";
import { useWidthBreakpoint } from "hooks";
import { ReactComponent as LogoIcon } from "../../../../images/svg/ppw-logo.svg"

import styles from "./Header.module.scss";

interface HeaderProps {
  className?: string;
}

const tickerData: TickerData = [
  { text: "Single - Multiplayer - Collab" },
  { text: "Automation" },
  { text: "Predictive Analytics and Insights" },
  { text: "Artificial Inteligence " },
  { text: "Supply Chain Optimization" },
  { text: "Customer Experience Management" },
  { text: "Blockchain" },
  { text: "On Chain and Off Chain" },
  { text: "Smart Contracts" },
  { text: "Immutable Records" },
  { text: "Token Creation" },
  { text: "Real-Time Value Tracking" },
  { text: "Spatial Computing" },
  { text: "Product Simulation" },
  { text: "Data Visualization" },
  { text: "NLP" },
];

export function Header({ className }: HeaderProps) {
  const isMobile = useWidthBreakpoint();
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState<boolean>(false);

  const classes = classNames(styles.header, className);

  function onAuthModalToggle() {
    setIsAuthModalOpen((prev) => !prev)
  }

  return (
    <>
      <header className={classes}>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Blank className={styles.blank} linePosition="right">
              <Text className={styles.title} weight="bolder">PORTAL PATHWAY</Text>
            </Blank>
            <LogoIcon className={styles.logo} />
            {!isMobile ? (
              <div className={styles.tickerWrapper}>
                <Text size="small" weight="extrabold">
                  WEB3 ENTERPRISE SOFTWARE
                </Text>
                <Ticker data={tickerData} />
              </div>
            ) : null}
          </div>
          <Button className={styles.go} onClick={onAuthModalToggle}>GO</Button>
        </div>
        {isMobile ? (
          <div className={styles.tickerWrapper}>
            <Text size="small" weight="extrabold">
              WEB3 ENTERPRISE SOFTWARE
            </Text>
            <Ticker data={tickerData} />
          </div>
        ) : null}
      </header>
      {isAuthModalOpen ? <AuthModal onModalToggle={onAuthModalToggle} /> : null}
    </>
  );
}
