import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import HttpsRedirect from 'react-https-redirect';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initStore from "./store/store";

const store = initStore();
ReactDOM.render(
    <React.StrictMode>
        <HttpsRedirect>
            <App store={store}/>
        </HttpsRedirect>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
