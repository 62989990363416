import React from "react";

export function CoinIcon({ className }: DefaultIconProps) {
  return (
    <svg
      className={className}
      width="35"
      height="40"
      viewBox="0 0 35 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.9471 13.522H14.125V19.9105H20.9471V13.522Z" fill="#D0A949" />
      <path
        d="M20.9471 19.9102H14.125V26.2987H20.9471V19.9102Z"
        fill="#EFDD5B"
      />
      <path
        d="M20.9374 0.745117H14.1249H7.30273V7.13364H14.1249H20.9374H27.7595V0.745117H20.9374Z"
        fill="#EFDD5B"
      />
      <path
        d="M7.30261 7.1333H0.480469V13.5218H7.30261V7.1333Z"
        fill="#EFDD5B"
      />
      <path
        d="M34.5819 7.1333H27.7598V13.5218H34.5819V7.1333Z"
        fill="#EFDD5B"
      />
      <path
        d="M27.7595 13.522V19.9105H20.9374V26.299H14.1249H7.30273V32.6972V39.0857H14.1249H20.9374H27.7595V32.6972H34.5816V26.299V19.9105V13.522H27.7595Z"
        fill="#D0A949"
      />
      <path
        d="M27.7594 13.5218V7.1333H20.9372H14.1247H7.30261V13.5218H0.480469V19.9104V26.2989V32.6971H7.30261V39.0856H14.1247V32.6971H20.9372V26.2989H14.1247V19.9104V13.5218H20.9372V19.9104H27.7594H34.5815V13.5218H27.7594Z"
        fill="#B68C34"
      />
    </svg>
  );
}
