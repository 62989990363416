import * as Yup from "yup";

export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required("Required field!"),
  lastName: Yup.string().required("Required field!"),
  phoneNumber: Yup.string().required("Required field!"),
  password: Yup.string().min(8, "Too Short!").required("Required field!"),
  type: Yup.string().optional(),
  role: Yup.string().required("Required field!"),
  email: Yup.string().email("Invalid email").required("Required field!"),
});
