import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

interface ButtonProps
  extends React.PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  className?: string;
  variant?: "yellow";
  onClick?: () => void;
}

export function Button({
  children,
  className,
  variant = "yellow",
  onClick,
  ...props
}: ButtonProps) {
  const classes = classNames(styles.button, styles[variant], className);

  return (
    <button className={classes} onClick={onClick} {...props}>
      {children}
    </button>
  );
}
