import React from 'react'

interface UseAudioHookProps {
  audio: HTMLAudioElement | null;
}

export function useAudio({ audio }: UseAudioHookProps) {
  const [isAudioPlaying, setIsAudioPlaying] = React.useState<boolean>(false);

  function onAudioPlay() {
    setIsAudioPlaying(true);
    audio?.play();
  };

  function onAudioPause() {
    setIsAudioPlaying(false);
    audio?.pause();
  };

  function onAudioToggle() {
    setIsAudioPlaying(prev => !prev)

    if (isAudioPlaying) {
      audio?.pause()
    } else {
      audio?.play()
    }
  }

  React.useEffect(() => {
    audio?.addEventListener('play', onAudioPlay);
    audio?.addEventListener('pause', onAudioPause);

    return () => {
      audio?.removeEventListener('play', onAudioPlay);
      audio?.removeEventListener('pause', onAudioPause);
      onAudioPause();
      audio?.pause();
    };
  }, [audio])

  return { isAudioPlaying, onAudioPlay, onAudioPause, onAudioToggle }
}
