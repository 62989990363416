import React from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as SwitchSvg } from "../../../images/svg/switch.svg";

const ChooseGameUsers = ({
  createGame,
  onCheckedAll,
  filters,
  gamesCreated,
  onValueChecked,
  queryType,
  onQueryTypeChange,
}) => {
  return (
    <div className="w-100 d-flex flex-row border-bottom-black pe-3 ps-3 pt-3 pb-3">
      <div className="my-auto w-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-center">
          <p
            className={`font-16 mb-2 text-center ${
              !queryType ? "" : "ps-2 pe-2 yellow-wrapper"
            }`}
          >
            DIRECT
          </p>
        </div>
        <Button
          disabled={createGame && gamesCreated > 0}
          onClick={onQueryTypeChange}
          className={`transform-button no-style-button ${
            queryType ? "transform-rotate" : "no-transform-rotate"
          }`}
        >
          <SwitchSvg />
        </Button>
        <div className="mt-2 d-flex flex-row justify-content-center">
          <p
            className={`font-16 mb-2 text-center ${
              queryType ? "" : "ps-2 pe-2 yellow-wrapper"
            }`}
          >
            INDIRECT
          </p>
        </div>
      </div>
      {filters.map((filter, index) => (
        <div
          key={`div-wrapper-${index}`}
          className="w-100 ms-2 me-2 d-flex flex-column"
        >
          <div
            key={`div-filter-key-${index}`}
            className="d-flex flex-row w-100"
          >
            <p
              key={`p-key-${index}`}
              className="font-16 ps-3 pe-3 text-center yellow-wrapper"
            >
              {filter.key}
            </p>
            <Form.Check
              key={`form-check-all-${index}`}
              className="w-100 ms-2"
              type="checkbox"
              onChange={(e) => onCheckedAll(index, e)}
              label="All"
            />
          </div>
          <ul className="list-unstyled card-columns">
            {Object.keys(filter.value).map((k, i) => (
              <Form.Group
                key={`form-group-${i}`}
                className="fixed-width-100 mb-1"
              >
                <Form.Check
                  disabled={createGame && gamesCreated > 0}
                  key={`form-check-${i}`}
                  name={k}
                  checked={filter.value[k]}
                  onChange={(e) => onValueChecked(index, e)}
                  type="checkbox"
                  label={k}
                />
              </Form.Group>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

ChooseGameUsers.propTypes = {
  onValueChecked: PropTypes.func.isRequired,
  onCheckedAll: PropTypes.func.isRequired,
  queryType: PropTypes.bool,
  onQueryTypeChange: PropTypes.func,
};

ChooseGameUsers.defaultProps = {
  queryType: true,
  onQueryTypeChange: () => {},
};

export default ChooseGameUsers;
