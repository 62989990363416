import React, { ChangeEvent, useState } from "react";

import { ReactComponent as Exit } from "../../../../images/svg/exit-white.svg";

import {Feed, Message} from "../../../../interface";
import Responses from "../Responses/Responses";
import axios from "../../../../services/axios";

interface Props {
  message: Message;
  updateMessageResponses: (response: any) => void;
  feed: Feed;
}

const MessageItem = ({ message, updateMessageResponses, feed }: Props) => {
  const [input, setInput] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  async function reply() {
    try {
      setLoading(true);
      const body = {
        text,
        userId: null,
        messageId: message?._id,
        portalId: feed?.portalID,
        portal: {
          icon: { url: feed?.portalFull?.icon },
          name: feed?.portalFull?.name,
        },
        fromPortal: true,
        fromUser: false,
        user: null,
      };

      const res = await axios.post("api/routes/messages/response", body);
      if (res.data) {
        await updateMessageResponses(body);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    e.stopPropagation();
    setText(e.target.value);
  }

  return (
    <div className="d-flex w-100 flex-column row-gap-2 justify-content-end align-items-end pb-4">
      <div className="d-flex column-gap-4 w-100 justify-content-between align-items-end">
        <div className="d-flex column-gap-3 w-100">
          <img
            src={message.user.nodeID}
            alt="user.."
            style={{ width: "50px", height: "50px" }}
          />
          <div>
            <div className="font-16-bold">{message.user.username}</div>
            <div className="message">{message.message}</div>
          </div>
        </div>
        {!input && !message?.responses?.length && (
          <div className="respond" onClick={() => setInput(!input)}>
            respond
          </div>
        )}
      </div>

      {input && !message.responses?.length && (
        <div className="d-flex flex-column w-75 align-items-end row-gap-2">
          {input && (
            <div className="cursor-pointer" onClick={() => setInput(!input)}>
              <Exit
                style={{ width: "20px", height: "20px" }}
                className="mt-3"
              />
            </div>
          )}
          <textarea
            name="reply"
            id="reply"
            className="w-100 font-12 p-2"
            style={{ height: "100px" }}
            onChange={handleChange}
            disabled={loading}
          ></textarea>
          {loading ? (
            <div className="respond-loading w-25">respond</div>
          ) : (
            <div className="respond w-25" onClick={reply}>
              respond
            </div>
          )}
        </div>
      )}

      {message.responses?.length > 0 && (
        <Responses
          responses={message.responses}
          loading={loading}
          feed={feed}
          updateMessageResponses={updateMessageResponses}
          message={message}
        />
      )}
      <hr style={{ width: "90%" }} />
    </div>
  );
};

export default MessageItem;
