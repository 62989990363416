import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Image, Container } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import arrowDown from "../../images/svg/arrow-down.svg";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import ChooseGameUsers from "./components/ChooseGameUsers";
import CreateGame from "./components/CreateGame";
import "./game.css";

const GameComponent = ({
  isLoading,
  onCheckedAll,
  gamesCreated,
  users,
  stories,
  activeStory,
  storySelected,
  filters,
  onValueChecked,
  queryType,
  onQueryTypeChange,
  onStorySelect,
  createNewGame,
  storyError,
  setGameTime,
  tokens,
  onTokenSelect,
}) => {
  const [createGame, setCreateGame] = useState(false);

  return (
    <>
      {isLoading && (
        <div className="bouncer-loading">
          <BounceLoader size={25} color={"#000"} loading={isLoading} />
        </div>
      )}
      <Container className="min-height" fluid>
        <Row>
          <Header />
        </Row>
        <Row className={`${isLoading ? "opacity-5" : ""}`}>
          <Col xs={2} className="no-side-paddings sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col
            xs={10}
            className="no-side-paddings background-light-grey page-content-wrapper"
          >
            <ChooseGameUsers
              onCheckedAll={onCheckedAll}
              createGame={createGame}
              gamesCreated={gamesCreated}
              filters={filters}
              onValueChecked={onValueChecked}
              queryType={queryType}
              onQueryTypeChange={onQueryTypeChange}
            />
            <div className="d-flex flex-row justify-content-center cursor-pointer">
              <p
                onClick={() => setCreateGame(!createGame)}
                className="ps-3 pe-3 font-18-bold yellow-wrapper"
              >
                CREATE PLAY SHEET{" "}
                <img
                  alt="arrow-down"
                  className={`${createGame ? "rotate-arrow" : "normal-arrow"}`}
                  src={arrowDown}
                />
              </p>
            </div>
            {!createGame && (
              <div className="w-100 d-flex flex-wrap pl-3 pr-3 pt-4">
                <p></p>
                {users.map((u, i) => (
                  <Image
                    key={i}
                    className={"mt-3 mb-3 ms-3 me-3 avatar"}
                    src={u.nodeID}
                    roundedCircle
                    title={u.username}
                  />
                ))}
              </div>
            )}
            {createGame && (
              <CreateGame
                gamesCreated={gamesCreated}
                activeStory={activeStory}
                storySelected={storySelected}
                stories={stories}
                tokens={tokens}
                createNewGame={createNewGame}
                onStorySelect={onStorySelect}
                onTokenSelect={onTokenSelect}
                storyError={storyError}
                isLoading={isLoading}
                setGameTime={setGameTime}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

GameComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  stories: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  queryType: PropTypes.bool.isRequired,
  onValueChecked: PropTypes.func.isRequired,
  onQueryTypeChange: PropTypes.func.isRequired,
  createNewGame: PropTypes.func.isRequired,
};

export default GameComponent;
