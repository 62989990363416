import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { Row, Col, Form, Button, Container } from "react-bootstrap";

import "./stories.scss";
import { PickerDropPane } from "filestack-react";
import {Story} from "interface";

const { Config } = require("../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV as any]["picturePrefix"];
const fileStackPrefix =
  Config[process.env.REACT_APP_ENV as any]["fileStackPrefix"];

const fileStackApiKey =
  Config[process.env.REACT_APP_ENV as any]["filestackApiKey"];

interface IProps {
  isLoading?: boolean;
  stories: Story[];

  createStory: (values: Story) => void;
  updateStory: (portalId: number, values: Story) => void;
}

interface IUpdate {
  banner: boolean;
  icon: boolean;
  desktopVideo: boolean;
  mobileVideo: boolean;
}

const StoriesComponent = ({ createStory, updateStory, stories }: IProps) => {
  const [active, setActive] = useState<any>(undefined);
  const [update, setUpdate] = useState<IUpdate>({
    banner: false,
    icon: false,
    desktopVideo: false,
    mobileVideo: false,
  });

  const formik = useFormik({
    initialValues: {
      name: active?.name || "",
      description: active?.description || "",
      storyType: active?.storyType || "",
      status: active?.status || "",
      icon: active?.icon || "",
      banner: active?.banner || "",
      desktopVideo: active?.desktopVideo || "",
      mobileVideo: active?.mobileVideo || "",
      makers: {
        cast: active?.makers?.cast || "",
        director: active?.makers?.director || "",
        writer: active?.makers?.writer || "",
        vibe: active?.makers?.vibe || "",
        producer: active?.makers?.producer || "",
        executive_producer: active?.makers?.executive_producer || "",
      },
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let request: any = {
        name: values.name,
        description: values.description,
        status: values.status,
        makers: values.makers,
        desktopVideo: values?.desktopVideo,
        mobileVideo: values?.mobileVideo,
      };
      if (typeof values?.icon === "string") {
        request.icon = values?.icon;
      }
      if (typeof values.banner === "string") {
        request.banner = values.banner;
      }
      if (active) {
        await updateStory(active._id, request);
      } else {
        request.number =
          stories.length > 0
            ? (stories[stories.length - 1]?.number || 0) + 1
            : 0;
        await createStory(request);
      }
      formik.resetForm();
    },
  });

  // const initUpload = async () => {
  //   if (document.getElementById(`uppyDiv`) !== null) {
  //     const iconUppy = startUppy(
  //       `.UppyIconModalOpenerBtn`,
  //       `.DashboardContainer`,
  //     );
  //     iconUppy.on("complete", async (result: any) => {
  //       if (result && result.successful) {
  //         const base64 = await blobToBase64(result.successful[0]?.data);
  //         await formik.setFieldValue("icon", base64);
  //         iconUppy.getPlugin("Dashboard").closeModal();
  //       }
  //     });
  //
  //     const bannerUppy = startUppy(
  //       `.UppyBannerModalOpenerBtn`,
  //       `.DashboardContainer`,
  //     );
  //     bannerUppy.on("complete", async (result: any) => {
  //       if (result && result.successful) {
  //         const base64 = await blobToBase64(result?.successful[0]?.data);
  //         await formik.setFieldValue("banner", base64);
  //         bannerUppy.getPlugin("Dashboard").closeModal();
  //       }
  //     });
  //   }
  // };
  // useEffect(() => {
  //   void initUpload();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (active) {
      setActive(stories.find((story) => story?._id === active?._id));
    }
  }, [active, stories]);

  const { iconImage, bannerImage } = useMemo(() => {
    let iconImage;
    let bannerImage;

    if (
      formik.values?.icon?.url &&
      !formik.values?.icon?.url.includes(fileStackPrefix)
    ) {
      iconImage = `${picturePrefix}/${formik.values?.icon?.url}.png`;
    } else if (formik.values.icon?.url) {
      iconImage = formik.values.icon?.url;
    } else if (formik.values?.icon) {
      iconImage = formik.values?.icon;
    }
    if (
      formik.values?.banner?.url &&
      !formik.values?.banner?.url.includes(fileStackPrefix)
    ) {
      bannerImage = `${picturePrefix}/${formik.values?.banner?.url}.png`;
    } else if (formik.values.banner?.url) {
      bannerImage = formik.values.banner?.url;
    } else if (formik.values?.banner) {
      bannerImage = formik.values?.banner;
    }

    return { iconImage, bannerImage };
  }, [formik.values.banner, formik.values.icon]);

  return (
    <Container>
      <Row className="h-100">
        <Col className="border-right-black" sm={3}>
          <div className="mt-4">
            {stories.map((story) => (
              <div
                key={story._id}
                className="d-flex mb-4 align-items-center cursor-pointer"
                onClick={() => setActive(story)}
              >
                <img
                  className="me-2"
                  alt={story.name}
                  src={
                    story.icon?.url.includes(fileStackPrefix)
                      ? story.icon?.url
                      : `${picturePrefix}/${story.icon?.url}.png`
                  }
                  // src={`${picturePrefix}/${story.icon?.url}.png`}
                  height={80}
                />
                <div>{story.name}</div>
              </div>
            ))}
            <Button
              className="mt-4 w-50 orange-button"
              onClick={() => setActive(undefined)}
            >
              Add new
            </Button>
          </div>
        </Col>
        <Col sm={9}>
          <Form className="w-100 mt-4" onSubmit={formik.handleSubmit}>
            <div className="w-50 mb-4">
              <Form.Label className="font-18-bold">BANNER</Form.Label>
              <br />
              {!update.banner && (active?.banner || formik.values.banner) ? (
                <div className="d-flex flex-column row-gap-1">
                  <img src={bannerImage} alt="" className="uploaded-image" />
                  <span
                    className="orange-text  cursor-pointer"
                    onClick={() => setUpdate({ ...update, banner: true })}
                  >
                    delete
                  </span>
                </div>
              ) : (
                <div className="d-flex flex-column row-gap-2">
                  <PickerDropPane
                    apikey={fileStackApiKey}
                    onError={(error) => {
                      console.log("error", error);
                    }}
                    onUploadDone={(res: any) => {
                      if (res?.filesUploaded.length > 0) {
                        formik.setFieldValue(
                          "banner",
                          `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                        );
                        setUpdate({ ...update, banner: false });
                      }
                    }}
                    pickerOptions={{
                      accept: "image/*",
                      storeTo: {
                        container: "ic-filestack",
                        region: "us-west-1",
                      },
                    }}
                  />
                  {(active?.banner || formik.values.banner) && (
                    <span
                      className="orange-text  cursor-pointer"
                      onClick={() => setUpdate({ ...update, banner: false })}
                    >
                      cancel
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="w-50 mb-4">
              <Form.Label className="font-18-bold">ICON</Form.Label>
              <br />
              {!update.icon && (active?.icon || formik.values.icon) ? (
                <div className="d-flex flex-column row-gap-1">
                  <img src={iconImage} alt="" className="uploaded-image" />
                  <span
                    className="orange-text  cursor-pointer"
                    onClick={() => setUpdate({ ...update, icon: true })}
                  >
                    delete
                  </span>
                </div>
              ) : (
                <div className="d-flex flex-column row-gap-2">
                  <PickerDropPane
                    apikey={fileStackApiKey}
                    onError={(error) => {
                      console.log("error", error);
                    }}
                    onUploadDone={(res: any) => {
                      if (res?.filesUploaded.length > 0) {
                        formik.setFieldValue(
                          "icon",
                          `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                        );
                        setUpdate({ ...update, icon: false });
                      }
                    }}
                    pickerOptions={{
                      accept: "image/*",
                      storeTo: {
                        container: "ic-filestack",
                        region: "us-west-1",
                      },
                    }}
                  />
                  {(active?.icon || formik.values.icon) && (
                    <span
                      className="orange-text  cursor-pointer"
                      onClick={() => setUpdate({ ...update, icon: false })}
                    >
                      cancel
                    </span>
                  )}
                </div>
              )}
            </div>
            <Form.Label className="font-18-bold">VIDEO</Form.Label>
            <br />
            <div className="d-flex w-100 column-gap-3 justify-content-between mb-3">
              {!update.desktopVideo &&
              (active?.desktopVideo || formik.values.desktopVideo) ? (
                <div className="w-50">
                  <p className="font-16-bold">DESKTOP</p>
                  <video
                    controls
                    src={formik.values.desktopVideo || active?.desktopVideo}
                    width={"100%"}
                    height={"115px"}
                  />
                  <span
                    className="orange-text cursor-pointer"
                    onClick={() => setUpdate({ ...update, desktopVideo: true })}
                  >
                    delete
                  </span>
                </div>
              ) : (
                <div className="w-50">
                  <p className="font-16-bold">DESKTOP</p>
                  <PickerDropPane
                    apikey={fileStackApiKey}
                    onError={(error) => {
                      console.log("error", error);
                    }}
                    onUploadDone={(res: any) => {
                      if (res?.filesUploaded.length > 0) {
                        formik.setFieldValue(
                          "desktopVideo",
                          `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                        );
                        setUpdate({ ...update, desktopVideo: false });
                        // setDesktopVideoUploaded(true);
                      }
                    }}
                    pickerOptions={{
                      accept: "video/*",
                      storeTo: {
                        container: "ic-filestack",
                        region: "us-west-1",
                      },
                    }}
                  />
                  {(active?.desktopVideo || formik.values.desktopVideo) && (
                    <span
                      className="orange-text  cursor-pointer"
                      onClick={() =>
                        setUpdate({ ...update, desktopVideo: false })
                      }
                    >
                      cancel
                    </span>
                  )}
                </div>
              )}
              {!update.mobileVideo &&
              (active?.mobileVideo || formik.values.mobileVideo) ? (
                <div className="w-50">
                  <p className="font-16-bold">MOBILE</p>
                  <video
                    controls
                    src={formik.values.mobileVideo || active?.mobileVideo}
                    width={"100%"}
                    height={"115px"}
                  />
                  <span
                    className="orange-text cursor-pointer"
                    onClick={() => setUpdate({ ...update, mobileVideo: true })}
                  >
                    delete
                  </span>
                </div>
              ) : (
                <div className="w-50">
                  <p className="font-16-bold">MOBILE</p>
                  <PickerDropPane
                    apikey={fileStackApiKey}
                    onError={(error) => {
                      console.log("error", error);
                    }}
                    onUploadDone={(res: any) => {
                      if (res?.filesUploaded.length > 0) {
                        formik.setFieldValue(
                          "mobileVideo",
                          `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                        );
                        setUpdate({ ...update, mobileVideo: false });
                      }
                    }}
                    pickerOptions={{
                      accept: "video/*",
                      storeTo: {
                        container: "ic-filestack",
                        region: "us-west-1",
                      },
                    }}
                  />
                  {(active?.mobileVideo || formik.values.mobileVideo) && (
                    <span
                      className="orange-text  cursor-pointer"
                      onClick={() =>
                        setUpdate({ ...update, mobileVideo: false })
                      }
                    >
                      cancel
                    </span>
                  )}
                </div>
              )}
            </div>
            <br />
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY NAME</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">
                STORY DESCRIPTION
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                name="description"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY STATUS</Form.Label>
              <Form.Control
                type="text"
                name="status"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.status}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY CAST</Form.Label>
              <Form.Control
                type="text"
                name="makers.cast"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.cast}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY DIRECTOR</Form.Label>
              <Form.Control
                type="text"
                name="makers.director"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.director}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY WRITER</Form.Label>
              <Form.Control
                type="text"
                name="makers.writer"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.writer}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY VIBE</Form.Label>
              <Form.Control
                type="text"
                name="makers.vibe"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.vibe}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY PRODUCER</Form.Label>
              <Form.Control
                type="text"
                name="makers.producer"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.producer}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">
                STORY EXECUTIVE PRODUCER
              </Form.Label>
              <Form.Control
                type="text"
                name="makers.executive_producer"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.makers.executive_producer}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="font-18-bold">STORY TYPE</Form.Label>
              <Form.Control
                type="text"
                name="storyType"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.storyType}
              />
            </Form.Group>
            <Button className="mt-4 w-100 orange-button" type="submit">
              SAVE
            </Button>
          </Form>
        </Col>
      </Row>
      <div id="uppyDiv" className="DashboardContainer" />
    </Container>
  );
};

export default StoriesComponent;
