import React from "react";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PortalContainer from "./pages/Portals/PortalContainer";
import GameContainer from "./pages/Games/GameContainer";
import TokenContainer from "./pages/Token/TokenContainer";
import TokensContainer from "./pages/Tokens/TokensContainer";
import StoriesContainer from "./pages/Stories/StoriesContainer";
import BrandContainer from "./pages/Brand/BrandContainer";
// import ProductContainer from "./pages/Product/ProductContainer";
import VerifyUsersContainer from "./pages/VerifyUsers/VerifyUsersContainer";
import ImagineContainer from "./pages/Imagine/ImagineContainer";
import FeedContainer from "./pages/Feed/FeedContainer";
import TransactionsContainer from "./pages/Transactions/TransactionsContainer";
// import Landing from "pages/Landing";
import { AppProvider } from "contexts/AppContext";
import { LandingNew } from "pages/LandingNew";

function App({ store }) {
  const NotFoundRedirect = () => <Redirect to="/" />;

  return (
    <Provider store={store}>
      <AuthProvider>
        <AppProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={LandingNew} />
              <Route exact path="/games" component={GameContainer} />
              <Route exact path="/token" component={TokenContainer} />
              <Route exact path="/tokens" component={TokensContainer} />
              <Route exact path="/portal" component={PortalContainer} />
              <Route exact path="/story" component={StoriesContainer} />
              {/* <Route exact path="/product" component={ProductContainer} /> */}
              <Route exact path="/feed" component={FeedContainer} />
              <Route exacr path="/brand" component={BrandContainer} />
              <Route exact path="/imagine" component={ImagineContainer} />
              <Route exact path="/verify" component={VerifyUsersContainer} />
              <Route
                exact
                path="/transactions"
                component={TransactionsContainer}
              />
              <Route component={NotFoundRedirect} />
            </Switch>
          </BrowserRouter>
        </AppProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
