import React from "react";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";

import { Button, InputField } from "../../UI";
import { useAuth } from "../../../../../contexts/AuthContext";
import { loginFormSchema } from "./login-form.schema";

import styles from "./LoginForm.module.scss";

const isEmail = (email: string) => {
  let myRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return myRegexp.exec(email);
};

interface LoginFormProps {
  className?: string;
}

export function LoginForm({ className }: LoginFormProps) {
  const classes = classNames(styles.form, className);
  const { loginUser } = useAuth();

  const login = async (values: LoginFormValues) => {
    if (isEmail(values.username)) {
      values.email = values.username.toLowerCase();
    } else if (/\s+$/.test(values.username)) {
      values.username = values.username.replace(/\s+/g, "");
    }
    loginUser(values);
  };

  function onLogin() {
    console.log("login");
  }

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          email: "",
          password: "",
          username: "",
        }}
        validationSchema={loginFormSchema}
        onSubmit={login}
      >
        {({ values, errors }) => (
          <Form className={classes}>
            <Field
              className={styles.field}
              component={InputField}
              name="username"
              type="text"
              placeholder="Portal Code"
            />
            {/* <ErrorMessage
              name="username"
              render={(msg) => <div className="warning">{msg}</div>}
            /> */}
            <Field
              className={styles.field}
              component={InputField}
              name="password"
              placeholder="Password"
              type="password"
            />
            {/* <ErrorMessage
              name="password"
              render={(msg) => <div className="warning">{msg}</div>}
            /> */}
            <Button className={styles.submit} type="submit" onClick={onLogin}>
              Log in
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
