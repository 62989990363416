import { handleActions } from "redux-actions";
import * as authActions from "../actions/auth";

const userStorage = JSON.parse(localStorage.getItem("ppw-user")) || {
  user: {
    data: {},
    isLogged: false,
  },
};
const localStorageUser = JSON.parse(localStorage.getItem("ppw-user"));

const defaultState = localStorageUser
  ? localStorageUser
  : {
      user: {
        data: {...userStorage.user.data},
        isLogged: userStorage.user.isLogged,
      },
    };

export default handleActions(
  {
    [authActions.loginUser]: (state = defaultState, action) => {
      localStorage.setItem(
        "ppw-user",
        JSON.stringify({
          user: {
            data: action.payload.user,
            isLogged: true,
          },
        })
      );

      return {
        ...state,
        user: {
          data: { ...action.payload.user },
          isLogged: true,
        },
      };
    },
    [authActions.logOutUser]: (state = defaultState, action) => {
      localStorage.removeItem("ppw-user");
      return {
        ...state,
        user: {
          data: {},
          isLogged: false,
        },
      };
    },
  },
  defaultState
);
