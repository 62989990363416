import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ImagineComponent from "./ImagineComponent";
import axios from "../../services/axios";
import toast from "../../components/Notistack";

const ImagineContainer = ({ brand }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState({});
  const [products, setProducts] = useState([]);
  const [game, setGame] = useState([]);
  const [gameSaves, setGameSaves] = useState([]);
  const [topObjects, setTopObjects] = useState([]);
  const history = useHistory();

  const getGames = async () => {
    try {
      let response = await axios.get(
        `api/routes/games/getGamesForPortal?portal=${brand.data.portal}`,
      );
      if (response.data?.message === "Success") {
        setToken(response.data.token);
        setProducts(response.data.products);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getGame = async (prod, i) => {
    let gameId = prod.gameStatus[i].id;
    if (!gameId) {
      toast.warning("That game is not yet created");
    } else {
      setIsLoading(true);
      try {
        let response = await axios.get(
          `api/routes/games/getGameDetailsForPortal?gameId=${gameId}`,
        );
        if (response.data?.message === "Success") {
          setGame(response.data.game);
          setGameSaves(response.data.gameSaves);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    }
  };

  const selectTopObject = (object) => {
    let oldO = [...topObjects];
    let index = oldO.findIndex((o) => o === object?._id);
    if (index === -1 && object?._id) {
      oldO.push(object._id);
      setTopObjects(oldO);
    } else {
      oldO.splice(index, 1);
      setTopObjects(oldO);
    }
  };

  const createTopObjects = async () => {};

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/");
    } else {
      getGames();
    }
  }, []);

  return (
    <ImagineComponent
      topObjects={topObjects}
      game={game}
      gameSaves={gameSaves}
      isLoading={isLoading}
      token={token}
      products={products}
      getGame={getGame}
      selectTopObject={selectTopObject}
    />
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.user,
});

export default connect(mapStateToProps, null)(ImagineContainer);
