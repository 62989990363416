import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import axios from "../../services/axios";
import toast from "../../components/Notistack"

import Layout from 'components/Layout';

import TransactionsComponent from './TransactionsComponent';

const StoriesContainer = ({ brand }: any) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isMinting, setIsMinting] = useState<string | null>(null);
  const [transactions, setTransactions] = useState([]);

  const loadTransactions = useCallback(async () => {
    try {
      let response = await axios.get(`api/routes/transactions/blockchain`)
      if (response.status === 200) {
        setTransactions(response.data.data)
      }
      setIsLoading(false)
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message)
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/")
    } else {
      loadTransactions();
    }
  }, [brand.isLogged, history, loadTransactions]);

  const mintTokenForUser = useCallback(async (id: string) => {
    try {
      setIsMinting(id);
      let response = await axios.post(`api/routes/transactions/mint/${id}`)
      if (response.status === 200) {
        toast.success('Token minted successfully');
        loadTransactions();
        setIsMinting(null);
      }
    } catch (error:any) {
      setIsMinting(null)
      toast.error(error?.response?.data?.message || error?.message);
    }
  }, [loadTransactions]);

  return (
    <Layout>
      <TransactionsComponent
        isLoading={isLoading}
        isMinting={isMinting}
        transactions={transactions}
        mintTokenForUser={mintTokenForUser}
      />
    </Layout>
  );
}

export default connect((state: any) => ({
  brand: state.auth.user,
}))(StoriesContainer);
