import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import dayjs from "dayjs";

import arrow from "../../../images/svg/arrow-down.svg";
import SelectTimeType from "./SelectTimeType";

const ChooseGameTime = ({ setGameTime }) => {
  const [first, setFirst] = useState(null);

  const [startGameDate, setStartGameDate] = useState("");
  const [playGameDate, setPlayGameDate] = useState("");
  const [voteGameDate, setVoteGameDate] = useState("");

  const [start, setStart] = useState();
  const [play, setPlay] = useState();
  const [vote, setVote] = useState();

  const [startTime, setStartTime] = useState({ time: 5, type: "MIN" });
  const [playTime, setPlayTime] = useState({ time: 5, type: "MIN" });
  const [voteTime, setVoteTime] = useState({ time: 5, type: "MIN" });

  useEffect(() => {
    const time = {
      start: startGameDate,
      play: playGameDate,
      vote: voteGameDate,
    };
    setGameTime(time);
  }, [startGameDate, playGameDate, voteGameDate]);

  useEffect(() => {
    const currentTime = Date.now();
    const time = {
      start: currentTime + formatTime(startTime.time, startTime.type),
      play:
        currentTime + formatTime(startTime.time + playTime.time, playTime.type),
      vote:
        currentTime + formatTime(playTime.time + voteTime.time, voteTime.type),
    };
    setStart(time.start);
    setPlay(time.play);
    setVote(time.vote);
    setFirst(true);
  }, []);

  useEffect(() => {
    if (start) void firstRun();
  }, [first]);

  const firstRun = async () => {
    handleStartTimeTypeChange({
      selectedValue: "MIN",
      value: 5,
    });
    handlePlayTimeTypeChange({
      selectedValue: "MIN",
      value: 5,
    });
    handleVoteTimeTypeChange({
      selectedValue: "MIN",
      value: 5,
    });
  };

  const formatTime = (value, type) => {
    let milliseconds;
    let minutes;
    if (type === "MIN") {
      minutes = value + 1;
    }
    switch (type) {
      case "MIN":
        milliseconds = minutes * 60 * 1000;
        break;
      case "HRS":
        milliseconds = value * 60 * 60 * 1000;
        break;
      case "DAYS":
        milliseconds = value * 24 * 60 * 60 * 1000;
        break;
      case "MONTHS":
        milliseconds = value * 30 * 24 * 60 * 60 * 1000;
        break;
      default:
        milliseconds = value * 60 * 1000;
    }
    return milliseconds;
  };

  const handleStartTimeTypeChange = ({ selectedValue, value }) => {
    const currentTime = Date.now();
    let milliseconds;
    if (selectedValue === "MIN") {
      setStartTime({ time: value, type: selectedValue });
      milliseconds = currentTime + formatTime(value, selectedValue);
    } else {
      setStartTime({ time: value, type: selectedValue });
      milliseconds = currentTime + formatTime(value, selectedValue);
    }
    const startMilliseconds = milliseconds + startTime.time;
    const playMilliseconds = formatTime(playTime.time, playTime.type);
    const voteMilliseconds = formatTime(voteTime.time, voteTime.type);

    setStart(startMilliseconds);
    setPlay(startTime.time + milliseconds + playMilliseconds);
    setVote(
      startTime.time + milliseconds + playMilliseconds + voteMilliseconds,
    );

    const newStartTime = new Date(startMilliseconds).toISOString();

    const newPlayTime = new Date(
      startTime.time + milliseconds + playMilliseconds,
    ).toISOString();

    const newVoteTime = new Date(
      startTime.time + milliseconds + playMilliseconds + voteMilliseconds,
    ).toISOString();

    setStartGameDate(newStartTime);
    setPlayGameDate(newPlayTime);
    setVoteGameDate(newVoteTime);
  };

  const handlePlayTimeTypeChange = ({ selectedValue, value }) => {
    let milliseconds;
    if (selectedValue === "MIN") {
      setPlayTime({ time: value, type: selectedValue });
      milliseconds = start + formatTime(value, selectedValue);
    } else {
      setPlayTime({ time: value, type: selectedValue });
      milliseconds = start + formatTime(value, selectedValue);
    }
    const playMilliseconds = formatTime(playTime.time, playTime.type);
    const voteMilliseconds = formatTime(voteTime.time, voteTime.type);

    setPlay(startTime.time + milliseconds + playMilliseconds);
    setVote(
      startTime.time + milliseconds + playMilliseconds + voteMilliseconds,
    );

    const newPlayTime = new Date(startTime.time + milliseconds).toISOString();

    const newVoteTime = new Date(
      startTime.time + milliseconds + voteMilliseconds,
    ).toISOString();

    setPlayGameDate(newPlayTime);
    setVoteGameDate(newVoteTime);
  };

  const handleVoteTimeTypeChange = ({ selectedValue, value }) => {
    let milliseconds;
    if (selectedValue === "MIN") {
      setVoteTime({ time: value, type: selectedValue });
      milliseconds = formatTime(value, selectedValue);
    } else {
      setVoteTime({ time: value, type: selectedValue });
      milliseconds = formatTime(value, selectedValue);
    }

    const isoString = new Date(play).toISOString();
    const millisecondsToAdd = milliseconds;

    const newVoteTime = dayjs(isoString)
      .add(millisecondsToAdd, "millisecond")
      .toISOString();

    setVoteGameDate(newVoteTime);
  };

  const updateStartTime = ({ type }) => {
    if (type === "plus") {
      if (
        startTime.time >= 5 &&
        startTime.time < 60 &&
        startTime.type === "MIN"
      ) {
        handleStartTimeTypeChange({
          selectedValue: startTime.type,
          value: startTime.time + 5,
        });
        setStartTime({ ...startTime, time: startTime.time + 5 });
      } else if (
        startTime.time >= 1 &&
        startTime.time < 60 &&
        startTime.type !== "MIN"
      ) {
        handleStartTimeTypeChange({
          selectedValue: startTime.type,
          value: startTime.time + 1,
        });
        setStartTime({ ...startTime, time: startTime.time + 1 });
      }
    } else if (type === "minus") {
      if (
        startTime.time > 5 &&
        startTime.time <= 60 &&
        startTime.type === "MIN"
      ) {
        handleStartTimeTypeChange({
          selectedValue: startTime.type,
          value: startTime.time - 5,
        });
        setStartTime({ ...startTime, time: startTime.time - 5 });
      } else if (
        startTime.time > 1 &&
        startTime.time <= 60 &&
        startTime.type !== "MIN"
      ) {
        handleStartTimeTypeChange({
          selectedValue: startTime.type,
          value: startTime.time - 1,
        });
        setStartTime({ ...startTime, time: startTime.time - 1 });
      }
    }
  };

  const updatePlayTime = ({ type }) => {
    if (type === "plus") {
      if (playTime.time >= 5 && playTime.time < 60 && playTime.type === "MIN") {
        handlePlayTimeTypeChange({
          selectedValue: playTime.type,
          value: playTime.time + 5,
        });
        setPlayTime({ ...playTime, time: playTime.time + 5 });
      } else if (
        playTime.time >= 1 &&
        playTime.time < 60 &&
        playTime.type !== "MIN"
      ) {
        handlePlayTimeTypeChange({
          selectedValue: playTime.type,
          value: playTime.time + 1,
        });
        setPlayTime({ ...playTime, time: playTime.time + 1 });
      }
    } else if (type === "minus") {
      if (playTime.time > 5 && playTime.time <= 60 && playTime.type === "MIN") {
        handlePlayTimeTypeChange({
          selectedValue: playTime.type,
          value: playTime.time - 5,
        });
        setPlayTime({ ...playTime, time: playTime.time - 5 });
      } else if (
        playTime.time > 1 &&
        playTime.time <= 60 &&
        playTime.type !== "MIN"
      ) {
        handlePlayTimeTypeChange({
          selectedValue: playTime.type,
          value: playTime.time - 1,
        });
        setPlayTime({ ...playTime, time: playTime.time - 1 });
      }
    }
  };

  const updateVoteTime = ({ type }) => {
    if (type === "plus") {
      if (voteTime.time >= 5 && voteTime.time < 60 && voteTime.type === "MIN") {
        handleVoteTimeTypeChange({
          selectedValue: voteTime.type,
          value: voteTime.time + 5,
        });
        setVoteTime({ ...voteTime, time: voteTime.time + 5 });
      } else if (
        voteTime.time >= 1 &&
        voteTime.time < 60 &&
        voteTime.type !== "MIN"
      ) {
        handleVoteTimeTypeChange({
          selectedValue: voteTime.type,
          value: voteTime.time + 1,
        });
        setVoteTime({ ...voteTime, time: voteTime.time + 1 });
      }
    } else if (type === "minus") {
      if (voteTime.time > 5 && voteTime.time <= 60 && voteTime.type === "MIN") {
        handleVoteTimeTypeChange({
          selectedValue: voteTime.type,
          value: voteTime.time - 5,
        });
        setVoteTime({ ...voteTime, time: voteTime.time - 5 });
      } else if (
        voteTime.time > 1 &&
        voteTime.time <= 60 &&
        voteTime.type !== "MIN"
      ) {
        handleVoteTimeTypeChange({
          selectedValue: voteTime.type,
          value: voteTime.time - 1,
        });
        setVoteTime({ ...voteTime, time: voteTime.time - 1 });
      }
    }
  };

  return (
    <div className="d-flex flex-column w-100 p-4 row-gap-3">
      <div className="d-flex flex-row column-gap-4 align-items-center">
        <div className="font-16-bold fixed-width-100">Start Time</div>
        <div className="d-flex flex-row p-2 column-gap-3 align-items-center">
          <Image
            src={arrow}
            className="rotate-arrow cursor-pointer"
            onClick={() => updateStartTime({ type: "plus" })}
          />
          <div className="p-2 fixed-width-100 border border-2 rounded d-flex justify-content-center align-items-center">
            {startTime.time === 60 ? (
              <div>
                1 <span className="font-12">HR</span>
              </div>
            ) : (
              <div>
                {startTime.time}{" "}
                <span className="font-12">{startTime.type}</span>
              </div>
            )}
          </div>
          <Image
            src={arrow}
            className="cursor-pointer"
            onClick={() => updateStartTime({ type: "minus" })}
          />

          <SelectTimeType setTimeType={handleStartTimeTypeChange} />
        </div>
      </div>
      <div className="d-flex flex-row column-gap-4 align-items-center">
        <div className="font-16-bold fixed-width-100">Play Time</div>
        <div className="d-flex flex-row p-2 column-gap-3 align-items-center">
          <Image
            src={arrow}
            className="rotate-arrow cursor-pointer"
            onClick={() => updatePlayTime({ type: "plus" })}
          />
          <div className="p-2 fixed-width-100 border border-2 rounded d-flex justify-content-center align-items-center">
            {playTime.time === 60 ? (
              <div>
                1 <span className="font-12">HR</span>
              </div>
            ) : (
              <div>
                {playTime.time} <span className="font-12">{playTime.type}</span>
              </div>
            )}
          </div>
          <Image
            src={arrow}
            className="cursor-pointer"
            onClick={() => updatePlayTime({ type: "minus" })}
          />

          <SelectTimeType setTimeType={handlePlayTimeTypeChange} />
        </div>
      </div>
      <div className="d-flex flex-row column-gap-4 align-items-center">
        <div className="font-16-bold fixed-width-100">Vote Time</div>
        <div className="d-flex flex-row p-2 column-gap-3 align-items-center">
          <Image
            src={arrow}
            className="rotate-arrow cursor-pointer"
            onClick={() => updateVoteTime({ type: "plus" })}
          />
          <div className="p-2 fixed-width-100 border border-2 rounded d-flex justify-content-center align-items-center">
            {voteTime.time === 60 ? (
              <div>
                1 <span className="font-12">HR</span>
              </div>
            ) : (
              <div>
                {voteTime.time} <span className="font-12">{voteTime.type}</span>
              </div>
            )}
          </div>
          <Image
            src={arrow}
            className="cursor-pointer"
            onClick={() => updateVoteTime({ type: "minus" })}
          />
          <SelectTimeType setTimeType={handleVoteTimeTypeChange} />
        </div>
      </div>
    </div>
  );
};

export default ChooseGameTime;
