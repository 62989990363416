import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import GameComponent from "./GameComponent";
import axios from "../../services/axios";
import toast from "../../components/Notistack";

const GameContainer = ({ brand }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryType, setQueryType] = useState(true);
  const [gamesCreated, setGamesCreated] = useState(0);

  // const [products, setProducts] = useState([]);
  // const [numberOfGamesToCreate, setNumberOfGamesToCreate] = useState(0);
  // const [numberOfGamesCreated, setNumberOfGamesCreated] = useState(0);
  // const [productSelected, setProductSelected] = useState("");
  // const [activeProduct, setActiveProduct] = useState({});
  const [filters, setFilters] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const [stories, setStories] = useState([]);
  const [storySelected, setStorySelected] = useState("");
  const [activeStory, setActiveStory] = useState({});
  const [storyError, setStoryError] = useState("");
  const [gameTime, setGameTime] = useState(null);

  const [tokens, setTokens] = useState([]);
  const [tokenSelected, setTokenSelected] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("api/routes/users/fields");
      void fetchStories();
      void fetchTokens();
      const newFilters = [];
      response.data?.fields?.forEach((element) => {
        const filter = {};
        filter.key = element.fieldName;
        filter.value = {};
        element.answers.forEach((answer) => {
          filter.value[answer] = false;
        });
        newFilters.push(filter);
      });
      setFilters(newFilters);
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const prepareFilters = (filtersList) => {
    const body = { filters: [] };
    filtersList.forEach((filter) => {
      const obj = {};
      obj.key = filter.key;
      obj.value = [];
      Object.keys(filter.value).forEach((key) => {
        if (filter.value[key]) obj.value.push(key);
      });
      if (obj.value.length > 0) body.filters.push(obj);
    });
    return body;
  };

  useEffect(() => {
    const fetchFilteredData = async () => {
      setIsLoading(true);
      try {
        let params = "?";
        if (queryType) params += "queryType=direct";
        else params += "queryType=indirect";

        const response = await axios.post(
          "api/routes/users/query" + params,
          prepareFilters(filters),
        );
        if (response.data.message === "Success") {
          setUsers(response.data.users?.docs);
        } else {
          toast.warning("did not fetch users under those criteria");
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    };
    fetchFilteredData();
  }, [filters, queryType]);

  const onValueChecked = useCallback((index, event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setFilters((prevFilters) => {
      prevFilters[index].value[name] = checked;
      return [...prevFilters];
    });
  }, []);

  const onCheckedAll = (index, e) => {
    setFilters((prevFilters) => {
      Object.keys(prevFilters[index].value)?.map(
        (pf) => (prevFilters[index].value[pf] = true),
      );
      return [...prevFilters];
    });
  };

  const onQueryTypeChange = () => {
    setQueryType(!queryType);
    setUsers([]);
  };

  const createNewGame = async (e, gameType) => {
    e.preventDefault();
    if (!storySelected) {
      setStoryError("CHOOSE STORY");
      return;
    }
    setStoryError("");
    setIsLoading(true);

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    const inputValue = {
      poolAmount: formDataObj.poolAmount,
      brandDetail: brand.data.brandDetail,
      productContext: formDataObj.context,
      brandEmail: brand.data.email,
      mission: formDataObj.mission,
      description: formDataObj.description,
      name: formDataObj.name,
      story_id: storySelected,
      tokenId: tokenSelected,
      // rules: formDataObj.rules,
      game_type: gameType,
      usersToShow: users,
      filters: [{ key: "tag", value: ["owner"] }], // prepareFilters(filters).filters,
      startTime: gameTime.start,
      playTime: gameTime["play"],
      voteTime: gameTime.vote,
    };

    if (queryType) inputValue["queryType"] = "direct";
    else inputValue["queryType"] = "indirect";
    try {
      let response = await axios.post(
        "api/routes/games/createGame",
        inputValue,
      );
      if (response.data.message === "Game created!") {
        setGamesCreated(gamesCreated + 1);
        setIsLoading(false);
        toast.success("Game created");
        if (e?.nativeEvent?.submitter?.name === "complete") {
          history.push("/imagine");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // const fetchProducts = async () => {
  //   try {
  //     let response = await axios.get(
  //       `api/routes/products/getProductsForGame?email=${encodeURIComponent(
  //         brand.data.email,
  //       )}`,
  //     );
  //     let counter = 0;
  //     let gamesCounter = 0;
  //     response.data.products.forEach((p) => {
  //       gamesCounter += p.gamesCreated;
  //       counter += p.numberOfGames;
  //     });
  //     setNumberOfGamesToCreate(counter);
  //     setNumberOfGamesCreated(gamesCounter);
  //     setProducts(response.data.products);
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error(error?.message);
  //   }
  // };

  const fetchStories = async () => {
    try {
      let response = await axios.get(`api/routes/stories/get`);
      setStories(response.data.data);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const fetchTokens = async () => {
    try {
      const response = await axios.get(
        `api/routes/games/getDrrtTokensForAttachToGame`,
      );
      setTokens(response?.data?.tokens);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const onStorySelect = (key) => {
    setStoryError("");
    let s = stories.filter((s) => s.id === key);
    setActiveStory(s[0]);
    setStorySelected(key);
  };

  // const onProductSelect = (key) => {
  //   let p = products.filter((p) => p.id === key);
  //   setActiveProduct(p[0]);
  //   setGamesCreated(p[0].gamesCreated);
  //   setProductSelected(key);
  // };

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/");
    }
    void fetchData();
  }, [brand]);

  return (
    <GameComponent
      isLoading={isLoading}
      filters={filters}
      stories={stories}
      users={users}
      tokens={tokens}
      gamesCreated={gamesCreated}
      activeStory={activeStory}
      storySelected={storySelected}
      onValueChecked={onValueChecked}
      onCheckedAll={onCheckedAll}
      onQueryTypeChange={onQueryTypeChange}
      queryType={queryType}
      createNewGame={createNewGame}
      onStorySelect={onStorySelect}
      onTokenSelect={setTokenSelected}
      storyError={storyError}
      setGameTime={setGameTime}
    />
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.user,
});

export default connect(mapStateToProps, null)(GameContainer);
