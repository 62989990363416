import React from "react";
import { BounceLoader } from "react-spinners";

import "./transactions.scss";
import {
  Button,
  Card,
  CardDeck,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";

interface IProps {
  isLoading: boolean;
  isMinting: string | null;
  transactions: any[];
  mintTokenForUser: (id: string) => void;
}

const TransactionsComponent = ({
  isLoading,
  transactions,
  mintTokenForUser,
  isMinting,
}: IProps) => {
  const getYesNo = (value: boolean) => (value ? "YES" : "NO");

  if (isLoading) {
    return (
      <div className="bouncer-loading">
        <BounceLoader size={25} color={"#000"} loading={isLoading} />
      </div>
    );
  }

  return (
    <div className="w-100 mt-5 mb-5 ms-5 me-5 d-flex flex-column">
      {transactions.map(
        (
          {
            user,
            transactionCreated,
            success,
            deliverAssets,
            token,
            _id,
            amount,
            quantity,
            to,
            tokenAddress,
          }: any,
          index,
        ) => (
          <CardDeck className="w-100 mb-3" key={index}>
            <Card className="ppw-card h-100">
              <div className="p-10 d-flex flex-column h-100">
                <Card.Body className="d-flex flex-row list-view-body align-items-center">
                  <div className="w-100 me-3 d-flex flex-column w-100">
                    <OverlayTrigger
                      overlay={<Tooltip id={_id}>{token?.name}</Tooltip>}
                    >
                      <Image
                        roundedCircle
                        className="token-image"
                        src={token?.image}
                        title={token?.name}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="w-100 me-3 d-flex flex-column w-100">
                    <OverlayTrigger
                      overlay={<Tooltip id={_id}>{user?.name}</Tooltip>}
                    >
                      <Image
                        roundedCircle
                        className="token-image"
                        src={user?.image}
                        title={user?.name}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="my-auto d-flex flex-column w-100">
                    <p className="font-18 opacity-5">QTY</p>
                    <p className="font-18-bold">{quantity || 1}</p>
                  </div>
                  <div className="my-auto d-flex flex-column w-100">
                    <p className="font-18 opacity-5">PRICE</p>
                    <p className="font-18-bold">${amount || 0}</p>
                  </div>
                  <div className="my-auto d-flex flex-column w-100">
                    <p className="font-18 opacity-5">DELIVERY</p>
                    <p className="font-18-bold">{getYesNo(deliverAssets)}</p>
                  </div>
                  <div className="my-auto d-flex flex-column w-100">
                    <p className="font-18 opacity-5">MINTED</p>
                    <p className="font-18-bold">
                      {success ? (
                        getYesNo(success)
                      ) : (
                        <Button
                          className="orange-button"
                          disabled={isMinting !== null}
                          onClick={() => mintTokenForUser(_id)}
                        >
                          {isMinting === _id ? "MINTING" : "MINT"}
                        </Button>
                      )}
                    </p>
                  </div>
                  <div className="my-auto d-flex flex-column w-100">
                    <p className="font-18 opacity-5">DATE</p>
                    <p className="font-18-bold">
                      {moment(transactionCreated).format("MMMM Do, YYYY")}
                    </p>
                  </div>
                </Card.Body>
              </div>
            </Card>
          </CardDeck>
        ),
      )}
    </div>
  );
};

export default TransactionsComponent;
