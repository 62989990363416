import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Form, Button, Image } from "react-bootstrap";
import { gameType } from "../../../constants/gameType";
import arrowLeft from "../../../images/svg/arrowLeft.svg";
import arrowRight from "../../../images/svg/arrowRight.svg";
import ChooseGameTime from "./ChooseGameTime";
import { useHistory } from "react-router-dom";
const { Config } = require("../../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV]["picturePrefix"];
const fileStackPrefix = Config[process.env.REACT_APP_ENV]["fileStackPrefix"];

const Arrow = ({ src, className }) => {
  return <Image src={src} className={`${className} cursor-pointer`} />;
};

const MenuItem = ({ image, selected }) => {
  let url;

  if (image?.url && image.url.includes(fileStackPrefix)) {
    url = image?.url;
  } else if (image?.url && !image.url.includes(fileStackPrefix)) {
    url = `${picturePrefix}/${image.url}.png`;
  } else {
    url = image;
  }

  return (
    <Image
      className={`game-product-image me-3 ${
        selected ? "game-active-product" : ""
      }`}
      src={url}
    />
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { _id, icon } = el;
    return <MenuItem image={icon} key={_id} selected={selected} />;
  });

const ArrowLeft = Arrow({ src: arrowLeft, class: "me-4" });
const ArrowRight = Arrow({ src: arrowRight, class: "ms-4" });

const CreateGame = ({
  createNewGame,
  isLoading,
  gamesCreated,
  activeStory,
  stories,
  onStorySelect,
  storySelected,
  storyError,
  setGameTime,
  tokens,
  onTokenSelect,
}) => {
  const formRef = useRef(null);
  const [typeOfPlay, setTypeOfPlay] = useState("");

  let menuItems = Menu(stories.slice(0, stories.length), storySelected);
  const history = useHistory();

  useEffect(() => {
    formRef.current.reset();
  }, [gamesCreated, activeStory?.numberOfGames]);

  const handleTokenChange = (event) => {
    onTokenSelect(event.target.value);
  };

  return (
    <>
      <Form ref={formRef} onSubmit={(e) => createNewGame(e, typeOfPlay)}>
        <div className="w-100 ms-3 me-3 d-flex flex-row mt-3 mb-3">
          {!storyError && <p className="font-18-bold my-auto ">CHOOSE STORY</p>}
          {storyError && (
            <p className="font-18-bold orange-text">CHOOSE STORY</p>
          )}
          <div className="w-75 justify-content-center d-flex ms-3 me-3">
            <ScrollMenu
              data={menuItems}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={storySelected}
              onSelect={onStorySelect}
            />
          </div>
        </div>

        <div className="w-100 d-flex flex-column pe-3 ps-3 pt-3 pb-3">
          <div className="w-100 d-flex flex-row column-gap-5">
            <div className="d-flex flex-column w-100">
              <Form.Group className="mb-3 mt-3">
                <Form.Label className="font-18-bold">NAME</Form.Label>
                <Form.Control type="text" name="name" placeholder="" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="font-18-bold">MISSION</Form.Label>
                <Form.Control
                  type="text"
                  name="mission"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="font-18-bold">DESCRIPTION</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="description"
                  placeholder=""
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="font-18-bold">CONTEXT</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  type="text"
                  name="context"
                  placeholder=""
                  required
                />
              </Form.Group>
            </div>
            <div className="ps-5 d-flex flex-column w-100">
              {/*// <Form.Group className="mb-3">*/}
              {/*//   <Form.Label className="font-18-bold">RULES</Form.Label>*/}
              {/*  <Form.Control*/}
              {/*    as="textarea"*/}
              {/*    rows={6}*/}
              {/*    type="text"*/}
              {/*    required*/}
              {/*    name="rules"*/}
              {/*    placeholder=""*/}
              {/*  />*/}
              {/*</Form.Group>*/}
              <Form.Group className="w-75 mb-4">
                <Form.Label className="font-18-bold d-flex">
                  <span className="d-flex w-100">
                    {" "}
                    CHOOSE TOKEN OR{" "}
                    <p
                      onClick={() => history.push("/token")}
                      className="orange-text cursor-pointer"
                    >
                      <span className="mx-1"> CREATE NEW ONE</span>
                    </p>
                  </span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="token"
                  onChange={handleTokenChange}
                >
                  <option value="">None</option>
                  {tokens.map((token) => (
                    <option key={token._id} value={token._id}>
                      {token.name} - {token.symbol}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div className="d-flex flex-row w-100 mb-5">
                <Form.Group className="mb-3">
                  <Form.Label className="font-18-bold">POOL AMOUNT</Form.Label>
                  <Form.Control
                    type="text"
                    name="poolAmount"
                    placeholder=""
                    required
                  />
                </Form.Group>
                <div className="d-flex flex-column ms-5">
                  <Form.Label className="font-18-bold">TYPE OF PLAY</Form.Label>
                  {gameType.map((gt, i) => (
                    <Form.Group
                      key={`check-group-${i}`}
                      className="fixed-width-100 mb-1"
                    >
                      <Form.Check
                        onChange={() => setTypeOfPlay(gt.type)}
                        name="type-radio"
                        required
                        key={`check-type-${i}`}
                        type="radio"
                        label={`${gt.text}`}
                      />
                    </Form.Group>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="font-18-bold">GAME CLOCK</div>
                <ChooseGameTime setGameTime={setGameTime} />
              </div>
              <div className="d-flex flex-row w-75 mt-5 justify-content-end">
                <Button
                  name="next"
                  disabled={isLoading}
                  className="game-button-height orange-button"
                  type="submit"
                >
                  SAVE GAME
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

CreateGame.propTypes = {
  createNewGame: PropTypes.func.isRequired,
  stories: PropTypes.array.isRequired,
  onStorySelect: PropTypes.func.isRequired,
};

export default CreateGame;
