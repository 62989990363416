import React from "react";
import { Header } from "./components";

import styles from "./PublicLayout.module.scss";

interface PublicLayoutProps {}

export default function PublicLayout({
  children,
}: React.PropsWithChildren<PublicLayoutProps>) {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header className={styles.header} />
        {children}
      </div>
    </div>
  );
}
