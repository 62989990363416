import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import toast from '../components/Notistack';

const AppContext = createContext<any>({
  colors: [],
  creativities: [],
  hubs: [],
});

export const AppProvider = ({ children }: any) => {
  const [colors, setColors] = useState<any[]>([]);
  const [creativities, setCreativities] = useState<any[]>([]);
  const [hubs, setHubs] = useState<any[]>([]);

  const init = useCallback(async () => {
    try {
      const response = await fetch('/api/routes/global/getGlobalStats');
      const data = await response.json();
      setColors((data?.stats?.colors || []).filter((item: any) => !item.hidden));
      setCreativities((data?.stats?.creativities || []).filter((item: any) => !item.hidden));
      setHubs((data?.stats?.hubs || []).filter((item: any) => !item.hidden));
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <AppContext.Provider
      value={{
        colors,
        creativities,
        hubs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
