import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Image, Container } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import sneakerBox from "images/png/sneakerbox.png"
import Sidebar from "components/Sidebar"
import Header from "components/Header"
import { tokenTypes } from "constants/tokenType"
import { gameSaveStatus } from "constants/gameSaveStatus";
import Modal from "components/GameSaveModal"
import "./tokens.css"

const { Config } = require("../../config.js");
const picturePrefix = Config[process.env.REACT_APP_ENV]["picturePrefix"]

const TokensComponent = ({
  isLoading,
  gameDetail,
  gameSaves,
  tokens,
  getProductsByToken,
  getGamesByProduct,
  getGameDetails,
  getTokenByType,
  games,
  products,
  deleteToken,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [userGS, setUserGS] = useState({})
  // const usersPlayed = gameSaves.filter(gs => gs.userGs.state === gameSaveStatus.SUBMITTED)
  // const usersVoted = gameSaves.filter(gs => gs.userGs.state === gameSaveStatus.VOTED)

  useEffect(() => {
    if (Object.keys(userGS).length) {
      setShowModal(true)
    }
  }, [userGS])

  return (
    <>
      {isLoading &&
        <div className="bouncer-loading">
          <BounceLoader
            size={25}
            color={"#000"}
            loading={isLoading}
          />
        </div>
      }
      <Container className="min-height" fluid>
        <Row>
          <Header />
        </Row>
        <Row className={`${isLoading ? "opacity-5" : ""}`}>
          <Col xs={2} className="no-side-paddings sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} className="background-light-grey page-content-wrapper">
            <div className="mt-3 mb-3 d-flex flex-row w-100">
              {tokenTypes.map(tt => (
                <div
                  onClick={() => getTokenByType(tt)}
                  className="cursor-pointer w-100 d-flex flex-column align-items-center"
                >
                  <Image className="product-type-image" src={sneakerBox} />
                  <p className="text-center font-18">{tt}</p>
                </div>
              ))}
            </div>
            {tokens.length ? (
              <div className="font-18-bold ms-3 mt-3 mb-3">Tokens</div>
            ) : null}
            <div className="ms-3 me-3 mt-3 mb-3 d-flex flex-row w-100">
              <div className="d-flex flex-wrap w-100">
                {tokens.map(token => (
                  <div
                    onClick={() => getProductsByToken(token)}
                    className="cursor-pointer me-3 d-flex flex-column align-items-center token-item"
                    key={token._id}
                  >
                    <Image className="product-type-image" src={`${picturePrefix}/${token?.image?.url}.png`} />
                    <p className="text-center font-18">{token.name}</p>
                    <div 
                      className="token-delete"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteToken(token._id);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {products.length ? (
              <div className="font-18-bold ms-3 mt-3 mb-3">Products</div>
            ) : null}
            <div className="ms-3 me-3 mt-3 mb-3 d-flex flex-row w-100">
              <div className="d-flex flex-wrap w-100">
                {products.map(product => (
                  <div
                    onClick={() => getGamesByProduct(product)}
                    className="cursor-pointer me-3 d-flex flex-column align-items-center"
                    key={product._id}
                  >
                    <Image className="product-type-image" src={product.images[0]?.url ? `${picturePrefix}/${product.images[0].url}.png` : product.images[0]} />
                    <p className="text-center font-18">{product.name}</p>
                  </div>
                ))}
              </div>
            </div>
            {games.length ? (
              <div className="font-18-bold ms-3 mt-3 mb-3">Games</div>
            ) : null}
            <div className="ms-3 me-3 mt-3 mb-3 d-flex flex-row w-100">
              <div className="d-flex flex-wrap w-100">
                {games.map(g => (
                  <div
                    onClick={() => getGameDetails(g)}
                    className="cursor-pointer me-3 d-flex flex-column align-items-center"
                    key={g._id}
                  >
                    <Image className="product-type-image" src={sneakerBox} />
                    <p className="text-center font-18">{g.name}</p>
                  </div>
                ))}
              </div>
            </div>
            {gameSaves.length ? (
              <div className="font-18-bold ms-3 mt-3 mb-3">Game Saves</div>
            ) : null}
            <div className="mt-3 mb-3 d-flex flex-row w-100">
              {gameSaves.length > 0 &&
                <div className="w-100 d-flex flex-wrap pl-3 pr-3 pt-2">
                  {gameSaves.map((gs, i) => (
                    <div
                      onClick={() => setUserGS(gs.userGs)}
                      className="me-4 align-items-center cursor-pointer d-flex flex-column"
                      key={gs._id}
                    >
                      <Image
                        key={i}
                        className={"mt-3 mb-3 ms-3 me-3 avatar"}
                        src={gs.userGs.userId.nodeID}
                        roundedCircle
                      />
                      <p className="font-16 text-center">{gs.userGs.userId.username}</p>
                      <div className="d-flex flex-row justify-content-center">
                        {(gs.userGs.state === gameSaveStatus.SUBMITTED || gs.userGs.state === gameSaveStatus.VOTED) && <div className="red-circle"></div>}
                        {gs.userGs.state === gameSaveStatus.VOTED && <div className="ms-1 blue-circle"></div>}
                      </div>
                      {gs.userGs.state === gameSaveStatus.VOTED && <p className="font-16 text-center">{gs.userGs.percentage}%</p>}
                    </div>
                  ))}
                </div>
              }
            </div>
          </Col>
        </Row>

        {showModal &&
          <Modal
            userGS={userGS}
            cancel={() => {
              setUserGS({})
              setShowModal(false)
            }}
          />
        }
      </Container>
    </>
  );
};

TokensComponent.propTypes = {
  getTokenByType: PropTypes.func.isRequired,
  getProductsByToken: PropTypes.func.isRequired,
  getGamesByProduct: PropTypes.func.isRequired,
  getGameDetails: PropTypes.func.isRequired
};

TokensComponent.defaultProps = {

}

export default TokensComponent;
