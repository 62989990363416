import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { PickerDropPane } from "filestack-react";

import { usePrevious } from "hooks";

import BounceLoader from "react-spinners/BounceLoader";
import DrawContent from "./DrawContent/DrawContent";

import { types, categories } from "constants/feed";
import {
  Feed,
  Game,
  GoPortalFeed,
  Message,
  Portal,
  Story,
} from "../../interface";
import Messages from "./Messages/Messages/Messages";
import { MenuItem, Select } from "@material-ui/core";

const { Config } = require("../../config.js");
const fileStackApiKey =
  Config[process.env.REACT_APP_ENV as any]["filestackApiKey"];

const fileStackPrefix =
  Config[process.env.REACT_APP_ENV as any]["fileStackPrefix"];

interface IProps {
  isLoading?: boolean;
  portals: Portal[];
  games: Game[];
  feed: Feed[];
  stories: Story[];
  goPortalFeed: GoPortalFeed[];

  createFeed: (values: Feed) => void;
  updateFeed: (feedId: number, values: Feed) => void;
  deleteFeed: (feedId: number) => void;
  createGoPortalFeed: (values: GoPortalFeed) => void;
  updateGoPortalFeed: (goPortalFeedId: number, values: GoPortalFeed) => void;
  deleteGoPortalFeed: (goPortalFeedId: number) => void;
}

const FeedComponent = ({
  createFeed,
  updateFeed,
  deleteFeed,
  portals,
  feed,
  games,
  stories,
  isLoading,
  goPortalFeed,
  createGoPortalFeed,
  updateGoPortalFeed,
  deleteGoPortalFeed,
}: IProps) => {
  const [token, setGameToken] = useState<any>(null);
  const [feedActive, setFeedActive] = useState<any>(undefined);
  const [goPortalFeedActive, setGoPortalFeedActive] = useState<any>(undefined);
  const [selectedFeedsToDisplay, setSelectedFeedsToDisplay] =
    useState<string>("all");
  const [selectedFeeds, setSelectedFeeds] = useState<string>("feeds");
  const [goPortalFeedImageUploaded, setGoPortalFeedImageUploaded] =
    useState<boolean>(false);
  const [fileMobileUploaded, setFileMobileUploaded] = useState<boolean>(false);
  const [fileDesktopUploaded, setFileDesktopUploaded] =
    useState<boolean>(false);

  const [mobileVideoUploaded, setMobileVideoUploaded] = useState({
    pending: false,
    submission: false,
    vote: false,
    completed: false,
    play_starts: false,
    hold: false,
  });
  const [desktopVideoUploaded, setDesktopVideoUploaded] = useState({
    pending: false,
    submission: false,
    vote: false,
    completed: false,
    play_starts: false,
    hold: false,
  });

  const [thumbnailsUploaded, setThumbnailsUploaded] = useState({
    pending: false,
    submission: false,
    vote: false,
    completed: false,
    hold: false,
  });

  const feedFormik = useFormik({
    initialValues: {
      portal: feedActive?.portalID || "",
      game: feedActive?.gameID || "",
      story: feedActive?.storyID || "",
      title: feedActive?.title || "",
      type: feedActive?.type || "text",
      category: feedActive?.gameID ? "play" : feedActive?.category || "news",
      message: feedActive?.message || "",
      mobileFile: feedActive?.mobileFile || "",
      desktopFile: feedActive?.desktopFile || "",
      mobileVideos: {
        pending: feedActive?.mobileVideos?.pending || "",
        submission: feedActive?.mobileVideos?.submission || "",
        vote: feedActive?.mobileVideos?.vote || "",
        completed: feedActive?.mobileVideos?.completed || "",
        play_starts: feedActive?.mobileVideos?.play_starts || "",
        hold: feedActive?.mobileVideos?.hold || "",
      },
      desktopVideos: {
        pending: feedActive?.desktopVideos?.pending || "",
        submission: feedActive?.desktopVideos?.submission || "",
        vote: feedActive?.desktopVideos?.vote || "",
        completed: feedActive?.desktopVideos?.completed || "",
        play_starts: feedActive?.desktopVideos?.play_starts || "",
        hold: feedActive?.desktopVideos?.hold || "",
      },
      thumbnails: {
        pending: feedActive?.thumbnails?.pending || "",
        submission: feedActive?.thumbnails?.submission || "",
        vote: feedActive?.thumbnails?.vote || "",
        completed: feedActive?.thumbnails?.completed || "",
        hold: feedActive?.thumbnails?.hold || "",
      },
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let request: any = {
        portal: values.portal,
        game: values.game,
        story: values.story,
        title: values.title,
        type: values.type,
        category: values.game ? "play" : values.category,
        message: values.message,
        mobileFile: values.mobileFile,
        desktopFile: values.desktopFile,
        mobileVideos: {
          pending: values?.mobileVideos?.pending,
          submission: values?.mobileVideos?.submission,
          vote: values?.mobileVideos?.vote,
          completed: values?.mobileVideos?.completed,
          play_starts: values?.mobileVideos?.play_starts,
          hold: values?.mobileVideos?.hold,
        },
        desktopVideos: {
          pending: values?.desktopVideos?.pending,
          submission: values?.desktopVideos?.submission,
          vote: values?.desktopVideos?.vote,
          completed: values?.desktopVideos?.completed,
          play_starts: values?.desktopVideos?.play_starts,
          hold: values?.desktopVideos?.hold,
        },
        thumbnails: {
          pending: values?.thumbnails?.pending,
          submission: values?.thumbnails?.submission,
          vote: values?.thumbnails?.vote,
          completed: values?.thumbnails?.completed,
          hold: values?.thumbnails?.hold,
        },
      };
      if (feedActive) {
        await updateFeed(feedActive._id, request);
      } else {
        await createFeed(values);
      }
      feedFormik.resetForm();
    },
  });

  const goPortalFeedFormik = useFormik({
    initialValues: {
      description: goPortalFeedActive?.description || "",
      link: goPortalFeedActive?.link || "",
      imageUrl: goPortalFeedActive?.imageUrl || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let request: any = {
        description: values.description,
        link: values.link,
        imageUrl: values.imageUrl,
      };

      if (goPortalFeedActive) {
        await updateGoPortalFeed(goPortalFeedActive._id, request);
      } else {
        await createGoPortalFeed(values);
      }

      goPortalFeedFormik.resetForm();
    },
  });

  const formDisabled = false;

  const feedContentTitle = useMemo(() => {
    switch (feedFormik.values.type) {
      case "video":
        return "VIDEO";
      case "image":
        return "IMAGE";
      case "text":
      default:
        return "MESSAGE";
    }
  }, [feedFormik.values.type]);

  const prevValuesType = usePrevious(feedFormik.values.type);

  useEffect(() => {
    if (prevValuesType !== feedFormik.values.type) {
      setFileMobileUploaded(false);
      setFileDesktopUploaded(false);
      setMobileVideoUploaded({
        pending: false,
        submission: false,
        vote: false,
        completed: false,
        play_starts: false,
        hold: false,
      });
      setDesktopVideoUploaded({
        pending: false,
        submission: false,
        vote: false,
        completed: false,
        play_starts: false,
        hold: false,
      });
      setThumbnailsUploaded({
        pending: false,
        submission: false,
        vote: false,
        completed: false,
        hold: false,
      });
      feedFormik.setFieldValue("content", "");
    }
  }, [feedFormik.values.type, prevValuesType, feedFormik]);

  const feedContentValue = useMemo(() => {
    if (!feedFormik.values.game) {
      switch (feedFormik.values.type) {
        case "video":
        case "image":
          return (
            <>
              <p className="font-16-bold">MOBILE</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileFile",
                      res.filesUploaded[0].key,
                    );
                    setFileMobileUploaded(true);
                  }
                }}
                pickerOptions={{
                  accept:
                    feedFormik.values.type === "video" ? "video/*" : "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              {fileMobileUploaded && (
                <p className="mt-2 text-success">Mobile file uploaded</p>
              )}
              <p className="font-16-bold mt-4">DESKTOP</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopFile",
                      res.filesUploaded[0].key,
                    );
                    setFileDesktopUploaded(true);
                  }
                }}
                pickerOptions={{
                  accept:
                    feedFormik.values.type === "video" ? "video/*" : "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              {fileDesktopUploaded && (
                <p className="mt-2 text-success">Desktop file uploaded</p>
              )}
            </>
          );
        case "text":
        default:
          return (
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              name="message"
              placeholder=""
              onChange={feedFormik.handleChange}
              value={feedFormik.values.message}
            />
          );
      }
    } else {
      switch (feedFormik.values.type) {
        case "video":
          return (
            <>
              <p className="font-16-bold">MOBILE</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.pending",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      pending: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">PENDING</p>
              {mobileVideoUploaded.pending && (
                <p className="text-success">File uploaded</p>
              )}
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.submission",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      submission: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">SUBMISSION</p>
              {mobileVideoUploaded.submission && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.play_starts",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      play_starts: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">PLAY STARTS</p>
              {mobileVideoUploaded.play_starts && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.vote",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      vote: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">VOTE</p>
              {mobileVideoUploaded.vote && (
                <p className="text-success">File uploaded</p>
              )}
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.completed",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      completed: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">COMPLETED</p>
              {mobileVideoUploaded.completed && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileVideos.hold",
                      res.filesUploaded[0].key,
                    );
                    setMobileVideoUploaded({
                      ...mobileVideoUploaded,
                      hold: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">HOLD</p>
              {mobileVideoUploaded.hold && (
                <p className="text-success">File uploaded</p>
              )}

              <p className="font-16-bold mt-4">DESKTOP</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.pending",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      pending: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">PENDING</p>
              {desktopVideoUploaded.pending && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.submission",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      submission: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">SUBMISSION</p>
              {desktopVideoUploaded.submission && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.play_starts",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      play_starts: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">PLAY STARTS</p>
              {desktopVideoUploaded.play_starts && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.vote",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      vote: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">VOTE</p>
              {desktopVideoUploaded.vote && (
                <p className="text-success">File uploaded</p>
              )}
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.completed",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      completed: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">COMPLETED</p>
              {desktopVideoUploaded.completed && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopVideos.hold",
                      res.filesUploaded[0].key,
                    );
                    setDesktopVideoUploaded({
                      ...desktopVideoUploaded,
                      hold: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "video/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">HOLD</p>
              {desktopVideoUploaded.hold && (
                <p className="text-success">File uploaded</p>
              )}

              <p className="font-16-bold mt-6">THUMBNAILS</p>

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "thumbnails.pending",
                      `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                    );
                    setThumbnailsUploaded({
                      ...thumbnailsUploaded,
                      pending: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">PENDING</p>
              {thumbnailsUploaded.pending && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "thumbnails.submission",
                      `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                    );
                    setThumbnailsUploaded({
                      ...thumbnailsUploaded,
                      submission: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">SUBMISSION</p>
              {thumbnailsUploaded.submission && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "thumbnails.vote",
                      `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                    );
                    setThumbnailsUploaded({
                      ...thumbnailsUploaded,
                      vote: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">VOTE</p>
              {thumbnailsUploaded.vote && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "thumbnails.completed",
                      `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                    );
                    setThumbnailsUploaded({
                      ...thumbnailsUploaded,
                      completed: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">COMPLETED</p>
              {thumbnailsUploaded.completed && (
                <p className="text-success">File uploaded</p>
              )}

              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "thumbnails.hold",
                      `${fileStackPrefix}/${res.filesUploaded[0].key}`,
                    );
                    setThumbnailsUploaded({
                      ...thumbnailsUploaded,
                      hold: true,
                    });
                  }
                }}
                pickerOptions={{
                  accept: "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              <p className="font-12">HOLD</p>
              {thumbnailsUploaded.hold && (
                <p className="text-success">File uploaded</p>
              )}
            </>
          );
        case "image":
          return (
            <>
              <p className="font-16-bold">MOBILE</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "mobileFile",
                      res.filesUploaded[0].key,
                    );
                    setFileMobileUploaded(true);
                  }
                }}
                pickerOptions={{
                  accept:
                    feedFormik.values.type === "video" ? "video/*" : "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              {fileMobileUploaded && (
                <p className="mt-2 text-success">Mobile file uploaded</p>
              )}
              <p className="font-16-bold mt-4">DESKTOP</p>
              <PickerDropPane
                apikey={fileStackApiKey}
                onError={(error) => {
                  console.log("error", error);
                }}
                onUploadDone={(res: any) => {
                  if (res?.filesUploaded.length > 0) {
                    feedFormik.setFieldValue(
                      "desktopFile",
                      res.filesUploaded[0].key,
                    );
                    setFileDesktopUploaded(true);
                  }
                }}
                pickerOptions={{
                  accept:
                    feedFormik.values.type === "video" ? "video/*" : "image/*",
                  storeTo: {
                    container: "ic-filestack",
                    region: "us-west-1",
                  },
                }}
              />
              {fileDesktopUploaded && (
                <p className="mt-2 text-success">Desktop file uploaded</p>
              )}
            </>
          );
        case "text":
        default:
          return (
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              name="message"
              placeholder=""
              onChange={feedFormik.handleChange}
              value={feedFormik.values.message}
            />
          );
      }
    }
  }, [
    fileMobileUploaded,
    fileDesktopUploaded,
    feedFormik,
    mobileVideoUploaded,
    desktopVideoUploaded,
  ]);

  const goPortalFeedContentValue = useMemo(() => {
    return (
      <>
        <PickerDropPane
          apikey={fileStackApiKey}
          onError={(error) => {
            console.log("error", error);
          }}
          onUploadDone={(res: any) => {
            if (res?.filesUploaded.length > 0) {
              goPortalFeedFormik.setFieldValue(
                "imageUrl",
                res.filesUploaded[0].key,
              );
              setGoPortalFeedImageUploaded(true);
            }
          }}
          pickerOptions={{
            accept: "image/*",
            storeTo: {
              container: "ic-filestack",
              region: "us-west-1",
            },
          }}
        />
        {goPortalFeedImageUploaded && (
          <p className="mt-2 text-success">Image uploaded</p>
        )}
      </>
    );
  }, [goPortalFeedFormik, goPortalFeedImageUploaded]);

  useEffect(() => {
    if (feedFormik.values.game) {
      const game = games.find((game) => game._id === feedFormik.values.game);
      if (game?.token) {
        setGameToken(game?.token);
      }
    } else {
      setGameToken(null);
    }
  }, [feedFormik.values.game]);

  const handleSelectedFeedsToDisplayChange = (event: any) => {
    setSelectedFeedsToDisplay(event.target.value);
  };

  const handleSelectedFeedsChange = (event: any) => {
    setSelectedFeeds(event.target.value);
  };

  const handleFeedActiveChange = (item: Feed) => {
    setFeedActive(item);
    setGoPortalFeedActive(undefined);
    setSelectedFeeds("feeds");
  };

  const handleGoPortalFeedActiveChange = (item: GoPortalFeed) => {
    setGoPortalFeedActive(item);
    setFeedActive(undefined);
    setSelectedFeeds("goPortalFeeds");
  };

  const handleActiveRemove = () => {
    setFeedActive(undefined);
    setGoPortalFeedActive(undefined);
  };

  return (
    <Container className={`${isLoading ? "opacity-5" : ""}`}>
      {isLoading && (
        <div className="bouncer-loading">
          <BounceLoader size={25} color={"#000"} loading={isLoading} />
        </div>
      )}
      <Row className="h-100">
        <Col className="border-right-black" sm={6}>
          <div className="mt-4 d-flex flex-column row-gap-3">
            <Button
              className="mt-2 mb-4 w-100 orange-button"
              onClick={() => handleActiveRemove}
              disabled={isLoading}
            >
              Add new
            </Button>
            <Select
              className="mb-4"
              value={selectedFeedsToDisplay}
              onChange={handleSelectedFeedsToDisplayChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"goPortalFeed"}>Go Portal Feeds</MenuItem>
              <MenuItem value={"feeds"}>Feeds</MenuItem>
            </Select>
            {/* Conditionally render based on selectedFeedsToDisplay */}
            {selectedFeedsToDisplay === "goPortalFeed" &&
              goPortalFeed.map((item) => (
                <div
                  key={item._id}
                  className="d-flex mb-8 align-items-start cursor-pointer"
                  onClick={() => handleGoPortalFeedActiveChange(item)}
                >
                  <div className="w-100">
                    <div className="d-flex justify-content-between">
                      <img
                        className="me-4"
                        alt={item.link}
                        src={item?.imageUrl}
                        style={{
                          maxWidth: 60,
                          maxHeight: 60,
                        }}
                      />
                      <p>
                        {moment(item.updated_at).format("MM/DD, HH:mm")}{" "}
                        <span
                          className="text-danger"
                          onClick={() => deleteGoPortalFeed(item._id as number)}
                        >
                          delete
                        </span>
                      </p>
                    </div>
                    <p>
                      <b>{item.description}</b>
                    </p>
                  </div>
                </div>
              ))}
            {selectedFeedsToDisplay === "feeds" &&
              feed.map((item) => (
                <div
                  key={item._id}
                  className="d-flex mb-8 align-items-start cursor-pointer"
                  onClick={() => handleFeedActiveChange(item)}
                >
                  <div className="w-100">
                    <p className="d-flex justify-content-between">
                      <img
                        className="me-4"
                        alt={item.name}
                        src={item?.portalFull?.icon}
                        style={{
                          maxWidth: 60,
                          maxHeight: 60,
                        }}
                      />
                      <i>
                        {moment(item.updated_at).format("MM/DD, HH:mm")}{" "}
                        <span
                          className="text-danger"
                          onClick={() => deleteFeed(item._id as number)}
                        >
                          delete
                        </span>
                      </i>
                    </p>
                    <p>
                      <b>{item.title}</b>
                    </p>
                    <div>
                      <DrawContent item={item} />
                    </div>
                  </div>
                </div>
              ))}
            {selectedFeedsToDisplay === "all" && (
              <>
                {goPortalFeed.map((item) => (
                  <div
                    key={item._id}
                    className="d-flex mb-8 align-items-start cursor-pointer"
                    onClick={() => handleGoPortalFeedActiveChange(item)}
                  >
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <img
                          className="me-4"
                          alt={item.link}
                          src={item?.imageUrl}
                          style={{
                            maxWidth: 60,
                            maxHeight: 60,
                          }}
                        />
                        <p>
                          {moment(item.updated_at).format("MM/DD, HH:mm")}{" "}
                          <span
                            className="text-danger"
                            onClick={() =>
                              deleteGoPortalFeed(item._id as number)
                            }
                          >
                            delete
                          </span>
                        </p>
                      </div>
                      <p className="pt-4">
                        <b>{item.description}</b>
                      </p>
                    </div>
                  </div>
                ))}
                <hr />
                {feed.map((item) => (
                  <div
                    key={item._id}
                    className="d-flex mb-8 align-items-start cursor-pointer"
                    onClick={() => handleFeedActiveChange(item)}
                  >
                    <div className="w-100">
                      <p className="d-flex justify-content-between">
                        <img
                          className="me-4"
                          alt={item.name}
                          src={item?.portalFull?.icon}
                          style={{
                            maxWidth: 60,
                            maxHeight: 60,
                          }}
                        />
                        <i>
                          {moment(item.updated_at).format("MM/DD, HH:mm")}{" "}
                          <span
                            className="text-danger"
                            onClick={() => deleteFeed(item._id as number)}
                          >
                            delete
                          </span>
                        </i>
                      </p>
                      <p>
                        <b>{item.title}</b>
                      </p>
                      <div>
                        <DrawContent item={item} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Col>
        <Col sm={6}>
          {!feedActive && !goPortalFeedActive && (
            <Select
              className="w-100 mt-4"
              value={selectedFeeds}
              onChange={handleSelectedFeedsChange}
            >
              <MenuItem value={"feeds"}>Feeds</MenuItem>
              <MenuItem value={"goPortalFeeds"}>Go Portal Feeds</MenuItem>
            </Select>
          )}
          {selectedFeeds === "feeds" && !goPortalFeedActive && (
            <Form className="w-100 mt-4" onSubmit={feedFormik.handleSubmit}>
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">PORTAL</Form.Label>
                <Form.Control
                  as="select"
                  name="portal"
                  disabled={formDisabled}
                  value={feedFormik.values.portal}
                  onChange={feedFormik.handleChange}
                >
                  <option value="none">None</option>
                  {portals.map((portal: any) => (
                    <option key={portal._id} value={portal._id}>
                      {portal.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">GAME</Form.Label>
                <Form.Control
                  as="select"
                  name="game"
                  disabled={formDisabled}
                  value={feedFormik.values.game}
                  onChange={feedFormik.handleChange}
                  className="h-50"
                >
                  <option value="">None</option>
                  {games.map((game: any) => (
                    <option key={game._id} value={game._id}>
                      {game.name}
                    </option>
                  ))}
                </Form.Control>
                {token && (
                  <Form.Label className="font-16-bold mt-2 px-2">
                    Token: {token?.name}-{token?.symbol}
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="font-18-bold">TITLE</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder=""
                  onChange={feedFormik.handleChange}
                  value={feedFormik.values.title}
                />
              </Form.Group>
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">CONTENT TYPE</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  disabled={formDisabled}
                  value={feedFormik.values.type}
                  onChange={feedFormik.handleChange}
                >
                  {types.map((type: any) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {!feedFormik.values.game && (
                <>
                  <Form.Group className="w-100 mb-4">
                    <Form.Label className="font-18-bold">CATEGORY</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      disabled={formDisabled}
                      value={feedFormik.values.category}
                      onChange={feedFormik.handleChange}
                    >
                      {categories.map((category: any) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="w-100 mb-4">
                    <Form.Label className="font-18-bold">STORY</Form.Label>
                    <Form.Control
                      as="select"
                      name="story"
                      disabled={formDisabled}
                      value={feedFormik.values.story}
                      onChange={feedFormik.handleChange}
                    >
                      {stories.length &&
                        stories.map((story: any) => (
                          <option key={story._id} value={story._id}>
                            Story {story.number} - {story.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </>
              )}
              {feedActive && <Messages feed={feedActive} />}
              <Form.Group className="mb-4">
                <Form.Label className="font-18-bold">
                  {feedContentTitle}
                </Form.Label>
                {feedContentValue}
              </Form.Group>
              <Button
                className="mt-4 w-100 orange-button"
                type="submit"
                disabled={isLoading}
              >
                SAVE
              </Button>
            </Form>
          )}
          {selectedFeeds === "goPortalFeeds" && !feedActive && (
            <Form
              className="w-100 mt-4"
              onSubmit={goPortalFeedFormik.handleSubmit}
            >
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">LINK</Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={goPortalFeedFormik.values.link}
                  onChange={goPortalFeedFormik.handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">IMAGE</Form.Label>
                {goPortalFeedContentValue}
              </Form.Group>
              <Form.Group className="w-100 mb-4">
                <Form.Label className="font-18-bold">DESCRIPTION</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={goPortalFeedFormik.values.description}
                  onChange={goPortalFeedFormik.handleChange}
                ></Form.Control>
              </Form.Group>
              <Button
                className="mt-4 w-100 orange-button"
                type="submit"
                disabled={isLoading}
              >
                SAVE
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FeedComponent;
