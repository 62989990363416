import React from 'react';
import classNames from 'classnames';
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from './ObjectItem.module.scss';

interface IProps {
  active?: boolean;
  item: any;
  handleClick: (name: string) => void;
}

export default function ObjectItem({ active, item, handleClick }: IProps) {
  return (
    <OverlayTrigger
      overlay={(
        <Tooltip id={item?.name}>
          {item?.name}
        </Tooltip>
      )}
    >
      <Image
        roundedCircle
        className={classNames("token-image", styles.image, {
          [styles.active]: active,
        })}
        onClick={() => handleClick(item.name)}
        src={item?.symbol}
        title={item?.name}
      />
    </OverlayTrigger>
  );
}