import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../services/axios";
import BounceLoader from "react-spinners/BounceLoader";
import toast from "../../components/Notistack";
import Layout from "components/Layout";
import TokenComponent from "./TokenComponent";
import {Game, Portal, Story, Token} from "../../interface";

const TokenContainer = ({ brand }: any) => {
  const history = useHistory();
  const [tokens, setTokens] = useState<Token[]>([]);
  const [portals, setPortals] = useState<Portal[]>([]);
  const [stories, setStories] = useState<Story[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadTokens = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`api/routes/tokens/all`);
      setTokens(response?.data?.data || []);
      setIsLoading(false);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, []);

  const loadPortals = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/portals/get`);
      setPortals(response?.data?.data || []);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  }, []);

  const loadStories = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/stories/get`);
      setStories(response?.data?.data || []);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  }, []);

  const loadGames = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/games/getCompletedGames`);
      setGames(response?.data?.games || []);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  }, []);

  const createToken = useCallback(
    async (values: Token) => {
      try {
        setIsLoading(true);
        await axios.post(`api/routes/tokens/create`, { values });
        loadTokens();
        setIsLoading(false);
        toast.success("Token successfully created");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  const updateToken = useCallback(
    async (tokenId: number, values: Token) => {
      try {
        setIsLoading(true);
        await axios.put(`api/routes/tokens/update`, {
          tokenId,
          values,
        });
        loadTokens();
        setIsLoading(false);
        toast.success("Token successfully updated");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  const addSlides = useCallback(
    async (tokenId: number, values: Token) => {
      try {
        setIsLoading(true);
        await axios.put(`api/routes/tokens/add-slides`, {
          tokenId,
          values,
        });
        loadTokens();
        setIsLoading(false);
        toast.success("Token successfully updated");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  const uploadToIpfs = useCallback(
    async (tokenId: number) => {
      try {
        setIsLoading(true);
        await axios.post(`api/routes/tokens/${tokenId}/metadata`);
        loadTokens();
        setIsLoading(false);
        toast.success("Token successfully uploaded to IPFS");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  const deployToken = useCallback(
    async (tokenId: number) => {
      try {
        setIsLoading(true);
        await axios.post(`api/routes/tokens/${tokenId}/deploy`);
        loadTokens();
        setIsLoading(false);
        toast.success("Token successfully deployed");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  const startSale = useCallback(
    async (tokenId: number, price: number) => {
      try {
        setIsLoading(true);
        await axios.post(`api/routes/tokens/${tokenId}/start-sale`, {
          price,
        });
        loadTokens();
        setIsLoading(false);
        toast.success("Token sale successfully started");
      } catch (error: any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    },
    [loadTokens],
  );

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/");
    } else {
      loadPortals();
      loadStories();
      loadTokens();
      loadGames();
    }
  }, [loadPortals, brand, history, loadStories, loadTokens, loadGames]);

  return (
    <Layout>
      {isLoading ? (
        <div className="bouncer-loading">
          <BounceLoader size={25} color={"#000"} loading={true} />
        </div>
      ) : (
        <TokenComponent
          createToken={createToken}
          updateToken={updateToken}
          addSlides={addSlides}
          tokens={tokens}
          portals={portals}
          stories={stories}
          games={games}
          uploadToIpfs={uploadToIpfs}
          deployToken={deployToken}
          startSale={startSale}
        />
      )}
    </Layout>
  );
};

export default connect((state: any) => ({
  brand: state.auth.user,
}))(TokenContainer);
