import React from "react";

export function HeartIcon({ className }: DefaultIconProps) {
  return (
    <svg
      className={className}
      width="49"
      height="40"
      viewBox="0 0 49 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.5546 7.09717H41.7324V13.4857H48.5546V7.09717Z"
        fill="#A5241A"
      />
      <path
        d="M48.5546 13.4858H41.7324V19.8744H48.5546V13.4858Z"
        fill="#84180F"
      />
      <path
        d="M34.9198 0.708496H28.0977V7.09703H34.9198V0.708496Z"
        fill="#EA5750"
      />
      <path
        d="M41.7323 0.708496H34.9102V7.09703H41.7323V0.708496Z"
        fill="#DE443B"
      />
      <path
        d="M34.9198 26.2627H28.0977V32.6512H34.9198V26.2627Z"
        fill="#A5241A"
      />
      <path
        d="M41.7323 19.874H34.9102V26.2626H41.7323V19.874Z"
        fill="#A5241A"
      />
      <path
        d="M7.6405 7.09717H0.818359V13.4857H7.6405V7.09717Z"
        fill="#EC706B"
      />
      <path
        d="M7.6405 13.4858H0.818359V19.8744H7.6405V13.4858Z"
        fill="#EA5750"
      />
      <path
        d="M28.0975 7.09717H21.2754V13.4857H28.0975V7.09717Z"
        fill="#DE443B"
      />
      <path
        d="M34.9198 7.09717H28.0977V13.4857H34.9198V7.09717Z"
        fill="#DE443B"
      />
      <path
        d="M41.7323 7.09717H34.9102V13.4857H41.7323V7.09717Z"
        fill="#DE443B"
      />
      <path
        d="M41.7323 13.4858H34.9102V19.8744H41.7323V13.4858Z"
        fill="#DE443B"
      />
      <path
        d="M21.2753 7.09717H14.4531V13.4857H21.2753V7.09717Z"
        fill="#DE443B"
      />
      <path
        d="M14.4628 7.09717H7.64062V13.4857H14.4628V7.09717Z"
        fill="#EA5750"
      />
      <path
        d="M28.0975 13.4858H21.2754V19.8744H28.0975V13.4858Z"
        fill="#DE443B"
      />
      <path
        d="M34.9198 13.4858H28.0977V19.8744H34.9198V13.4858Z"
        fill="#DE443B"
      />
      <path
        d="M21.2753 13.4858H14.4531V19.8744H21.2753V13.4858Z"
        fill="#DE443B"
      />
      <path
        d="M28.0975 19.874H21.2754V26.2626H28.0975V19.874Z"
        fill="#DE443B"
      />
      <path
        d="M28.0975 26.2627H21.2754V32.6512H28.0975V26.2627Z"
        fill="#A5241A"
      />
      <path
        d="M34.9198 19.874H28.0977V26.2626H34.9198V19.874Z"
        fill="#DE443B"
      />
      <path
        d="M21.2753 19.874H14.4531V26.2626H21.2753V19.874Z"
        fill="#DE443B"
      />
      <path
        d="M14.4628 13.4858H7.64062V19.8744H14.4628V13.4858Z"
        fill="#DE443B"
      />
      <path
        d="M28.0975 32.6509H21.2754V39.0394H28.0975V32.6509Z"
        fill="#84180F"
      />
      <path
        d="M21.2753 0.708496H14.4531V7.09703H21.2753V0.708496Z"
        fill="#EA5750"
      />
      <path
        d="M14.4628 0.708496H7.64062V7.09703H14.4628V0.708496Z"
        fill="#EC706B"
      />
      <path
        d="M21.2753 26.2627H14.4531V32.6512H21.2753V26.2627Z"
        fill="#A5241A"
      />
      <path
        d="M14.4628 19.874H7.64062V26.2626H14.4628V19.874Z"
        fill="#DE443B"
      />
    </svg>
  );
}
