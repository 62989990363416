import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../services/axios";
import toast from "../../components/Notistack";

import Layout from "components/Layout";

import FeedComponent from "./FeedComponent";
import {Feed, Game, GoPortalFeed, Portal, Story} from "interface";

const PortalContainer = ({ brand }: any) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [portals, setPortals] = useState<Portal[]>([])
  const [games, setGames] = useState<Game[]>([]);
  const [feed, setFeed] = useState<Feed[]>([]);
  const [stories, setStories] = useState<Story[]>([]);
  const [goPortalFeed, setGoPortalFeed] = useState<GoPortalFeed[]>([]);

  const loadPortals = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`api/routes/portals/get`);
      setPortals(response?.data?.data || []);
      setIsLoading(false);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, []);

  const loadGames = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/games/getAll`);
      setGames(response?.data?.game || []);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, []);



  const loadStories = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/stories/get`);
      setStories(response?.data?.data || []);
    } catch (error:any) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsLoading(false);
    }
  }, []);

  const loadFeed = useCallback(async () => {
    try {
      const response = await axios.get(`api/routes/feed/get`);
      setFeed(response?.data?.data || []);
    } catch (error:any) {
      toast.error(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  }, []);

  const createFeed = useCallback(
    async (values: Feed) => {
      setIsLoading(true);

      await axios.post(`api/routes/feed/create`, { values });
      await loadFeed();
      await loadGames();
      setIsLoading(false);
    },
    [loadFeed, loadGames],
  );

  const updateFeed = useCallback(
    async (feedId: number, values: Feed) => {
      setIsLoading(true);
      await axios.put(`api/routes/feed/update`, {
        feedId,
        values,
      });
      await loadFeed();
      await loadGames();
      setIsLoading(false);
    },
    [loadFeed, loadGames],
  );

  const deleteFeed = useCallback(
    async (feedId: number) => {
      setIsLoading(true);
      await axios.post(`api/routes/feed/delete`, {
        feedId,
      });
      await loadFeed();
      await loadGames();
      setIsLoading(false);
    },
    [loadFeed, loadGames],
  );

  const loadGoPortalFeed = useCallback(async () => {
     try {
         const response = await axios.get(`api/routes/go-portal-feed/get`);
         setGoPortalFeed(response?.data?.data || []);
     } catch (error: any) {
         toast.error(error?.response?.data?.message || error?.message);
         setIsLoading(false);
     }
  }, []);

  const createGoPortalFeed = useCallback(async (values: GoPortalFeed) => {
    setIsLoading(true);

    await axios.post(`api/routes/go-portal-feed/create`, {values});
    await loadGoPortalFeed();
    
    setIsLoading(false);
  }, [loadGoPortalFeed]);

  const updateGoPortalFeed = useCallback(async(goPortalFeedId: number, values: GoPortalFeed) => {
      setIsLoading(true);

      console.log(values);

      try {
          await axios.put(`api/routes/go-portal-feed/update/${goPortalFeedId}`, {
              values
          });
      } catch (error: any) {
          toast.error(error?.response?.data?.message || error?.message);
          setIsLoading(false);
      }
      await loadGoPortalFeed();

      setIsLoading(false);
  }, [loadGoPortalFeed]);

  const deleteGoPortalFeed = useCallback(async(goPortalFeedId: number) => {
    setIsLoading(true);

    await axios.delete(`api/routes/go-portal-feed/delete/${goPortalFeedId}`);
    await loadGoPortalFeed();

    setIsLoading(false);
  }, [loadGoPortalFeed]);

  useEffect(() => {
    if (!brand.isLogged) {
      history.push("/");
    } else {
      void loadFeed();
      void loadPortals();
      void loadGames();
      void loadStories();
      void loadGoPortalFeed();
    }
  }, [loadPortals, loadFeed, loadGames, brand, history, loadStories, loadGoPortalFeed]);

  return (
    <Layout>
      <FeedComponent
        createFeed={createFeed}
        updateFeed={updateFeed}
        deleteFeed={deleteFeed}
        createGoPortalFeed={createGoPortalFeed}
        updateGoPortalFeed={updateGoPortalFeed}
        deleteGoPortalFeed={deleteGoPortalFeed}
        isLoading={isLoading}
        portals={portals}
        feed={feed}
        goPortalFeed={goPortalFeed}
        games={games}
        stories={stories}
      />
    </Layout>
  );
};

export default connect((state: any) => ({
  brand: state.auth.user,
}))(PortalContainer);
