import React, { useState } from 'react';
import moment from "moment"
import { CardDeck, Image, Card, Button, Form } from "react-bootstrap";

import arrowDown from "../../images/svg/arrow-down.svg"

import styles from './UserComponent.module.scss';

export default function UserComponent({ user, patchUser, approvePushuser, sendGoborb, sendNft, sendRoyaltyAmount }) {
  const [open, setOpen] = useState(false);
  const [goborbAmount, setGoborbAmount] = useState('');
  const [nftAddress, setNftAddress] = useState('');
  const [royaltyAmount, setRoyaltyAmount] = useState(user.royalties || 0);

  return (
    <CardDeck className="w-100 mb-3">
      <Card className="ppw-card h-100">
        <div className="p-10 d-flex flex-column h-100">
          <Card.Body className="d-flex flex-row list-view-body">
            <div className="w-100 me-3 d-flex flex-column">
              <Image roundedCircle className="token-image" src={user.nodeID} />
              <p className="text-left mb-1 font-18">{user.username}</p>
              <p className="text-left font-18">{user.email}</p>
            </div>
            <div className="my-auto d-flex flex-column w-100">
              <p className="font-18 opacity-5">VERIFIED</p>
              <p className="font-18-bold">{user.verified ? "True" : "False"}</p>
            </div>
            <div className="my-auto d-flex flex-column w-100">
              <p className="font-18 opacity-5">ONBOARDED</p>
              <p className="font-18-bold">{user.onboarding ? "True" : "False"}</p>
            </div>
            <div className="my-auto d-flex flex-column w-100">
              <p className="font-18 opacity-5">HUB</p>
              <p className="font-18-bold">{user.hub}</p>
            </div>
            <div className="my-auto d-flex flex-column w-100">
              <p className="font-18 opacity-5">CREATED</p>
              <p className="font-18-bold">{moment(user.creationDate).format('MMMM Do, YYYY')}</p>
            </div>
          </Card.Body>
          <div className={styles.menuWrapper}>
            <button className={styles.button} onClick={() => setOpen(!open)}>
              Actions <img alt="arrow-down" className={`${open ? "rotate-arrow" : "normal-arrow"}`} src={arrowDown} />
            </button>
            {open && (
              <div className='d-flex flex-column list-view-body'>
                {user.verified && (
                  <>
                    <div className="mt-3 d-flex flex-row">
                      <label className="font-18 mt-1 me-3 opacity-5">Tag</label>
                      <Form.Control
                        as="select"
                        onChange={(event) => patchUser(user, {
                          tag: event.target.value,
                        })}
                        value={user.tag || ""}
                      >
                        <option value="">None</option>
                        <option value="demo">Demo</option>
                        <option value="tester">Tester</option>
                      </Form.Control>
                    </div>
                    <div className="mt-3 d-flex flex-row">
                      <label className="font-18 mt-1 me-3 opacity-5">Royalties</label>
                      <Form.Control
                        value={royaltyAmount}
                        onChange={(event) => setRoyaltyAmount(event.target.value)}
                        placeholder='input GOBORB amount here'
                      />
                      <Button className="orange-button ms-3" onClick={() => sendRoyaltyAmount(royaltyAmount, user.email)}>
                        SET
                      </Button>
                    </div>
                  </>
                )}
                <div className="mt-3 d-flex flex-row">
                  <label className="font-18 mt-1 me-3 opacity-5">GOBORB</label>
                  <Form.Control
                    value={goborbAmount}
                    onChange={(event) => setGoborbAmount(event.target.value)}
                    placeholder='input GOBORB amount here'
                  />
                  <Button className="orange-button ms-3" onClick={() => sendGoborb(goborbAmount, user.ethAddress)}>
                    SEND
                  </Button>
                </div>
                <div className="mt-3 d-flex flex-row">
                  <label className="font-18 mt-1 me-3 opacity-5">NFT</label>
                  <Form.Control
                    value={nftAddress}
                    onChange={(event) => setNftAddress(event.target.value)}
                    placeholder='input eth address here'
                  />
                  <Button className="orange-button ms-3" onClick={() => sendNft(nftAddress, user.ethAddress)}>
                    SEND
                  </Button>
                </div>
                {!user.verified && user.onboarding && (
                  <div className="my-auto mt-3 d-flex flex-column">
                    <Button className="orange-button" onClick={() => approvePushuser(user)}>
                      APPROVE
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    </CardDeck>
  );
}