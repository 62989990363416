import React, { useMemo } from 'react';
import { PickerDropPane, PickerInline } from 'filestack-react';

const { Config } = require("../config.js");
const fileStackApiKey = Config[process.env.REACT_APP_ENV as any]["filestackApiKey"];
const region = 'us-west-1';
const container = 'ic-filestack';

interface IFile {
  type?: string;
  url?: string;
}

interface IProps {
  acceptType: string | string[];
  value: IFile;
  onUploadDone: (value: IFile) => void;
  resetValue: () => void;
  type?: 'inline' | 'dropPane';
  successMsg?: string;
  errorMsg?: string;
  disabled?: boolean;
}

export default function FileUploader({
  acceptType,
  onUploadDone,
  resetValue,
  value,
  type = 'dropPane',
  successMsg = 'File successfully uploaded',
  errorMsg = 'Error uploading file',
  disabled = false,
}: IProps) {
  const fileType = useMemo(() =>
    value?.type?.includes('video') ? 'video' : 'image',
    [value]);

  const options = {
    apikey: fileStackApiKey,
    onError: (error: any) => {
      console.log(error);
    },
    onUploadDone: (res: any) => {
      if (res?.filesUploaded.length > 0) {
        onUploadDone({
          type: res.filesUploaded[0].mimetype,
          url: res.filesUploaded[0].key,
        });
      }
    },
    pickerOptions: {
      accept: acceptType,
      fromSources: ['local_file_system'],
      storeTo: {
        container: container,
        region: region,
      },
    },
  };

  return value?.url ? (
    <>
      {fileType === 'image' ? (
        <img
          alt="uploaded file"
          src={value?.url}
          width={'100%'}
        />
      ) : (
        <video
          controls
          src={value?.url}
          width={'100%'}
        />
      )}
      {!disabled && (
        <>
          <br />
          <span
            className="text-danger cursor-pointer"
            onClick={() => resetValue()}
          >
            delete
          </span>
        </>
      )}
    </>
  ) : (
    <>
      {type === 'inline' && (
        <PickerInline {...options} />
      )}
      {type === 'dropPane' && (
        <PickerDropPane {...options} />
      )}
    </>
  );
}
